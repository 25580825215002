<div class="text-center spinner-container d-flex align-items-center justify-content-center" *ngIf="loading">
    <!--   <div class="spinner-border text-primary text-center" role="status">
        <span class="sr-only">Loading...</span>
    </div> -->
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="box">
        <img src="assets/VILE.gif" width="200px" alt="cargando...">
        <!-- <div class="loader-37"> -->
        <!-- <div class="loader-37"></div> -->
        <!-- </div> -->
    </div>
</div>