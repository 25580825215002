import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { LocalStorageService } from './services/local-storage.service';
declare var $: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Output() myEvent = new EventEmitter();
  title = 'Fe-vile';
  mostrarCarrito = false;
  bad = {
    "datos": []
  }
  custom = {
    "datos": []
  }
  conteo: any;

  constructor(
    private localStorage: LocalStorageService,
  ) {
  }

  ngOnInit(): void {
    this.bad = this.localStorage.getInicio("ProductForCart");
    this.custom = this.localStorage.getInicio("Personalizado");

    if (this.bad != null) {
      if (this.bad.datos.length > 0) {
        this.mostrarCarrito = true;
        this.conteo = this.sumaHecha();
      } else {
        this.mostrarCarrito = false;
      }
    }
    if (this.custom != null) {
      if (this.custom.datos.length > 0) {
        this.mostrarCarrito = true;
        this.conteo = this.sumaHecha();
      } else {
        this.mostrarCarrito = false;
      }
    }
    /* if (this.bad == null && this.bad.datos.length == 0 && this.custom == null && this.custom.datos.length == 0) {
      this.mostrarCarrito = false;
    } else {
      this.mostrarCarrito = true;
      this.conteo = this.sumaHecha();
    } */
  }

  sumaHecha(): number {
    let total = 0;

    if (this.bad == null) {
      total = this.custom.datos.length;
    } else if (this.custom == null) {
      total = this.bad.datos.length;
    } else {
      total = this.bad.datos.length + this.custom.datos.length;
    }
    /* if (this.bad == null && this.custom == null) {
      total = this.bad.datos.length + this.custom.datos.length;
    } else if (this.bad == null) {
      total = this.custom.datos.length;
    } else if (this.custom == null) {
      total = this.bad.datos.length;
    } */

    return total;
  }

}
