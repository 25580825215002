<div class="inicio">
    <app-menu>
    </app-menu>
    <div id="carouselExampleIndicators" class="carousel slide carousel-fade vis" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../../assets/img/bs01.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/bs02.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/bs03.jpg" class="d-block w-100" alt="...">
            </div>
        </div>

    </div>
    <div id="carouselExampleIndicators2" class="carousel slide carousel-fade  esc" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../../assets/img/bs01-movil.jpg" class="d-block h-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/bs02-movil.jpg" class="d-block h-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/bs03-movil.jpg" class="d-block h-100" alt="...">
            </div>
        </div>

    </div>
    <div class="contactanos">
        <div class="titulo">
            <h2>Sellos, litografía e imprenta</h2>
        </div>
        <div class="parrafos">
            <p>Bienvenidos a la página oficial de Vile</p>
            <p>Somos una empresa que se dedica a la imprenta y fabricación de sellos para marcar, en base automática o de madera.</p>
            <p class="naranja">Nuestra propuesta de valor con tu marca o empresa se basa en estos 3 pilares</p>
        </div>
        <div class="pilares esco">
            <div id="carouselExampleIndicators3" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjeta">
                            <div class="icono"><img src="../../../assets/svg/star_outline_black_24dp.svg" alt=""></div>
                            <div class="texto">
                                <p>Más de 90 años de experiencia en fabricación de sellos</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <div class="icono"><img src="../../../assets/svg/thumb_up_black_24dp.svg" alt=""></div>
                            <div class="texto">
                                <p>Más de 40 años de experiencia en las artes gráficas</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <div class="icono"><img src="../../../assets/svg/thumb_up_black_24dp.svg" alt=""></div>
                            <div class="texto">
                                <p>Calidad</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <div class="icono"><img src="../../../assets/svg/trending_up_black_24dp.svg" alt=""></div>
                            <div class="texto">
                                <p>Capacidad de producción</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
            </div>
        </div>
        <div class="pilares vis">
            <div class="tarjeta">
                <div class="icono"><img src="../../../assets/svg/star_outline_black_24dp.svg" alt=""></div>
                <div class="texto">
                    <p>Más de 90 años de experiencia en fabricación de sellos</p>
                </div>
            </div>
            <div class="tarjeta">
                <div class="icono"><img src="../../../assets/svg/star_outline_black_24dp.svg" alt=""></div>
                <div class="texto">
                    <p>Más de 40 años de experiencia en las artes gráficas</p>
                </div>
            </div>
            <div class="tarjeta">
                <div class="icono"><img src="../../../assets/svg/thumb_up_black_24dp.svg" alt=""></div>
                <div class="texto">
                    <p>Calidad</p>
                </div>
            </div>
            <div class="tarjeta">
                <div class="icono"><img src="../../../assets/svg/trending_up_black_24dp.svg" alt=""></div>
                <div class="texto">
                    <p>Capacidad de producción</p>
                </div>
            </div>
        </div>

        <div class="boton">
            <button routerLink="/contactenos">Contáctanos</button>
        </div>

    </div>
    <div class="productos">
        <div class="titulo">
            <h2>Conoce nuestros productos Vile</h2>
            <p>En Vile te ofrecemos el servicio de litografía, imprenta y elaboración de sellos.</p>
        </div>
        <div class="tarjetas">
            <div class="tarjeta img1">
                <div class="texto">
                    <p>Imprenta y Litografía</p>
                </div>
                <div class="boton">
                    <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotizar</a></button>
                </div>
            </div>
            <div class="tarjeta img2">
                <div class="texto">
                    <p>Multisellos</p>
                </div>
                <div class="boton">
                    <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotizar</a> </button>
                </div>
            </div>
        </div>
    </div>
    <div class="conoce">
        <div class="texto">
            <h2>Conoce más de Vile</h2>
            <p>Somos una empresa dedica a las artes gráficas y la elaboración de sellos. Con más de 40 años de experiencia en las artes gráficas y más de 90 años de experiencia en la elaboración de sellos.</p>
        </div>
        <div class="boton">
            <button routerLink="/acerca-de">Conoce Vile</button>
        </div>
    </div>
    <div class="multisellos">
        <div class="titulo">
            <h2>
                Multisellos VILE
            </h2>
        </div>
        <div class="tarjetas vis">
            <div class="tarjeta img1">
                <div class="texto">
                    <p>Fechadores</p>
                </div>

            </div>
            <div class="tarjeta img2">
                <div class="texto">
                    <p>Sellos de Escritorio</p>
                </div>
            </div>
            <div class="tarjeta img3">
                <div class="texto">
                    <p>Sellos de Bolsillo</p>
                </div>
            </div>
            <div class="tarjeta img4">
                <div class="texto">

                    <p>Sellos Especiales</p>
                </div>
            </div>
            <div class="tarjeta img5">
                <div class="texto">
                    <p>Repuestos</p>
                </div>
            </div>
            <div class="tarjeta img6">
                <div class="texto">
                    <p>Tintas</p>
                </div>
            </div>
        </div>
        <div id="carouselExampleIndicators4" class="carousel slide carousel-fade esc" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="tarjeta img1">
                        <p>Fechadores</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img2">
                        <p>Sellos de Escritorio</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img3">
                        <p>Sellos de Bolsillo</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img4">
                        <p>Sellos Especiales</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img5">
                        <p>Repuestos</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img6">
                        <p>Tintas</p>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
        </div>


    </div>
    <div class="destacados">
        <div class="titulo">
            <h2>Conoce nuestros productos destacados en Vile</h2>
            <p>En VILE nos adaptamos a tu presupuesto fabricando según tus necesidades.</p>
        </div>
        <div class="prod-des">
            <div class="subtitulo">
                <h3>Productos Destacados</h3>
            </div>
            <div class="tarjetas">
                <div class="tarjeta img1"><button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotizar</a></button></div>
                <div class="tarjeta img2"><button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotizar</a></button></div>
                <div class="tarjeta img3"><button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotizar</a></button></div>
            </div>
        </div>
    </div>
    <div class="cotizar">
        <div class="imagen">
            <img src="../../../assets/img/img1.jpg" alt="">
        </div>
        <div class="texto">
            <h3>Litografía e imprenta</h3>
            <p>En Litografía Vile contamos con tecnología de punta, gracias a esta nos es permitido trabajar a 1, 2, 3 tintas y completamente a full Color. Contamos con un 100% de clientes satisfechos que aprecian la calidad y fineza otorgada en los trabajos
                entregados.
            </p>
            <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotiza hoy</a></button>
        </div>
    </div>
    <div class="litografia">
        <div class="titulo">
            <h2>
                Litografía VILE
            </h2>
        </div>
        <div class="tarjetas vis">
            <div id="carouselExampleIndicators6" class="carousel slide carousel-fade" data-bs-ride="carousel">

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjeta img1">
                            <div class="texto">
                                <p>Libros</p>
                            </div>
                        </div>
                        <div class="tarjeta img2">
                            <div class="texto">
                                <p>Revistas</p>
                            </div>
                        </div>
                        <div class="tarjeta img3">
                            <div class="texto">
                                <p>Folletos</p>
                            </div>
                        </div>
                        <div class="tarjeta img4">
                            <div class="texto">
                                <p>Bifoliares</p>
                            </div>
                        </div>
                        <div class="tarjeta img5">
                            <div class="texto">
                                <p>Trifoliares</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta img6">
                            <div class="texto">
                                <p>Volantes</p>
                            </div>
                        </div>
                        <div class="tarjeta img7">
                            <div class="texto">
                                <p>Afiches</p>
                            </div>
                        </div>
                        <div class="tarjeta img8">
                            <div class="texto">
                                <p>Papel Membretado</p>
                            </div>
                        </div>
                        <div class="tarjeta img9">
                            <div class="texto">
                                <p>Facturas</p>
                            </div>
                        </div>
                        <div class="tarjeta img10">
                            <div class="texto">
                                <p>Calendario</p>
                            </div>
                        </div>
                    </div>


                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators6" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
            </div>
        </div>
        <div id="carouselExampleIndicators5" class="carousel slide carousel-fade esc" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="tarjeta img1">
                        <p>Libros</p>
                    </div>
                    <div class="tarjeta img2">
                        <p>Revistas</p>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="tarjeta img3">
                        <p>Folletos</p>
                    </div>
                    <div class="tarjeta img4">
                        <p>Bifoliares</p>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="tarjeta img5">
                        <p>Trifoliares</p>
                    </div>
                    <div class="tarjeta img6">
                        <p>Volantes</p>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="tarjeta img7">
                        <p>Afiches</p>
                    </div>
                    <div class="tarjeta img8">
                        <p>Papel Membretado</p>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="tarjeta img9">
                        <p>Facturas</p>
                    </div>
                    <div class="tarjeta img10">
                        <p>Calendario</p>
                    </div>
                </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators5" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
        </div>
    </div>
    <div class="marcas">
        <div class="titulo">
            <h2>Algunas marcas que confian en la calidad de Vile</h2>
        </div>
        <div class="tarjetas vis">

            <div id="carouselExampleIndicators7" class="carousel slide carousel-fade" data-bs-ride="carousel">

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjeta"><img src="../../../assets/img/servicios medicos hospitalarios.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/saqarik.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/papedec.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/muni san lucas.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/logos empresas.png" alt=""></div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta"><img src="../../../assets/img/litegua.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/grupo hospitalario la paz.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/fundecan.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/sc-05.jpg" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/credichapin.png" alt=""></div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta"><img src="../../../assets/img/cooperativa guadalupana.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/banco industrial.png" alt=""></div>
                        <div class="tarjeta"><img src="../../../assets/img/Alan Tenenbaum.png" alt=""></div>

                    </div>

                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators7" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
            </div>
        </div>
        <div id="carouselExampleIndicators8" class="carousel slide esc" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="tarjeta"><img src="../../../assets/img/servicios medicos hospitalarios.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/saqarik.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/papedec.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/muni san lucas.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/logos empresas.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/litegua.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/grupo hospitalario la paz.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/fundecan.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/sc-05.jpg" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/credichapin.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/cooperativa guadalupana.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/banco industrial.png" alt=""></div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta"><img src="../../../assets/img/Alan Tenenbaum.png" alt=""></div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators8" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
        </div>
    </div>
<!--    <div class="noticias">-->
<!--        <div class="titulo">-->
<!--            <h2>Noticias, lanzamiento de nuevos productos, plazas y más contenido que puede interesarte en nuestro blog</h2>-->
<!--        </div>-->
<!--        <div class="boton">-->
<!--            <button>Noticias</button>-->
<!--        </div>-->
<!--    </div>-->
</div>

<app-footer></app-footer>
