export class Contact {
  constructor(
    public name:          string,
    public email:         string,
    public phone:   string,
    public address:       string,
    public message:       string,
    public subject:       string,
    public receiver:      string[],
  ) { }
}
