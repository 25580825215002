<app-menu></app-menu>

<div class="contenedor-general">

    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
           <!--  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <h1 class="title-s">Imprenta Vile</h1>
                <div class="img"></div>
            </div>
            <!-- <div class="carousel-item">
                <div class="img"></div>
            </div>
            <div class="carousel-item">
                <div class="img"></div>
            </div> -->
        </div>

        <!--?flechas laterales-->
        <!--  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button> -->
    </div>


    <div class="cintillo-blanco">
        <div class="texto">
            <h2>Explora nuestros productos de</h2>
            <h2>Litografía Vile</h2>
        </div>
        <button routerLink="/productos" type="button">Explorar</button>
    </div>

    <div class="imprenta-info">
        <div class="img"></div>
        <div class="texto">
            <div class="titulo">
                <h2>Imprenta</h2>
                <h2>Vile</h2>
            </div>
            <p>En Vile contamos con el servicio de imprenta con tecnología de punta, con la cual trabajamos una, dos, tres tintas y completamente a Full Color. Contamos con un 100% de clientes satisfechos que aprecian la calidad y fineza otorgada en los
                trabajos entregados. Cuando se trate de Impresiones cuente con nosotros.</p>
            <button routerLink="/productos" type="button">Comprar</button>
        </div>
    </div>

    <div class="imprenta-slider">
        <div class="titulo">
            <h2>Imprenta <span>vile</span></h2>
            <h5>Conoce lo que hacemos</h5>
            <hr>
        </div>
        <div class="car-desk">
            <div id="carouseldesktop" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjetas">
                            <div class="tarjeta">
                                <h5>Formularios</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Insertos de medicina</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Papeleria empresarial</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Tarjetas de presentación</h5>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjetas">
                            <div class="tarjeta">
                                <h5>Impresión de volantes</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Bifoliares, trifoliares</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Elaboración de cajas</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>impresión de libros</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouseldesktop" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouseldesktop" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
            </div>
        </div>
        <div class="car-mov">
            <div id="carouselmovil" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjeta">
                            <h5>Formularios</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Insertos de medicina</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Papeleria empresarial</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Tarjetas de presentación</h5>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselmovil" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselmovil" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
            </div>
        </div>

        <button type="button">
            <a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB"
               target="_blank" class="custom-link">Cotiza hoy mismo</a>
        </button>

    </div>

    <div class="galeria-imprenta">
        <div class="conoce">
            <div class="titulo">
                <h2>Conoce nuestra</h2>
                <h2>línea ecológica</h2>
            </div>
            <p>En Litografía Vile, estamos consientes del medio ambiente, por eso escogemos minuciosamente a nuestros proveedores, los cuales garantizan practicas responsables en la fabricación del papel.</p>
            <button routerLink="/productos" type="button">Comprar</button>
        </div>
        <div class="pri-img"></div>
        <div class="seg-img"></div>
        <div class="empresas">
            <div class="titulo">
                <h2>Para empresas</h2>
                <h5>Papelería corporativa y publicitaria</h5>
            </div>
            <p>Papel membretado, etiquetas, notas de envío, formularios, tarjetas de presentación, volantes, afiches y más.</p>
            <button routerLink="/productos" type="button">Comprar</button>
        </div>
    </div>

    <div class="cintillo-naranja">
        <div class="texto">
            <h2>¿Te gusta lo que ves?</h2>
            <hr>
            <h5>Solicita una cotización hoy mismo</h5>
        </div>
        <button type="button"><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB"
                                 target="_blank" class="custom-link">Cotizar</a>
        </button>
    </div>
</div>

<app-footer></app-footer>
