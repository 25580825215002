import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaginaConstruccionComponent } from './Components/pagina-construccion/pagina-construccion.component';
import { InicioComponent } from './Components/inicio/inicio.component';
import { SellosComponent } from './Components/sellos/sellos.component';
import { AcercaDeComponent } from './Components/acerca-de/acerca-de.component';

import { ContactenosComponent } from './Components/contactenos/contactenos.component';
import { MenuComponent } from './Components/menu/menu.component';
import { FooterComponent } from './Components/footer/footer.component';
import { LitografiaEImprentaComponent } from './Components/litografia-e-imprenta/litografia-e-imprenta.component';
import { ProductosComponent } from './Components/productos/productos.component';
import { AnclaComponent } from './Components/ancla/ancla.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ProductoComponent } from './Components/productos/producto/producto.component';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { PersonalizarComponent } from './Components/personalizar/personalizar.component';
import { ErrorComponent } from './Components/error/error.component';
import { ShoppingCartComponent } from './Components/shopping-cart/shopping-cart.component';
import { SpinnerComponent } from './Components/library/spinner/spinner.component';

import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
import { GLOBAL } from './services/global.service';
import { ImprentaComponent } from './Components/imprenta/imprenta.component';
import { SellosPersonalizadosComponent } from './Components/sellos-personalizados/sellos-personalizados.component';
import { ScriptService } from './services/script.service';

@NgModule({
  declarations: [
    AppComponent,
    PaginaConstruccionComponent,
    InicioComponent,
    SellosComponent,
    AcercaDeComponent,
    ContactenosComponent,
    MenuComponent,
    FooterComponent,
    LitografiaEImprentaComponent,
    ProductosComponent,
    AnclaComponent,
    ProductoComponent,
    PersonalizarComponent,
    ErrorComponent,
    ShoppingCartComponent,
    SpinnerComponent,
    ImprentaComponent,
    SellosPersonalizadosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(GLOBAL.firebaseConfig),
    NgHcaptchaModule.forRoot({
      siteKey: '8525062e-422e-46dd-b443-c09beee356dd',
      languageCode: 'es' // optional, will default to browser language
  }),
  ],
  providers: [
    ScriptService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
