<app-menu></app-menu>
<div class="sellos">
    <div class="banner">
        <div class="texto">
            <h1>Sellos Vile</h1>
            <p>En Multisellos Vile creemos en comunicar las ideas tal como las imaginamos.</p>
            <p> Por eso nos esforzamos para que cada sello que creamos impresione.</p>
            <p class="peq">Nuestra promesa es entregarle un sello de calidad superior y excelente tiempo de entrega.</p>
        </div>
    </div>
    <div class="catalogo">
        <h2>Explora nuestros sellos Vile</h2>
        <button><a routerLink="/productos">Explorar</a></button>
    </div>
    <div class="tarjetas inv peq">
        <div class="tarjeta">
            <div class="texto">
                <h2>Sellos De Escritorio</h2>
                <p class="naranja">Conoce nuestra línea de sellos 100% personalizada</p>
                <p>Sellos de escritorio para labores que demandan alto desempeño, nitidez de impresión y limpieza absoluta. Los materiales de fabricación son de alta calidad, garantizándole un sello de larga vida.</p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Comprar</a></button>
            </div>
        </div>
        <div class="tarjeta">
            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>

                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../assets/img/bp01.jpg" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/Sellos-Escritorios-2.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/Sellos-Escritorios-3.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/Sellos-Escritorios-4.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/Sellos-Escritorios-5.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/Sellos-Escritorios-6.png" class="d-block w-100" alt="...">
                    </div>

                </div>

            </div>
        </div>
    </div>
    <div class="tarjetas">
        <div class="tarjeta">
            <div class="texto">
                <h2>Sellos Especiales</h2>
                <p class="naranja">Sellos especiales para marcar de forma diferente y en otras superficies:
                </p>
                <p>Contamos con sellos para marcar en realzado o sello de agua y para marcar sobre tela con tinta especial para textiles.
                </p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Comprar</a></button>
            </div>
        </div>
        <div class="tarjeta">
            <div id="carouselExampleIndicators1" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1" aria-label="Slide 2"></button>

                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../assets/img/Sello-especial-1.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/Sellos-especiales-2.png" class="d-block w-100" alt="...">
                    </div>


                </div>

            </div>
        </div>
    </div>
    <div class="urgentes">
        <div class="tex">
            <h2>¿Buscas sellos urgentes?</h2>
            <p>Entregas urgentes en 1 hr. Solicita por WhatsApp</p>
        </div>
        <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Más información</a></button>
    </div>
    <div class="tarjetas inv">
        <div class="tarjeta">
            <div class="texto">
                <h2>Sellos de bolsillo</h2>
                <p class="negro">Conoce nuestra línea de sellos de bolsillo, prácticos para profesionales.</p>
                <p>Sello practico para llevar a todos lados, ya sea dentro de la bolsa, maletín o bolsillo. No manchan. Ideal para profesionales.Los materiales de fabricación son de alta calidad, garantizándole un sello de larga vida.</p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Comprar</a></button>
            </div>
        </div>
        <div class="tarjeta">
            <div id="carouselExampleIndicators2" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="4" aria-label="Slide 5"></button>

                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../assets/img/sellos-bolsillo-2.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/sellos-bolsillo-3.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/sellos-bolsillo-4.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/sellos-bolsillo-5.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/sellos-bolsillo-6.png" class="d-block w-100" alt="...">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tarjetas">
        <div class="tarjeta">
            <div class="texto">
                <h2>Fechadores</h2>
                <p class="negro">Conoce nuestra línea de sellos 100% personalizada</p>
                <p>Fechadores de diferentes tamaños y estilos, ideal para tener un mejor control de procesos.</p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%020%F0%9F%92%BB" target="_blank">Comprar</a></button>
            </div>
        </div>
        <div class="tarjeta">
            <div id="carouselExampleIndicators3" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="4" aria-label="Slide 5"></button>

                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../assets/img/vile-fechadores-2.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/vile-fechadores-3.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/vile-fechadores-4.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/vile-fechadores-5.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/vile-fechadores-6.png" class="d-block w-100" alt="...">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tarjetas inv">
        <div class="tarjeta">
            <div class="texto">
                <h2>Repuestos</h2>
                <p class="negro">Contamos con repuestos y tintas para todos tus sellos.</p>
                <p>Si tu sello ya no tiene una buena impresión solicita tu repuesto.</p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Comprar</a></button>
            </div>
        </div>
        <div class="tarjeta">
            <div id="carouselExampleIndicators4" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="4" aria-label="Slide 5"></button>

                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../assets/img/repuestos-sellos-1.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/repuestos-sellos-2.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/repuestos-sellos-3.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/repuestos-sellos-4.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/repuestos-sellos-5.png" class="d-block w-100" alt="...">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tarjetas">
        <div class="tarjeta">
            <div class="texto">
                <h2>Nuestra marca en el planeta</h2>
                <p class="naranja">RRR</p>
                <p>Multisellos Vile, como parte del planeta tierra, somos conscientes de la responsabilidad que debemos de tener con el medio ambiente. Por eso nuestras dos marcas lideres de sellos, Trodat y Shiny, son marcas las cuales están certificadas,
                    donde el impacto de la elaboración de los sellos es amigable con el medio ambiente.</p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank"> Comprar</a></button>
            </div>
        </div>
        <div class="tarjeta img bk2">
            <div class="texto">
                <h3>Sellos Ecológicos</h3>

            </div>
        </div>
    </div>
    <div class="personalizado">
        <div class="info">
            <h2>En tan solo 4 pasos tienes tu sello personalizado</h2>
            <p>Conoce nuestro proceso de producción o entrega</p>
        </div>
        <div class="cuadros">
            <div class="tarjeta">
                <h2>1</h2>
                <div class="color rojo"></div>
                <p>Haz tu pedido por teléfono, correo electrónico, WhatsApp o redes sociales.</p>
            </div>
            <div class="tarjeta">
                <h2>2</h2>
                <div class="color amarillo"></div>
                <p>Revisa y aprueba el arte de tu sello.</p>
            </div>
            <div class="tarjeta">
                <h2>3</h2>
                <div class="color verde"></div>
                <p>Realiza tu pago.</p>
            </div>
            <div class="tarjeta">
                <h2>4</h2>
                <div class="color gris"></div>
                <p>Recibe tu sello en máximo 24 horas.</p>
            </div>
        </div>
    </div>
</div>
<app-ancla></app-ancla>
<app-footer></app-footer>