export class Filter {
    constructor(
        public name: string,
        public tipo: string,
        public marca: string,
        public categoria: string,
        public priceMin: number,
        public priceMax: number,
    ) { }
}
