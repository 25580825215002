import { ILine } from '../interfaces/ILine'
export class Custom {
    constructor(
        public color:         string,
        public borde:         string,
        public borderSize:    string,
        public stamp_code:    string,
        public user_id:       string,
        public line1:         ILine,
        public line2:         ILine,
        public line3:         ILine,
        public line4:         ILine,
        public notas:         string,
        public carcasa:       string,
        public status:        string,
        public nombre:        string,
        public email:         string[],
        public datetime:      string,
        public extra1:        string,
        public extra2:        string,
        public extra3:        string,
        public direccion:     string,
        public telefono:      string

    ) { }
}
