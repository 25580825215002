import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AcercaDeComponent } from "./Components/acerca-de/acerca-de.component";
import { ContactenosComponent } from "./Components/contactenos/contactenos.component";
import { ErrorComponent } from "./Components/error/error.component";
import { FooterComponent } from "./Components/footer/footer.component";
import { ImprentaComponent } from "./Components/imprenta/imprenta.component";
import { InicioComponent } from "./Components/inicio/inicio.component";
import { LitografiaEImprentaComponent } from "./Components/litografia-e-imprenta/litografia-e-imprenta.component";
import { MenuComponent } from "./Components/menu/menu.component";
import { PersonalizarComponent } from "./Components/personalizar/personalizar.component";
import { ProductoComponent } from "./Components/productos/producto/producto.component";
import { ProductosComponent } from "./Components/productos/productos.component";
import { SellosPersonalizadosComponent } from "./Components/sellos-personalizados/sellos-personalizados.component";
import { SellosComponent } from "./Components/sellos/sellos.component";
import { ShoppingCartComponent } from "./Components/shopping-cart/shopping-cart.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 250],
  onSameUrlNavigation: "reload",
};

const routes: Routes = [
  { path: "", redirectTo: "/inicio", pathMatch: "full" },
  { path: "inicio", component: InicioComponent },
  { path: "sellos", component: SellosComponent },
  { path: "sellos-personalizados", component: SellosPersonalizadosComponent },
  { path: "litografia", component: LitografiaEImprentaComponent },
  { path: "productos", component: ProductosComponent },
  { path: "productos/:name", component: ProductoComponent },
  { path: "acerca-de", component: AcercaDeComponent },
  { path: "contactenos", component: ContactenosComponent },
  { path: "menu", component: MenuComponent },
  { path: "footer", component: FooterComponent },
  { path: "personalizar/:id", component: PersonalizarComponent },
  { path: "carrito", component: ShoppingCartComponent },
  { path: "imprenta", component: ImprentaComponent },
  { path: "**", component: ErrorComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
