import { CustomId } from '../interfaces/CustomsId'
import { ProductsId } from '../interfaces/ProductsId'
export class Ticket {
    constructor(
        public customsId:      CustomId[],
        public productsId:     ProductsId[],
        public userId:         string,
        public totalPrice:     number,
        public status:         string,
        public receiver:       string,
        public datetime:       string,
        public envioTipo:      string,
        public nombre:         string,
        public email:          string,
        public direccion:      string,
        public telefono:       string,
        public nit:            string,
        public envioPrice:     number,
        public tipoEnvio: string,

    ) { }
}
