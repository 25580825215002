<div class="contenedor">
    <div class="banner">
        <img src="../assets/Construccion.jpeg" alt="..." class="const">
    </div>
    <div class="texto">

        <h1>Pronto tendremos una nueva forma de servirte!</h1>
        <div class="logo">
            <img src="../assets/logo vile colores.png" alt="">
        </div>
    </div>
    <div class="footer">
        <div class="contacto">
            <h4>Contáctanos por estas vías:</h4>
            <p>
                <span>PBX: </span>23142222
            </p>
            <p><span>WHATSAPP: </span><a href="https://api.whatsapp.com/send?phone=50257556140&text=Hola!%20Buenas%20tardes%20" target="_blank">57556140</a></p>
            <p><span>CORREO: </span><a href="mailto:ventas1@vile.com.gt" target="_blank">ventas1@vile.com.gt</a> - <a href="mailto:ventas2@vile.com.gt" target="_blank">ventas2@vile.com.gt</a></p>
            <p class="mitad"><span>Dirección: </span> 6 Ave. 5-31 zona 2, Cdad de Guatemala, CA 01002</p>

        </div>
        <div class="redes">
            <div class="red">
                <img src="./../assets/facebook.svg" alt="">
                <a href="https://www.facebook.com/Sellos-Multisellos-Vile-243698232664512" target="_blank">Multisellos Vile</a>
            </div>
            <div class="red">
                <img src="./../assets/instagram.svg" alt="">
                <a href="https://www.instagram.com/multisellosvilegt/" target="_blank">Multisellos Vile</a>
            </div>
            <div class="red">
                <img src="./../assets/instagram.svg" alt="">
                <a href="https://www.instagram.com/litografiavilegt/" target="_blank">Litografía Vile</a>
            </div>
        </div>
    </div>
</div>