import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LABELS, TYPES } from 'src/app/enums/enums';
import { Product } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';
import { Category } from "src/app/models/category.model";
import { CategoriesService } from "src/app/services/categories.service";
import { Filter } from 'src/app/models/filter.model';
import { stringify } from 'querystring';
declare var $: any;

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss'],
})
export class ProductosComponent implements OnInit {
  colorBorder: string = '';
  isChecked: any;
  checkbox: any;
  status = '';
  isLoading = false;
  products: Product[] = [];
  buscarProducts: Filter;
  categoriesDB: Category[];
  // FOR SEARCH
  search: string = '';
  tag: string = '';
  category: string = '';
  brand: string = '';
  general: string = '';
  specie: string = '';
  speciesType = Object.values(TYPES);
  genCategories = Object.values(LABELS);
  /* marcEN = Object.values(BRAND); */
  searchFields = {
    search: '',
    tag: '',
    category: '',
    brand: '',
    specie: '',
  }

  hola: number;
  hola2: number;

  constructor(
    private _sanitizer: DomSanitizer,
    private _productsService: ProductsService,
    private __activatedRoute: ActivatedRoute,
    private title: Title,
    private _categoryService: CategoriesService,
    private meta: Meta
  ) { }

  
  ngOnInit(): void {
    this.title.setTitle('  Productos | Vile ');
    this.meta.updateTag({ name: 'keywords', content: 'sellos personalizables guatemala, sellos urgentes guatemala.' });
    this.meta.updateTag({ name: 'description', content: 'Ofrecemos fabricación y personalización de sellos de hule, sellos automáticos y de escritorio. Litografía e imprenta en Guatemala.' });
    this.meta.updateTag({ property: 'og:description', content: 'Ofrecemos fabricación y personalización de sellos de hule, sellos automáticos y de escritorio. Litografía e imprenta en Guatemala.' });
    this.listarProductos();
    this.getUrlParams();
    this.listCategories();
    this.buscarProducts = new Filter('', '', '', '', 0, 0);
    /* this.buscarProducts.name = "sello de escritorio"; */
    /* this.filtros(); */

    /*  if (this.isLoading) {
       $('#exampleModal').modal('show');
     }
  */
  }

  jose(entrada: number): number {
    let holahola: number
    holahola = entrada * 5;
    this.buscarProducts.priceMax = holahola;
    return holahola;
  }

  listarProductos(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      // tslint:disable-next-line: deprecation
      const productsSubs = this._productsService.listProducts().subscribe(
        res => {
          if (res.products?.length > 0) {
            this.products = res.products;
            $('#exampleModal').modal('hide');
          } else {
            alert('No hay Productos almacenados');
            this.products = [];
          }
          this.status = 'ok';

        },
        error => this.onError(error),
        () => {
          productsSubs.unsubscribe();
          this.isLoading = false;
        }
      );
    }
  }

  onError(error: any, property?: string): void {
    const errorMessage: any = error;
    alert('Se ha producido un error al ejecutar la petición');
    if (errorMessage !== null) {
      this.status = 'error';
    }
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  searchProd(): void {
    this.search = this.searchFields.search || '';
    this.brand = this.searchFields.brand || '';
    this.tag = this.searchFields.tag || '';
    this.category = this.searchFields.category || '';
    this.specie = this.searchFields.specie || '';
    this.setUrlParams();
  }

  setUrlParams(): void {
    if (this.search) {
      var producto = this.search;
    }
    if (this.brand) {
      var marca = this.brand;
    }
    if (this.tag) {
      var etiqueta = this.tag
    }
    if (this.category) {
      var categoria = this.category
    }
    if (this.specie) {
      var especie = this.specie
    }

  }

  getUrlParams(): void {
    this.__activatedRoute.queryParams.subscribe(params => {
      this.clearUrlParamsFields();
      for (const key in params) {
        switch (key) {
          case 'producto':
            this.searchFields.search = params[key];
            this.search = params[key];
            break;
          case 'marca':
            this.searchFields.brand = params[key];
            this.brand = params[key];
            break;
          case 'etiqueta':
            this.searchFields.tag = params[key];
            this.tag = params[key];
            break;
          case 'categoria':
            this.searchFields.category = params[key].toUpperCase();
            this.category = params[key];
            break;
          case 'especie':
            this.searchFields.specie = params[key];
            this.specie = params[key];
            break;
          case 'buscar':
            this.general = params[key];
            break;
        }
      }
    });
  }

  clearUrlParamsFields(): void {
    this.searchFields.brand = '';
    this.searchFields.tag = '';
    this.searchFields.search = '';
    this.searchFields.category = '';
    this.searchFields.specie = '';
    this.search = '';
    this.tag = '';
    this.brand = '';
    this.category = '';
    this.specie = '';
    this.general = '';
  }

  url(direccion: string): string {
    const nameProduct = direccion;
    return "/productos/" + nameProduct;
  }

  listCategories(): void {
    this.isLoading = true;
    // tslint:disable-next-line: deprecation
    const listCatSubs = this._categoryService.listCategories().subscribe(
      res => {
        if (!res.categories?.length) {
          alert('No hay categorías almacenadas.');
          this.status = 'error';
          this.categoriesDB = [];
        } else {
          this.categoriesDB = res.categories;
          this.status = 'ok';
          /* console.log(this.categoriesDB) */
        }
      },
      error => {
        this.onError(error);
        this.isLoading = false;
        listCatSubs.unsubscribe();
      },
      () => {
        listCatSubs.unsubscribe();
        this.isLoading = false;
      }
    );
  }

  filtros(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      // tslint:disable-next-line: deprecation
      const productsSubs = this._productsService.filter(this.buscarProducts).subscribe(
        res => {
          if (res.products?.length > 0) {
            this.products = res.products;
          } else {
            alert('No hay Productos almacenados');
            this.products = [];
          }
          this.status = 'ok';
        },
        error => this.onError(error),
        () => {
          productsSubs.unsubscribe();
          this.isLoading = false;
        }
      );
    }
  }

  slobo(newValue1: any) {
    this.colorBorder = newValue1;
    /* this.cobrarA(this.colorBorder); */

    /*  console.log(this.colorBorder); */
    /* if (newValue1 !== "negro") {
      this.cobrar = true;
    } else {
      this.cobrar = false;
    } */
  }

  checkValue(event: any) {
    /* console.log(event); */
  }

  changed = (evt) => {
    this.isChecked = evt.target.checked;
  }

  click(ev) {
    /* console.log(ev.target.defaultValue); */
    this.buscarProducts.tipo = ev.target.defaultValue;

  }

}
