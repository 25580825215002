<app-menu></app-menu>
<br><br><br><br>
<br><br>
<div class="varias-lineas">
    <h1 class="text-center mt-4 mb-3">
        Editando sello {{product.codigo}}
    </h1>
    <div class="linea">
        <hr>
    </div>
    <div class="area-trabajo m-auto">
        <h3 class="mt-5 d-none">
            Datos del Comprador
        </h3>


        <div class="row d-none">
            <div class="col-3">
                <div class=" form-group">
                    <label for="inputNotas" class=""> <b>Nombre</b></label>
                    <span>*</span>
                    <input type="text" [(ngModel)]="nombreFront" class="form-control inpt-a" id="inputNotas">
                    <!-- <span *ngIf="">Ingrese su nombre</span> -->
                </div>
            </div>
            <div class="col-3">
                <div class=" form-group">
                    <label for="inputNotas" class=""> <b>Teléfono</b></label>
                    <span>*</span>
                    <input type="text" [(ngModel)]="telefonoFront" class="form-control inpt-a" id="inputNotas">
                    <!-- <span>Ingrese su teléfono</span> -->
                </div>
            </div>
            <div class="col-3">
                <div class=" form-group">
                    <label for="inputNotas" class=""> <b>Correo</b></label>
                    <span>*</span>
                    <input type="email" [(ngModel)]="correoFront" class="form-control inpt-a" id="inputNotas">
                    <!-- <span>Ingrese su correo</span> -->
                </div>
            </div>

            <div class="col-3">
                <div class=" form-group">
                    <label for="inputNotas" class=""> <b>Notas</b></label>
                    <input type="text" [(ngModel)]="notasFront" class="form-control inpt-a" id="inputNotas">
                </div>
            </div>

        </div>
        <!--   <br>
        <br> -->
        <div class="text-center">

            <h3 *ngIf="verTexto">
                Si ya tienes tu diseño, súbelo aquí.
            </h3>
            <h3 *ngIf="!verTexto">
                Deseas editar Texto
            </h3>

            <button *ngIf="verTexto" (click)="ocultarTexto()" class="btn imb m-2">
                Subir un diseño
            </button>


        </div>

        <!--  <br><br> -->
        <br><br>
        <h3 *ngIf="verTexto" class="">
            Personalización del sello
        </h3>

        <div class="txt-edit" *ngIf="verTexto">
            <div class="row">
                <div class="col-md-3">
                    <div class="my-2">
                        <label for="formFile" class="form-label">Color de tinta</label> <span
                            class="text-danger">*</span>
                        <select [ngModel]="colorBorder" (ngModelChange)="slobo($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="black">Negro</option>
                            <option value="green">Verde</option>
                            <option value="purple">Morado</option>
                            <option value="orange">Naranja</option>
                            <option value="red">Rojo</option>
                            <option value="blue">Azul</option>
                            <option value="chocolate">Cafe</option>
                            <option value="turquoise">Turquesa</option>
                        </select>
                        <span class="text-primary" *ngIf="cobrar">
                            +(Q.5.00 por cambio de tinta)
                        </span>
                    </div>
                    <div class="my-2">
                        <label for="formFile" class="form-label">Borde</label>
                        <select [ngModel]="typeBorder" (ngModelChange)="ricardo($event)" class="form-select"
                            aria-label="Default select example">
                            <option selected value="none">Ninguno</option>
                            <option value="solid">Solido</option>
                            <option value="dotted">Punteado</option>
                            <option value="double">Doble</option>
                        </select>
                    </div>
                    <div class="my-2">
                        <label for="formFile" class="form-label">Tamaño del borde</label>
                        <select [ngModel]="tania" (ngModelChange)="borde_t($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="1px">1 px</option>
                            <option value="2px">2 px</option>
                            <option value="3px">3 px</option>
                            <option value="4px">4 px</option>
                            <option value="5px">5 px</option>
                        </select>
                    </div>
                    <div class="my-2">
                        <label for="formFile" class="form-label">Color del sello</label><span
                            class="text-danger">*</span>
                        <select [ngModel]="varStampColor" (ngModelChange)="stampColor($event)" class="form-select">
                            <option [value]="i" *ngFor="let i of product.colores">{{i}}</option>
                        </select>
                        <select [ngModel]="varStampColor" (ngModelChange)="stampColor($event)"
                            class="form-select d-none" aria-label="Default select example">
                            <option value="Azul">Azul</option>
                            <option value="Verde">Verde</option>
                            <option value="Negro">Negro</option>
                            <option value="Rojo">Rojo</option>
                            <option value="Naranja">Naranja</option>
                        </select>
                    </div>
                </div>
                <div class="mt-4 previsual col text-center" id="htmlData">
                    <h6 [ngStyle]="{'display': pauFor}">vile.com.gt vile.com.gt vile.com.gt vile.com.gt <br> vile.com.gt
                        vile.com.gt
                        vile.com.gt vile.com.gt <br>vile.com.gt vile.com.gt vile.com.gt vile.com.gt <br> vile.com.gt
                        vile.com.gt vile.com.gt vile.com.gt <br> vile.com.gt vile.com.gt vile.com.gt vile.com.gt
                        <br>vile.com.gt vile.com.gt vile.com.gt vile.com.gt <br> vile.com.gt vile.com.gt vile.com.gt
                        vile.com.gt <br>vile.com.gt vile.com.gt vile.com.gt vile.com.gt <br> vile.com.gt vile.com.gt
                        vile.com.gt vile.com.gt
                    </h6>
                    <div class="d-flex align-items-center justify-content-center p-5 downht">
                        <div class="square1 text-center d-flex align-items-center justify-content-center flex-column overflow-hidden p-2"
                            [ngStyle]="{ 'border-style': typeBorder, 'border-color': colorBorder, 'border-width': tania, 'color': colorBorder, 'font-size': tamanoFuente, 'width': variableWidth, 'height': variableHeigth}">
                            <!-- [ngClass]="{ 'borde-tres-red-solid': activarBorde.solid_red, 'borde-tres-red-dotted': activarBorde.dotted_red, 'borde-tres-red-double': activarBorde.double_red, 'borde-tres-blue-solid': activarBorde.solid_blue, 'borde-tres-blue-dotted': activarBorde.dotted_blue, 'borde-tres-blue-double': activarBorde.double_blue}"> -->
                            <!-- [ngStyle]="{'font-size': tamanoFuente + 'px', 'text-align': alineacion, 'font-family': tipoFuente, 'line-height': ajustarInterlineado(tamanoFuente, product.codigo)  + 'px'}" -->
                            <h2 class="w-100"
                                [ngStyle]="{'font-size': tamanoFuente + 'px', 'text-align': alineacion, 'font-family': tipoFuente, 'line-height': interlineadoFront}"
                                [ngClass]="{'lewa': style, 'lewa2': style2, 'lewa3': style3}">
                                {{textoLineaUna}}
                            </h2>
                            <h2 class="w-100"
                                [ngStyle]="{'font-size': tamanoFuente1 + 'px', 'text-align': alineacion1,'font-family': tipoFuente1, 'line-height': interlineadoFront1}"
                                [ngClass]="{'lewa': style1Line2, 'lewa2': style2Line2, 'lewa3': style3Line2}"
                                *ngIf="isCollapse">
                                {{textoLineaDos}}
                            </h2>
                            <h2 class="w-100"
                                [ngStyle]="{'font-size': tamanoFuente2 + 'px', 'text-align': alineacion2,'font-family': tipoFuente2, 'line-height': interlineadoFront2}"
                                [ngClass]="{'lewa': style1Line3, 'lewa2': style2Line3, 'lewa3': style3Line3}"
                                *ngIf="isCollapse1">
                                {{textoLineaTres}}
                            </h2>
                            <h2 class="w-100"
                                [ngStyle]="{'font-size': tamanoFuente3 + 'px', 'text-align': alineacion3,'font-family': tipoFuente3, 'line-height': interlineadoFront3}"
                                [ngClass]="{'lewa': style1Line4, 'lewa2': style2Line4, 'lewa3': style3Line4}"
                                *ngIf="isCollapse2">
                                {{textoLineaCuatro}}
                            </h2>
                        </div>
                    </div>
                    <h5>
                        Medidas: {{product.medidas}}
                    </h5>
                    <!-- <h5>
                    Escala: 3:1
                </h5> -->
                    <p>Copyright Ⓒ VILE 2022, Todos los derechos reservados.</p>

                </div>
            </div>
            <div class="alert mt-3 alert-warning d-flex align-items-center alert-dismissible fade show" role="alert">
                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
                    <use xlink:href="#check-circle-fill" />
                </svg>
                <div>
                    <span>
                        <b>Importante:</b>
                        <br>

                        Solamente lo que salga en el recuadro gris estará en tu sello, si el texto se sale del recuadro
                        gris
                        el texto no saldrá completo.
                        <br><br>
                        <!-- La medida del recuardo gris, es solamente con fines ilustrativos, en la cual la medida esta multiplicada por 3 -->
                    </span>
                </div>
            </div>
            <div class="w-50 m-auto mostrarphone">

                <div type="button" (click)="toggleState()"
                    class="col-md-1 d-flex align-items-center justify-content-center text-center pe-auto flex-column ">
                    <!-- <img src="https://img.icons8.com/stickers/100/000000/add.png" width="50%" /> -->
                    <img src="assets/img/mas.png" width="30px" />
                    <!-- <img src="assets/img/cerrar.png" width="30px" /> -->
                    <p class="p-0 m-0">
                        Agregar una linea
                    </p>
                </div>
            </div>
            <h3 class="mt-3">Lineas</h3>
            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Texto</label><span class="text-danger">*</span>
                        <input type="text" [(ngModel)]="textoLineaUna" class="form-control" id="inputPassword">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tipo de Fuente</label>
                        <select [ngModel]="tipoFuente" (ngModelChange)="onChangeFamily($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="Arial">Arial</option>
                            <option value="Typography Times">Times</option>
                            <option value="Technical">Technical</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="EnviroD">Enviro D</option>
                            <option value="RegencyScriptFLF">Regency Script FLP Plain</option>
                            <option value="English111 Vivace BT">English 111 Vivace BT</option>
                            <option value="OPTIEngraversOldEnglish">Engravers Old English</option>
                            <option value="Edwardian Script ITC">Edwardian Script ITC</option>
                            <option value="Century">Century</option>
                            <option value="Century Gothic">Century Gothic</option>
                            <option value="BrushScriptOpti"> Brush Script</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tamaño de fuente</label>
                        <select [ngModel]="tamanoFuente" (ngModelChange)="fuenteSize($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="8">8 px</option>
                            <option value="9">9 px</option>
                            <option value="10">10 px</option>
                            <option value="11">11 px</option>
                            <option value="12">12 px</option>
                            <option value="14">14 px</option>
                            <option value="16">16 px</option>
                            <option selected value="18">18 px</option>
                            <option value="20">20 px</option>
                            <option value="22">22 px</option>
                            <option value="24">24 px</option>
                            <option value="26">26 px</option>
                            <option value="28">28 px</option>
                            <option value="36">36 px</option>
                            <option value="48">48 px</option>
                            <option value="72">72 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Interlineado</label>
                        <select [ngModel]="interlineadoFront" (ngModelChange)="interFunction($event)"
                            class="form-select" aria-label="Default select example">
                            <option value="10px">10 px</option>
                            <option value="20px">20 px</option>
                            <option value="30px">30 px</option>
                            <option value="40px">40 px</option>
                            <option value="50px">50 px</option>
                            <option value="60px">60 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Alineación</label>
                        <select [ngModel]="alineacion" (ngModelChange)="txtAling($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="left">Izquierda</option>
                            <option value="center">Centrado</option>
                            <option value="right">Derecha</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style" (ngModelChange)="style_t($event)" class="form-check-input m-0"
                            type="checkbox" id="inlineCheckbox1">
                        <label class="form-check-label" for="inlineCheckbox1">Bold</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style2" (ngModelChange)="style_t2($event)" class="form-check-input m-0"
                            type="checkbox" id="italic1">
                        <label class="form-check-label" for="italic1">Italic</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style3" (ngModelChange)="style_t3($event)" class="form-check-input m-0"
                            type="checkbox" id="subrayar1">
                        <label class="form-check-label" for="subrayar1">Subrayar</label>
                    </div>
                </div>
                <div type="button" class="quitarphone" (click)="toggleState()"
                    class="col-md-1 d-flex align-items-center justify-content-center text-center pe-auto flex-column ">
                    <!-- <img src="https://img.icons8.com/stickers/100/000000/add.png" width="50%" /> -->
                    <img src="assets/img/mas.png" width="30px" />
                    <!-- <img src="assets/img/cerrar.png" width="30px" /> -->
                    <p class="p-0 m-0">
                        Agregar una linea
                    </p>
                </div>
                <select class="d-none" [ngModel]="selectedDevice" (ngModelChange)="onChange($event)" name="sel2">
                    <option [value]="i" *ngFor="let i of devices">{{i}}</option>
                </select>
            </div>
            <!-- <h3 *ngIf="isCollapse">Linea 2</h3> -->
            <div class="row mt-3" *ngIf="isCollapse">
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Texto</label>
                        <input type="text" [(ngModel)]="textoLineaDos" class="form-control" id="inputPassword">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tipo de Fuente</label>
                        <select [ngModel]="tipoFuente1" (ngModelChange)="onChangeFamily1($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="Arial">Arial</option>
                            <option value="Typography Times">Times</option>
                            <option value="Technical">Technical</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="EnviroD">Enviro D</option>
                            <option value="RegencyScriptFLF">Regency Script FLP Plain</option>
                            <option value="English111 Vivace BT">English 111 Vivace BT</option>
                            <option value="OPTIEngraversOldEnglish">Engravers Old English</option>
                            <option value="Edwardian Script ITC">Edwardian Script ITC</option>
                            <option value="Century">Century</option>
                            <option value="Century Gothic">Century Gothic</option>
                            <option value="BrushScriptOpti"> Brush Script</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tamaño de fuente</label>
                        <select [ngModel]="tamanoFuente1" (ngModelChange)="fuenteSize1($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="8">8 px</option>
                            <option value="9">9 px</option>
                            <option value="10">10 px</option>
                            <option value="11">11 px</option>
                            <option value="12">12 px</option>
                            <option value="14">14 px</option>
                            <option value="16">16 px</option>
                            <option value="18">18 px</option>
                            <option value="20">20 px</option>
                            <option value="22">22 px</option>
                            <option value="24">24 px</option>
                            <option value="26">26 px</option>
                            <option value="28">28 px</option>
                            <option value="36">36 px</option>
                            <option value="48">48 px</option>
                            <option value="72">72 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Interlineado</label>
                        <select [ngModel]="interlineadoFront1" (ngModelChange)="interFunction1($event)"
                            class="form-select" aria-label="Default select example">
                            <option value="10px">10 px</option>
                            <option value="20px">20 px</option>
                            <option value="30px">30 px</option>
                            <option value="40px">40 px</option>
                            <option value="50px">50 px</option>
                            <option value="60px">60 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Alineación</label>
                        <select [ngModel]="alineacion1" (ngModelChange)="txtAling1($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="left">Izquierda</option>
                            <option value="center">Centrado</option>
                            <option value="right">Derecha</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style1Line2" (ngModelChange)="styleOneLineTwo($event)"
                            class="form-check-input m-0" type="checkbox" id="boldSecond">
                        <label class="form-check-label" for="boldSecond">Bold</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style2Line2" (ngModelChange)="styleTwoLineTwo($event)"
                            class="form-check-input m-0" type="checkbox" id="italicSecond">
                        <label class="form-check-label" for="italicSecond">Italic</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style3Line2" (ngModelChange)="styleThreeLineTwo($event)"
                            class="form-check-input m-0" type="checkbox" id="underlineSecond">
                        <label class="form-check-label" for="underlineSecond">Subrayar</label>
                    </div>
                </div>
                <div type="button" (click)="eliminarDos()"
                    class="col-md-1 d-flex align-items-center justify-content-center text-center pe-auto flex-column ">
                    <img src="assets/img/cerrar.png" width="30px" />
                    <p class="p-0 m-0">
                        Eliminar linea
                    </p>
                </div>
            </div>
            <!-- <h3 *ngIf="isCollapse1">Linea 3</h3> -->
            <div class="row mt-3" *ngIf="isCollapse1">
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Texto</label>
                        <input type="text" [(ngModel)]="textoLineaTres" class="form-control" id="inputPassword">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tipo de Fuente</label>
                        <select [ngModel]="tipoFuente2" (ngModelChange)="onChangeFamily2($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="Arial">Arial</option>
                            <option value="Typography Times">Times</option>
                            <option value="Technical">Technical</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="EnviroD">Enviro D</option>
                            <option value="RegencyScriptFLF">Regency Script FLP Plain</option>
                            <option value="English111 Vivace BT">English 111 Vivace BT</option>
                            <option value="OPTIEngraversOldEnglish">Engravers Old English</option>
                            <option value="Edwardian Script ITC">Edwardian Script ITC</option>
                            <option value="Century">Century</option>
                            <option value="Century Gothic">Century Gothic</option>
                            <option value="BrushScriptOpti"> Brush Script</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tamaño de fuente</label>
                        <select [ngModel]="tamanoFuente2" (ngModelChange)="fuenteSize2($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="8">8 px</option>
                            <option value="9">9 px</option>
                            <option value="10">10 px</option>
                            <option value="11">11 px</option>
                            <option value="12">12 px</option>
                            <option value="14">14 px</option>
                            <option value="16">16 px</option>
                            <option value="18">18 px</option>
                            <option value="20">20 px</option>
                            <option value="22">22 px</option>
                            <option value="24">24 px</option>
                            <option value="26">26 px</option>
                            <option value="28">28 px</option>
                            <option value="36">36 px</option>
                            <option value="48">48 px</option>
                            <option value="72">72 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Interlineado</label>
                        <select [ngModel]="interlineadoFront2" (ngModelChange)="interFunction2($event)"
                            class="form-select" aria-label="Default select example">
                            <option value="10px">10 px</option>
                            <option value="20px">20 px</option>
                            <option value="30px">30 px</option>
                            <option value="40px">40 px</option>
                            <option value="50px">50 px</option>
                            <option value="60px">60 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Alineación</label>
                        <select [ngModel]="alineacion2" (ngModelChange)="txtAling2($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="left">Izquierda</option>
                            <option value="center">Centrado</option>
                            <option value="right">Derecha</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style1Line3" (ngModelChange)="styleOneLineThree($event)"
                            class="form-check-input m-0" type="checkbox" id="boldThird">
                        <label class="form-check-label" for="boldThird">Bold</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style2Line3" (ngModelChange)="styletwoLineThree($event)"
                            class="form-check-input m-0" type="checkbox" id="italicThird">
                        <label class="form-check-label" for="italicThird">Italic</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style3Line3" (ngModelChange)="styleThreeLineThree($event)"
                            class="form-check-input m-0" type="checkbox" id="underlineThird">
                        <label class="form-check-label" for="underlineThird">Subrayar</label>
                    </div>
                </div>
                <div type="button" (click)="eliminarTres()"
                    class="col-md-1 d-flex align-items-center justify-content-center text-center pe-auto flex-column ">
                    <img src="assets/img/cerrar.png" width="30px" />
                    <p class="p-0 m-0">
                        Agregar una linea
                    </p>
                </div>
            </div>
            <!-- <h3 *ngIf="isCollapse2">Linea 4</h3> -->
            <div class="row mt-3" *ngIf="isCollapse2">
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Texto</label>
                        <input type="text" [(ngModel)]="textoLineaCuatro" class="form-control" id="inputPassword">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tipo de Fuente</label>
                        <select [ngModel]="tipoFuente3" (ngModelChange)="onChangeFamily3($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="Arial">Arial</option>
                            <option value="Typography Times">Times</option>
                            <option value="Technical">Technical</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="EnviroD">Enviro D</option>
                            <option value="RegencyScriptFLF">Regency Script FLP Plain</option>
                            <option value="English111 Vivace BT">English 111 Vivace BT</option>
                            <option value="OPTIEngraversOldEnglish">Engravers Old English</option>
                            <option value="Edwardian Script ITC">Edwardian Script ITC</option>
                            <option value="Century">Century</option>
                            <option value="Century Gothic">Century Gothic</option>
                            <option value="BrushScriptOpti"> Brush Script</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Tamaño de fuente</label>
                        <select [ngModel]="tamanoFuente3" (ngModelChange)="fuenteSize3($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="8">8 px</option>
                            <option value="9">9 px</option>
                            <option value="10">10 px</option>
                            <option value="11">11 px</option>
                            <option value="12">12 px</option>
                            <option value="14">14 px</option>
                            <option value="16">16 px</option>
                            <option value="18">18 px</option>
                            <option value="20">20 px</option>
                            <option value="22">22 px</option>
                            <option value="24">24 px</option>
                            <option value="26">26 px</option>
                            <option value="28">28 px</option>
                            <option value="36">36 px</option>
                            <option value="48">48 px</option>
                            <option value="72">72 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Interlineado</label>
                        <select [ngModel]="interlineadoFront3" (ngModelChange)="interFunction3($event)"
                            class="form-select" aria-label="Default select example">
                            <option value="10px">10 px</option>
                            <option value="20px">20 px</option>
                            <option value="30px">30 px</option>
                            <option value="40px">40 px</option>
                            <option value="50px">50 px</option>
                            <option value="60px">60 px</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3 form-group">
                        <label for="inputPassword" class="">Alineación</label>
                        <select [ngModel]="alineacion3" (ngModelChange)="txtAling3($event)" class="form-select"
                            aria-label="Default select example">
                            <option value="left">Izquierda</option>
                            <option value="center">Centrado</option>
                            <option value="right">Derecha</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style1Line4" (ngModelChange)="styleOneLineFour($event)"
                            class="form-check-input m-0" type="checkbox" id="BoldQuarter">
                        <label class="form-check-label" for="BoldQuarter">Bold</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style2Line4" (ngModelChange)="styleTwoLineFour($event)"
                            class="form-check-input m-0" type="checkbox" id="italicQuarter">
                        <label class="form-check-label" for="italicQuarter">Italic</label>
                    </div>
                    <div
                        class="form-check form-switch d-flex flex-column align-items-center justify-content-center p-0 w-100">
                        <input [ngModel]="style3Line4" (ngModelChange)="styleThreeLineFour($event)"
                            class="form-check-input m-0" type="checkbox" id="underlineQuarter">
                        <label class="form-check-label" for="underlineQuarter">Subrayar</label>
                    </div>
                </div>
                <div type="button" (click)="eliminarCuatro()"
                    class="col-md-1 d-flex align-items-center justify-content-center text-center pe-auto flex-column ">
                    <img src="assets/img/cerrar.png" width="30px" />
                    <p class="p-0 m-0">
                        Agregar una linea
                    </p>
                </div>
            </div>
        </div>


    </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
</svg>

<div class="w-50 p-5 d-none">
    <main>
        <svg viewBox="0 0 100 100">
            <path d="M 0,50 a 50,50 0 1,1 0,1 z" id="circle" />
            <text>
                <textPath xlink:href="#circle">
                    {{textoLineaUna}}
                </textPath>
            </text>
        </svg>
    </main>
</div>

<br>
<div class="edit-image" *ngIf="!verTexto">
    <div class="row align-items-center d-flex w-75 m-auto justify-content-center">

        <div class="col-6">
            <div class="col-8 m-auto">
                <div class="form-group">
                    <label>Insertar archivo </label>
                    <div class="custom-file">
                        <input #myInput type="file" class="custom-file-input" id="add-fotoP" name="imgP"
                            aria-describedby="image-desc" accept="application/pdf" (change)="selectFiles($event)">
                        <label for="add-fotoP">
                            <img src="assets/svg/agregar/subir.svg" width="20px" alt="icono subir">
                        </label>
                    </div>
                    <br>
                    <span style="color: red;">
                        El archivo debe de ser de alta resolución y en formato pdf
                    </span>
                </div>
            </div>
        </div>


    </div>

    <div class="text-center">
        <button (click)="editarFotos()" class="btn car m-auto text-center">
            Subir Imagen
        </button>
    </div>
</div>


<div class="row align-items-center d-flex d-none">

    <div class="col-6">
        <div class="text-center">
            <button class="btn col col-lg-4 btn-danger btn-block ml-4" (click)="activarMarca()">Ver diseño
                final</button>
        </div>
    </div>

</div>
<br><br>
<div class="text-center d-none mt-3">
    <button (click)="addCategory()" class="btn car m-2">
        Ver diseño final
    </button>
</div>
<div class="text-center mt-3">

    <button (click)="desactivarModal()" *ngIf="verTexto" class="btn car m-2">
        Agregar a carrito
    </button>

    <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn car m-2 d-none">
        Agregar a carrrrrrito
    </button>
    <!--  <button *ngIf="!verTexto" data-bs-target="#exampleModal" (click)="editarFotos()" class="btn car m-2">
        Agregar a carrito
    </button> -->

    <button *ngIf="verTexto" (click)="activarMarca()" class="btn confi m-2">
        Ver diseño final
    </button>
    <button *ngIf="verTexto" (click)="ocultarTexto()" class="btn imb m-2 d-none">
        Subir una imagen
    </button>
    <button *ngIf="!verTexto" (click)="ocultarTexto()" class="btn imb m-2 d-none">
        Editar texto
    </button>

    <button *ngIf="!verTexto" (click)="ocultarTexto()" class="btn imb m-2">
        <!-- Editar texto -->
        Regresar
    </button>
</div>
<br><br>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header border-0">
                <h5 class="modal-title" id="exampleModalLabel">Confirmar compra</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h3 class="mt-1">
                    Datos del Comprador
                </h3>

                <div class="row">
                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Nombre</b></label>
                            <span>*</span>
                            <input type="text" [(ngModel)]="nombreFront" class="form-control inpt-a" id="name">
                            <!-- <span *ngIf="">Ingrese su nombre</span> -->
                        </div>
                    </div>
                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Teléfono</b></label>
                            <span>*</span>
                            <input type="text" [(ngModel)]="telefonoFront" class="form-control inpt-a" id="phone">
                            <!-- <span>Ingrese su teléfono</span> -->
                        </div>
                    </div>
                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Correo</b></label>
                            <span>*</span>
                            <input style="padding-bottom: 0;" type="email" [(ngModel)]="correoFront"
                                class="form-control inpt-a" id="email">
                            <!-- <span>Ingrese su correo</span> -->
                        </div>
                    </div>

                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Notas</b></label>
                            <input type="text" [(ngModel)]="notasFront" class="form-control inpt-a" id="inputNotas">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Nit</b></label>
                            <input type="text" [(ngModel)]="nitFront" class="form-control inpt-a" id="nit">
                        </div>
                    </div>

                </div>

                <h3 class="mt-1">
                    Datos de Envio
                </h3>
                <span>Envío gratis dentro de la Ciudad de Guatemala.</span>
                <span>No se hacen envíos a zona rojas.</span>
                <div class="row">
                    <div class="col-6">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="activoFijo" (click)="envio('si')"
                                id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Envío a domicilio
                            </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="activoFijo" (click)="envio('no')"
                                id="flexRadioDefault2" checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                                Pickup (Recoge en tienda)
                            </label>
                        </div>
                    </div>
                    <div class="col-9 m-auto mt-2 d-none">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="activoFijo" (click)="envio('si')"
                                id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Envío a Carretera a El Salvador, Naranjo, San Cristobal, Ruta Atlántico: Q35.00
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="mostrarEnvio">
                    <div class="col-12">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Dirección Exacta</b></label>
                            <span>*</span>
                            <input type="text" [(ngModel)]="direccionFront" class="form-control inpt-a" id="inputNotas">
                            <!-- <span *ngIf="">Ingrese su nombre</span> -->
                        </div>
                    </div>

                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Departamento</b></label>
                            <span>*</span>
                            <input type="text" [(ngModel)]="depaFront" class="form-control inpt-a" id="inputNotas">
                        </div>
                    </div>

                    <div class="col-6">
                        <div class=" form-group">
                            <label for="inputNotas" class=""> <b>Municipio</b></label>
                            <span>*</span>
                            <input type="text" [(ngModel)]="muniFront" class="form-control inpt-a" id="inputNotas">
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="activoFijo1" (click)="proveedor('no')"
                                id="prover1">
                            <label class="form-check-label" for="prover1">
                                Envío a Carretera a El Salvador, Naranjo, San Cristobal, Ruta Atlántico: Q35.00
                            </label>
                        </div>
                    </div>
                    <div class="col-6 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="activoFijo1" (click)="proveedor('no')"
                                id="prover2">
                            <label class="form-check-label" for="prover2">
                                Ciudad Capital (gratis)
                            </label>
                        </div>
                    </div>
                    <div class="col-6 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="activoFijo1" (click)="proveedor('si')"
                                id="prover3">
                            <label class="form-check-label" for="prover3">
                                Otros municipios y departamentos
                            </label>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="form-group" *ngIf="mostrarPro">
                            <label for="inputNotas" class=""> <b>Proveedor de envio</b></label>
                            <br>
                            <span style="color: black; font-size: 15px; width: 75%; margin: auto;"
                                class="text-center">El pago del envío se paga directo con el proveedor de
                                transporte al recibir el
                                producto.</span>
                            <br>
                            <br>
                            <select [ngModel]="proverFront" (ngModelChange)="proft($event)" name="proveedor"
                                id="proveedor">
                                <option value="Guatex">Guatex</option>
                                <option value="Cargo Expreso">Cargo Expreso</option>
                            </select>
                            <br>
                            <br>
                            <span class="d-none">Envíos a Municipios y Departamentos de la República</span>
                        </div>
                    </div>

                </div>

                <div class="text-center mt-5">
                    <input (click)="botonAC()" type="checkbox" id="heaceptado">
                    <label style="color: red;" for="heaceptado">
                        &nbsp;He revisado los datos y me parecen correctos.
                    </label>
                </div>
            </div>
            <div class="modal-footer border-0">
                <button type="button" class="btn confi" data-bs-dismiss="modal">Cerrar</button>
                <button *ngIf="verTexto" data-bs-target="#exampleModal" [disabled]="activarBT" (click)="addCategory()"
                    class="btn car m-2">
                    Confirmar Compra
                </button>
                <button *ngIf="!verTexto" data-bs-target="#exampleModal" [disabled]="activarBT" (click)="editarFotos()"
                    class="btn car m-2">
                    Confirmar Compra
                </button>
            </div>
        </div>
    </div>
</div>

<input #myInput type="file" class="custom-file-input d-none" id="add-fotoP" name="imgP" aria-describedby="image-desc"
    accept="image/*" (change)="selectFiles($event)">

<div class="modal fade" id="exampleModalDescar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Descargar</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div> -->
            <div class="modal-body">
                <h3>Deseas descargar una copia de tu diseño en PDF</h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" (click)="downloadPDF()" class="btn btn-primary">Descargar</button>
            </div>
        </div>
    </div>
</div>