export class Transaccion {
    constructor(
        public deviceFingerPrint: number,
        public numTarjeta: string,
        public direccionFacturacion: string,
        public expMes: string,
        public expAnno: string,
        public codigoSeguridad: string,
        public monto: string,
        public moneda: string = 'GTQ',
        public nombre: string,
        public apellido: string,
        public direccion: string,
        public departamento: string,
        public codPostal: string,
        public pais: string = 'GT',
        public email: string,
        public celular: string,
        public idEmpresa: number,
        public idCliente: number,
        public idTarjeta: number,
        public noAutorizacion: number,
        public ipOrigen: number,
        public descripcion: string = 'test',
        public estadoTransaccion: number,
        public estadoRegistro: number,
        public tipoEnvio: string,
    ) { }
}
