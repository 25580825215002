import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sellos-personalizados',
  templateUrl: './sellos-personalizados.component.html',
  styleUrls: ['./sellos-personalizados.component.scss']
})
export class SellosPersonalizadosComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.title.setTitle('  Sellos Personalizados | Vile ');
    this.meta.updateTag({ name: 'keywords', content: 'Sellos personalizados Guatemala, sellos automáticos personalizados, sellos de bolsillo personalizados' });
    this.meta.updateTag({ name: 'description', content: 'Vile te ofrece sellos personalizados de escritorio y de bolsillo marca trodat Shiny' });
    this.meta.updateTag({ property: 'og:description', content: 'Vile te ofrece sellos personalizados de escritorio y de bolsillo marca trodat Shiny' });
  }

}
