import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ILine } from 'src/app/interfaces/ILine';
import { CustomId } from 'src/app/interfaces/CustomsId';
import { ProductsId } from 'src/app/interfaces/ProductsId';
import { Custom } from 'src/app/models/custom.mode';
import { Product } from 'src/app/models/product.model';
import { Ticket } from 'src/app/models/ticket.model';
import { UrlId } from 'src/app/models/urlId.model';
import { ProductsService } from 'src/app/services/products.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { IStock } from 'src/app/interfaces/IStock';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { AppComponent } from 'src/app/app.component';
declare var $: any

@Component({
  selector: 'app-personalizar',
  templateUrl: './personalizar.component.html',
  styleUrls: ['./personalizar.component.scss']
})
export class PersonalizarComponent implements OnInit {
  @ViewChild('myInput')
  myInputVariable: ElementRef;

  id: string;
  productTitle: string;
  product: Product;
  infoCustom: Custom;
  infoTicket: Ticket;
  public status: string;
  public buscarUrl: UrlId;
  public fotos = [];
  public colors = [];
  arregloCorreos = ['']
  lineas: ILine;
  selectedPrincipal: FileList;
  fileee: FileList;
  productForm: FormGroup;
  notasFront: string = '';
  correoFront: string = '';
  nombreFront: string = '';
  telefonoFront: string = '';
  direccionFront: string = '';
  depaFront: string = '';
  muniFront: string = '';
  nitFront: string = '';
  varStampColor: string = '';
  verMarca = false;
  pauFor = 'none';

  /* telefonoFront: string = ''; */
  /* notasFront: string = '';
  notasFront: string = ''; */


  isLoading: boolean = false;
  isSubmitting: boolean = false;
  isDeleting: boolean = false;
  isUpdating: boolean = false;
  verTexto: boolean = true;


  /* Globales */
  colorBorder: string;
  typeBorder: string;
  borde: string;
  cobrar: boolean;
  tania: string;
  chicas = "italic";
  isCollapse = false;
  isCollapse1 = false;
  isCollapse2 = false;
  devices = 'one two three'.split(' ');
  selectedDevice: string;

  /* Primera Linea */
  textoLineaUna: string;
  tipoFuente: string;
  tamanoFuente: string;
  alineacion: string;
  interlineadoFront: string;
  style = false;
  style2 = false;
  style3 = false;

  /* Segunda Linea */
  hola1: string;
  textoLineaDos: string;
  tipoFuente1: string;
  tamanoFuente1: string;
  interlineadoFront1: string;
  alineacion1: string;
  style1Line2 = false;
  style2Line2 = false;
  style3Line2 = false;

  /* Tercera Linea */
  hola2: string;
  textoLineaTres: string;
  tipoFuente2: string;
  tamanoFuente2: string;
  interlineadoFront2: string;
  alineacion2: string;
  style1Line3 = false;
  style2Line3 = false;
  style3Line3 = false;

  /* Cuarta Linea */
  hola3: string;
  textoLineaCuatro: string;
  tipoFuente3: string;
  tamanoFuente3: string;
  interlineadoFront3: string;
  alineacion3: string;
  style1Line4 = false;
  style2Line4 = false;
  style3Line4 = false;

  variableWidth: string;
  variableHeigth: string;

  hoy = new Date();
  imagenSubida: string;

  private form: FormData = new FormData();

  constructor(
    private _route: ActivatedRoute,
    private rutaActiva: ActivatedRoute,
    private productService: ProductsService,
    protected _sanitizer: DomSanitizer,
    private _fb: FormBuilder,
    private _storageService: StorageService,
    private localStorage: LocalStorageService,
    private title: Title,
    private _router: Router,
    private comp: AppComponent
  ) {
    this.buscarUrl = new UrlId('');

    /* this.downloadPDF(); */
  }

  ngOnInit(): void {
    this.title.setTitle('  Personalizar | Vile ');
    this.product = new Product('', '', '', '', [this.clearIStock()], '', '', 0, [''], '', '', '', [''], '', '', '', '', '', '', '', false, true);

    this._route.params.subscribe((params) => {
      this.productTitle = params['id'].replace('%20', ' ');

      this.id = "/" + this.rutaActiva.snapshot.params['name'];

    })
    this.infoCustom = new Custom('', '', '', this.productTitle, '', this.clearIGroup(), this.clearIGroup(), this.clearIGroup(), this.clearIGroup(), '', '', '', '', [''], '', '', '', '', '', '');
    this.infoTicket = new Ticket([this.clearICustom()], [this.clearIProduct()], 'store', 0, '', '', '', '', '', '', '', '', '', 0, '');
    this.initFormBuilder();
    this.listarProducto();
    this.cerrar();


  }

  initFormBuilder(): void {
    this.productForm = this._fb.group({
      image: ['']
    }, { updateOn: 'change' });
  }

  clearIGroup(): ILine {
    return {
      texto: '',
      tipoFuente: '',
      tamanioFuente: '',
      alineacion: '',
      interlineado: '',
      bold: false,
      italic: false,
      underline: false
    }
  }

  clearICustom(): CustomId {
    return {
      customId: 'V3IEIVvwS6NNELdH27Cl',
      quantity: 10,
      colorTinta: 'blue',
      colorCase: 'Azul'
    }
  }

  clearIProduct(): ProductsId {
    return {
      productId: '6GlpkI3Ca6ndAe4R4w4i',
      quantity: 10,
      colorTinta: 'blue',
      colorCase: 'Azul'
    }
  }

  clearIStock(): IStock {
    return {
      color: '',
      stock: 0
    }
  }

  cerrar(): void {
    $(document).ready(function () {
      // show the alert
      setTimeout(function () {
        $(".alert").alert('close');
      }, 90000);
    });

  }

  listarProducto(): void {
    this.buscarUrl.id = this.productTitle;
    this.productService.getProductById(this.productTitle).subscribe(
      res => {
        if (!res.product) {
          this.status = 'error';
        } else {
          this.product = res.product;
          this.status = 'ok';
          this.miguel();
        }
      },
      err => this.onError(err)
    );
  }

  miguel(): void {
    switch (this.product.codigo) {
      case "4912":
        this.variableWidth = "47mm"
        this.variableHeigth = "18mm"
        break;
      case "3912":
        this.variableWidth = "47mm"
        this.variableHeigth = "18mm"
        break;
      case "S-723":
        this.variableWidth = "47mm"
        this.variableHeigth = "18mm"
        break;
      case "S-823":
        this.variableWidth = "47mm"
        this.variableHeigth = "18mm"
        break;
      case "9511":
        this.variableWidth = "38mm"
        this.variableHeigth = "14mm"
        break;
      case "4911":
        this.variableWidth = "38mm"
        this.variableHeigth = "14mm"
        break;
      case "3911":
        this.variableWidth = "38mm"
        this.variableHeigth = "14mm"
        break;
      case "S-722":
        this.variableWidth = "38mm"
        this.variableHeigth = "14mm"
        break;
      case "S-822":
        this.variableWidth = "38mm"
        this.variableHeigth = "14mm"
        break;
      case "S-530":
        this.variableWidth = "30mm"
        this.variableHeigth = "30mm"
        break;
      case "4929":
        this.variableWidth = "50mm"
        this.variableHeigth = "30mm"
        break;
      case "9512":
        this.variableWidth = "47mm"
        this.variableHeigth = "18mm"
        break;
      case "S-542":
        this.variableWidth = "42mm"
        this.variableHeigth = "42mm"
        break;
    }
  }

  onError(error: any): void {
    let errorMessage = error;
    alert("Error al ejecutar la acción");
    if (errorMessage != null) {
      this.status = 'error';
    }

  }

  activarBorde = {
    solid_red: false,
    double_red: false,
    dotted_red: false,
    dashed_red: false,
    solid_blue: false,
    double_blue: false,
    dotted_blue: false,
    dashed_blue: false
  };

  onChange(newValue) {
    this.selectedDevice = newValue;
  }

  onChangeFamily(newValue) {
    this.tipoFuente = newValue;
  }

  onChangeFamily1(newValue) {
    this.tipoFuente1 = newValue;
  }

  color_border(newValue1: any) {
    this.colorBorder = newValue1;
  }

  slobo(newValue1: any) {
    this.colorBorder = newValue1;
    /* this.cobrarA(this.colorBorder); */

    if (newValue1 !== "black") {
      this.cobrar = true;
    } else {
      this.cobrar = false;
    }
  }

  ricardo(newValue: any) {
    this.typeBorder = newValue;
  }

  borde_t(newValue: any) {
    this.tania = newValue;
  }

  style_t(newValue: any) {
    this.style = newValue;
  }

  style_t2(newValue: any) {
    this.style2 = newValue;
  }

  style_t3(newValue: any) {
    this.style3 = newValue;
  }

  fuenteSize(newValue: any) {
    this.tamanoFuente = newValue;
  }

  fuenteSize1(newValue: any) {
    this.tamanoFuente1 = newValue;
  }

  txtAling(newValue: any) {
    this.alineacion = newValue;
  }

  txtAling1(newValue: any) {
    this.alineacion1 = newValue;
  }

  toggleState() {
    if (this.isCollapse && this.isCollapse1 && !this.isCollapse2 && this.product.codigo !== "91021" && this.product.codigo !== "4911" && this.product.codigo !== "3911" && this.product.codigo !== "S-722" && this.product.codigo !== "S-822") {
      this.isCollapse2 = true;
    } else if (this.isCollapse && !this.isCollapse1 && !this.isCollapse2) {
      this.isCollapse1 = true;
    } else if (!this.isCollapse && !this.isCollapse1 && !this.isCollapse2) {
      this.isCollapse = true;
    }

  }

  toggleState1() {
    let foo = this.isCollapse1;
    this.isCollapse1 = foo === false ? true : false
  }

  ocultarTexto() {
    if (this.varStampColor != "" && this.colorBorder != undefined) {
      let foo = this.verTexto;
      this.verTexto = foo === false ? true : false
    }
  }

  toggleState2() {
    let foo = this.isCollapse2;
    this.isCollapse2 = foo === false ? true : false
  }

  eliminarCuatro() {
    this.isCollapse2 = false;
    this.clearControls(3);
  }

  eliminarTres() {
    if (!this.isCollapse2) {
      this.isCollapse1 = false;
      this.clearControls(2);
    } else {
      alert("elimina la cuarta linea")
    }
  }

  eliminarDos() {
    if (!this.isCollapse1) {
      this.isCollapse = false;
      this.clearControls(1);
    } else {
      alert("elimina la tercera linea")
    }
  }

  interFunction(newValue: any) {
    this.interlineadoFront = newValue;
  }
  /* Segunda Linea */
  styleThreeLineTwo(newValue: any) {
    this.style3Line2 = newValue;
  }

  styleTwoLineTwo(newValue: any) {
    this.style2Line2 = newValue;
  }

  styleOneLineTwo(newValue: any) {
    this.style1Line2 = newValue;
  }
  interFunction1(newValue: any) {
    this.interlineadoFront1 = newValue;
  }
  /* Tercera Linea */

  onChangeFamily2(newValue) {
    this.tipoFuente2 = newValue;
  }

  fuenteSize2(newValue: any) {
    this.tamanoFuente2 = newValue;
  }

  txtAling2(newValue: any) {
    this.alineacion2 = newValue;
  }

  styleOneLineThree(newValue: any) {
    this.style1Line3 = newValue;
  }

  styletwoLineThree(newValue: any) {
    this.style2Line3 = newValue;
  }

  styleThreeLineThree(newValue: any) {
    this.style3Line3 = newValue;
  }

  interFunction2(newValue: any) {
    this.interlineadoFront2 = newValue;
  }

  stampColor(newValue: any) {
    this.varStampColor = newValue;
  }

  /* Cuarta Linea */

  onChangeFamily3(newValue) {
    this.tipoFuente3 = newValue;
  }

  fuenteSize3(newValue: any) {
    this.tamanoFuente3 = newValue;
  }

  txtAling3(newValue: any) {
    this.alineacion3 = newValue;
  }

  styleOneLineFour(newValue: any) {
    this.style1Line4 = newValue;
  }

  styleTwoLineFour(newValue: any) {
    this.style2Line4 = newValue;
  }

  styleThreeLineFour(newValue: any) {
    this.style3Line4 = newValue;
  }

  interFunction3(newValue: any) {
    this.interlineadoFront3 = newValue;
  }

  addCategory(): void {
    if (this.nombreFront != "") {
      if (this.telefonoFront != "") {
        if (this.correoFront != "") {
          if (!this.isSubmitting) {
            this.isSubmitting = true;
            this.infoCustom.color = this.colorBorder;
            this.infoCustom.borde = this.typeBorder;
            this.infoCustom.borderSize = this.tania;
            this.infoCustom.line1.texto = this.textoLineaUna;
            this.infoCustom.line1.tipoFuente = this.tipoFuente;
            this.infoCustom.line1.tamanioFuente = this.tamanoFuente;
            this.infoCustom.line1.alineacion = this.alineacion;
            this.infoCustom.line1.interlineado = this.interlineadoFront;
            this.infoCustom.line1.bold = this.style;
            this.infoCustom.line1.italic = this.style2;
            this.infoCustom.line1.underline = this.style3;

            this.infoCustom.line2.texto = this.textoLineaDos;
            this.infoCustom.line2.tipoFuente = this.tipoFuente1;
            this.infoCustom.line2.tamanioFuente = this.tamanoFuente1;
            this.infoCustom.line2.alineacion = this.alineacion1;
            this.infoCustom.line2.interlineado = this.interlineadoFront1;
            this.infoCustom.line2.bold = this.style1Line2;
            this.infoCustom.line2.italic = this.style2Line2;
            this.infoCustom.line2.underline = this.style3Line2;

            this.infoCustom.line3.texto = this.textoLineaTres;
            this.infoCustom.line3.tipoFuente = this.tipoFuente2;
            this.infoCustom.line3.tamanioFuente = this.tamanoFuente2;
            this.infoCustom.line3.alineacion = this.alineacion2;
            this.infoCustom.line3.interlineado = this.interlineadoFront2;
            this.infoCustom.line3.bold = this.style1Line3;
            this.infoCustom.line3.italic = this.style2Line3;
            this.infoCustom.line3.underline = this.style3Line3;

            this.infoCustom.line4.texto = this.textoLineaCuatro;
            this.infoCustom.line4.tipoFuente = this.tipoFuente3;
            this.infoCustom.line4.tamanioFuente = this.tamanoFuente3;
            this.infoCustom.line4.alineacion = this.alineacion3;
            this.infoCustom.line4.interlineado = this.interlineadoFront3;
            this.infoCustom.line4.bold = this.style1Line4;
            this.infoCustom.line4.italic = this.style2Line4;
            this.infoCustom.line4.underline = this.style3Line4;
            if (this.proverFront) {
              this.infoCustom.notas = this.notasFront + ", el envio por " + this.proverFront;
            }
            if (!this.proverFront) {
              this.infoCustom.notas = this.notasFront;
            }
            this.infoCustom.nombre = this.nombreFront + " Nit: " + this.nitFront;
            this.arregloCorreos.push(this.correoFront);
            this.infoCustom.email = this.arregloCorreos;
            this.infoCustom.telefono = this.telefonoFront;
            this.infoCustom.extra2 = this.imagenSubida;
            this.infoCustom.extra3 = this.product.precioU.toString();

            this.infoCustom.direccion = this.direccionFront + " Departamento: " + this.depaFront + " Municipio: " + this.muniFront;

            this.infoCustom.datetime = this.hoy.getDate() + '-' + (this.hoy.getMonth() + 1) + '-' + this.hoy.getFullYear();

            this.desactivarModal();
            let addCatSubs = this.productService.addProduct(this.infoCustom).subscribe(
              res => {
                if (!res.message) {
                  this.status = 'error';
                  alert('No se pudo agregar el producto')
                } else {
                  this.status = 'ok';
                  Swal.fire({
                    title: 'Se ha agregado el pedido correctamente',
                    html: 'Su numero de orden es ' + res.id,
                    footer: '<p style="text-align: center; padding-bottom: 0;">Para darle seguimiento a tu pedido, <br> puedes comunicarte al 2314-2222 o por WhatsApp al 5755-6140</p>',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#40A5E2'
                  });
                  this.clearControls(0);
                  $('#exampleModal').modal('hide');
                  /*   this._router.navigateByUrl('inicio'); */

                }
                addCatSubs.unsubscribe();
                this.isSubmitting = false;
              },
              error => {
                this.onError(error);
                this.isSubmitting = false;
                addCatSubs.unsubscribe();
              }
            );
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Introduce tu correo',
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Introduce tu teléfono',
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Introduce tu nombre',
      })
    }

  }

  clearControls(idx: number): void {
    switch (idx) {
      case 1:
        this.textoLineaDos = '';
        this.tipoFuente1 = '';
        this.tamanoFuente1 = '';
        this.alineacion1 = '';
        this.style1Line2 = false;
        this.style2Line2 = false;
        this.style3Line2 = false;
        break;
      case 2:
        this.textoLineaTres = '';
        this.tipoFuente2 = '';
        this.tamanoFuente2 = '';
        this.alineacion2 = '';
        this.style1Line3 = false;
        this.style2Line3 = false;
        this.style3Line3 = false;
        break;
      case 3:
        this.textoLineaCuatro = '';
        this.tipoFuente3 = '';
        this.tamanoFuente3 = '';
        this.alineacion3 = '';
        this.style1Line4 = false;
        this.style2Line4 = false;
        this.style3Line4 = false;
        break;
      case 0:
        this.colorBorder = '';
        this.typeBorder = '';
        this.tania = '';
        this.textoLineaUna = '';
        this.tipoFuente = '';
        this.tamanoFuente = '';
        this.alineacion = '';
        this.style = false;
        this.style2 = false;
        this.style3 = false;

        this.textoLineaDos = '';
        this.tipoFuente1 = '';
        this.tamanoFuente1 = '';
        this.alineacion1 = '';
        this.style1Line2 = false;
        this.style2Line2 = false;
        this.style3Line2 = false;

        this.textoLineaTres = '';
        this.tipoFuente2 = '';
        this.tamanoFuente2 = '';
        this.alineacion2 = '';
        this.style1Line3 = false;
        this.style2Line3 = false;
        this.style3Line3 = false;

        this.textoLineaCuatro = '';
        this.tipoFuente3 = '';
        this.tamanoFuente3 = '';
        this.alineacion3 = '';
        this.style1Line4 = false;
        this.style2Line4 = false;
        this.style3Line4 = false;

        this.nombreFront = '';
        this.telefonoFront = '';
        this.correoFront = '';
        this.notasFront = '';

        if (this.imagenSubida != "") {

          this.myInputVariable.nativeElement.value = "";
        }

        break;
    }


  }

  addTicket(): void {
    if (!this.isSubmitting) {
      this.isSubmitting = true;
      /* this.infoTicket.customId = this.colorBorder;
      this.infoTicket.status = this.typeBorder; */

      let addCatSubs = this.productService.addTicket(this.infoTicket).subscribe(
        res => {
          if (!res.message) {
            this.status = 'error';
            alert('No se pudo agregar el producto')
          } else {
            /* this.listarProducto(); */
            this.status = 'ok';
            this.clearControls(0);
            Swal.fire({
              title: 'Acción completada',
              html: 'Se ha enviado un correo correctamente',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
          }
          addCatSubs.unsubscribe();
          this.isSubmitting = false;
        },
        error => {
          this.onError(error);
          this.isSubmitting = false;
          addCatSubs.unsubscribe();
        }
      );
    }
  }

  activarMarca(): void {
    this.verMarca = true;
    this.pauFor = 'grid';
    $("#exampleModalDescar").modal("show");
  }

  downloadPDF() {
    if (this.textoLineaUna === undefined) {
      Swal.fire({
        title: 'Error',
        html: 'Se debe de agregar por lo menos una linea',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    } else {
      // Extraemos el
      const DATA = document.getElementById('htmlData');
      const doc = new jsPDF('p', 'pt', 'a4');
      const options = {
        background: 'white',
        scale: 3
      };
      html2canvas(DATA, options).then((canvas) => {

        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        docResult.save(`${new Date().toISOString()}_desingVile.pdf`);
        this.pauFor = 'none';
      });
      /* this.verMarca = false; */
    }
  }

  selectFiles(event) {
    this.fileee = event.target.files;
  }

  /* fileP: File[] = []; */
  imagenPrincipal: File[] = [];
  image = '';

  dataFiler() {
    if (this.imagenPrincipal) {
      for (let i = 0; i < this.fileee?.length; i++) {
        this.imagenPrincipal.push(this.fileee.item(i))
      }
    }
  }

  editarFotos(): void {
    const promisesToResolve = [];
    this.dataFiler();

    if (this.imagenPrincipal) {
      promisesToResolve.push(this._storageService.uploadFilesRecursively('imagenes_para_sellos', this.imagenPrincipal));
    }

    Promise.all(promisesToResolve).then(imgRes => {
      const img: string = imgRes[0] || '';
      this.imagenSubida = img;
      console.log(this.imagenSubida)
      this.agregarConArchivo();
    }).catch((err) => {
      this.onError(err);
    });

  }

  onFileChange(e: any, action: string): void {
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const date = new Date();
        this.image = date.toJSON() + e.target.files[i].name;
        $(`#${action}-image-label`).html(this.image);
        /*  this.form.delete('image');
         this.form.append('image', e.target.files[i], this.image); */
      }
    }
  }

  desactivarModal() {
    if (this.textoLineaUna != undefined && this.varStampColor != "" && this.colorBorder != undefined) {
      this.infoCustom.color = this.colorBorder;
      this.infoCustom.borde = this.typeBorder;
      this.infoCustom.borderSize = this.tania;
      this.infoCustom.carcasa = this.varStampColor;

      this.infoCustom.line1.texto = this.textoLineaUna;
      this.infoCustom.line1.tipoFuente = this.tipoFuente;
      this.infoCustom.line1.tamanioFuente = this.tamanoFuente;
      this.infoCustom.line1.alineacion = this.alineacion;
      this.infoCustom.line1.interlineado = this.interlineadoFront;
      this.infoCustom.line1.bold = this.style;
      this.infoCustom.line1.italic = this.style2;
      this.infoCustom.line1.underline = this.style3;

      this.infoCustom.line2.texto = this.textoLineaDos;
      this.infoCustom.line2.tipoFuente = this.tipoFuente1;
      this.infoCustom.line2.tamanioFuente = this.tamanoFuente1;
      this.infoCustom.line2.alineacion = this.alineacion1;
      this.infoCustom.line2.interlineado = this.interlineadoFront1;
      this.infoCustom.line2.bold = this.style1Line2;
      this.infoCustom.line2.italic = this.style2Line2;
      this.infoCustom.line2.underline = this.style3Line2;

      this.infoCustom.line3.texto = this.textoLineaTres;
      this.infoCustom.line3.tipoFuente = this.tipoFuente2;
      this.infoCustom.line3.tamanioFuente = this.tamanoFuente2;
      this.infoCustom.line3.alineacion = this.alineacion2;
      this.infoCustom.line3.interlineado = this.interlineadoFront2;
      this.infoCustom.line3.bold = this.style1Line3;
      this.infoCustom.line3.italic = this.style2Line3;
      this.infoCustom.line3.underline = this.style3Line3;

      this.infoCustom.line4.texto = this.textoLineaCuatro;
      this.infoCustom.line4.tipoFuente = this.tipoFuente3;
      this.infoCustom.line4.tamanioFuente = this.tamanoFuente3;
      this.infoCustom.line4.alineacion = this.alineacion3;
      this.infoCustom.line4.interlineado = this.interlineadoFront3;
      this.infoCustom.line4.bold = this.style1Line4;
      this.infoCustom.line4.italic = this.style2Line4;
      this.infoCustom.line4.underline = this.style3Line4;
      if (this.proverFront) {
        this.infoCustom.notas = this.notasFront + ", el envió por " + this.proverFront;
      }
      if (!this.proverFront) {
        this.infoCustom.notas = this.notasFront;
      }
      this.infoCustom.nombre = this.nombreFront + " Nit: " + this.nitFront;
      this.arregloCorreos.push(this.correoFront);
      this.infoCustom.email = this.arregloCorreos;
      this.infoCustom.telefono = this.telefonoFront;
      this.infoCustom.extra2 = this.imagenSubida;
      this.infoCustom.extra3 = this.product.precioU.toString();

      this.infoCustom.direccion = this.direccionFront + " Departamento: " + this.depaFront + " Municipio: " + this.muniFront;

      this.infoCustom.datetime = this.hoy.getDate() + '-' + (this.hoy.getMonth() + 1) + '-' + this.hoy.getFullYear();

      this.localStorage.setCustomShop("Personalizado", this.infoCustom);
      this.comp.ngOnInit();
      Swal.fire({
        icon: 'success',
        title: 'Agregado',
        text: 'Se ha agregado el sello personalizado al carrito',
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Verifica todos los campos obligatorios',
      })
    }

  }

  agregarConArchivo() {
    if (this.varStampColor != "" && this.colorBorder != undefined) {
      this.infoCustom.color = this.colorBorder;
      this.infoCustom.borde = this.typeBorder;
      this.infoCustom.borderSize = this.tania;
      this.infoCustom.carcasa = this.varStampColor;

      this.infoCustom.line1.texto = this.textoLineaUna;
      this.infoCustom.line1.tipoFuente = this.tipoFuente;
      this.infoCustom.line1.tamanioFuente = this.tamanoFuente;
      this.infoCustom.line1.alineacion = this.alineacion;
      this.infoCustom.line1.interlineado = this.interlineadoFront;
      this.infoCustom.line1.bold = this.style;
      this.infoCustom.line1.italic = this.style2;
      this.infoCustom.line1.underline = this.style3;

      this.infoCustom.line2.texto = this.textoLineaDos;
      this.infoCustom.line2.tipoFuente = this.tipoFuente1;
      this.infoCustom.line2.tamanioFuente = this.tamanoFuente1;
      this.infoCustom.line2.alineacion = this.alineacion1;
      this.infoCustom.line2.interlineado = this.interlineadoFront1;
      this.infoCustom.line2.bold = this.style1Line2;
      this.infoCustom.line2.italic = this.style2Line2;
      this.infoCustom.line2.underline = this.style3Line2;

      this.infoCustom.line3.texto = this.textoLineaTres;
      this.infoCustom.line3.tipoFuente = this.tipoFuente2;
      this.infoCustom.line3.tamanioFuente = this.tamanoFuente2;
      this.infoCustom.line3.alineacion = this.alineacion2;
      this.infoCustom.line3.interlineado = this.interlineadoFront2;
      this.infoCustom.line3.bold = this.style1Line3;
      this.infoCustom.line3.italic = this.style2Line3;
      this.infoCustom.line3.underline = this.style3Line3;

      this.infoCustom.line4.texto = this.textoLineaCuatro;
      this.infoCustom.line4.tipoFuente = this.tipoFuente3;
      this.infoCustom.line4.tamanioFuente = this.tamanoFuente3;
      this.infoCustom.line4.alineacion = this.alineacion3;
      this.infoCustom.line4.interlineado = this.interlineadoFront3;
      this.infoCustom.line4.bold = this.style1Line4;
      this.infoCustom.line4.italic = this.style2Line4;
      this.infoCustom.line4.underline = this.style3Line4;
      if (this.proverFront) {
        this.infoCustom.notas = this.notasFront + ", el envió por " + this.proverFront;
      }
      if (!this.proverFront) {
        this.infoCustom.notas = this.notasFront;
      }
      this.infoCustom.nombre = this.nombreFront + " Nit: " + this.nitFront;
      this.arregloCorreos.push(this.correoFront);
      this.infoCustom.email = this.arregloCorreos;
      this.infoCustom.telefono = this.telefonoFront;
      this.infoCustom.extra2 = this.imagenSubida;
      this.infoCustom.extra3 = this.product.precioU.toString();

      this.infoCustom.direccion = this.direccionFront + " Departamento: " + this.depaFront + " Municipio: " + this.muniFront;

      this.infoCustom.datetime = this.hoy.getDate() + '-' + (this.hoy.getMonth() + 1) + '-' + this.hoy.getFullYear();

      this.localStorage.setCustomShop("Personalizado", this.infoCustom);
      this.comp.ngOnInit();
      Swal.fire({
        icon: 'success',
        title: 'Agregado',
        text: 'Se ha agregado el sello personalizado al carrito',
      }).then(() => {
        location.reload();
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Verifica todos los campos obligatorios',
      })
    }

  }

  public mostrarEnvio = false;
  public mostrarPro = false;
  public proverFront = "";
  public activarBT = true;

  envio(decision: string) {
    if (decision === 'si') {
      this.mostrarEnvio = true;
    } else {
      this.mostrarEnvio = false
    }
  }

  proveedor(decision: string) {
    if (decision === 'si') {
      this.mostrarPro = true;
    } else {
      this.mostrarPro = false
    }
  }

  proft(newValue1: any) {
    this.proverFront = newValue1;
  }

  ajustarInterlineado(necesito: string, elcodigo: string): number {
    let cent: number;
    switch (elcodigo) {
      case "4929":
      case "S-542":
        cent = parseInt(necesito);
        cent = cent + 9;
        break;
      case "3911":
      case "9511":
      case "4911":
      case "S-722":
      case "S-822":
      case "S-530":
        cent = parseInt(necesito);
        cent = cent + 5;
        break;
      case "S-823":
      case "4912":
      case "3912":
      case "S-723":
      case "9512":
        cent = parseInt(necesito);
        cent = cent + 7;
        break;
    }


    return cent
  }

  botonAC() {
    let foo = this.activarBT;
    this.activarBT = foo === false ? true : false
  }


}

