<app-menu></app-menu>
<div class="contenedor-general">
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                aria-label="Slide 3"></button> -->
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <h1 class="title-s">Litografía Vile</h1>
                <div class="img"></div>
            </div>
            <!-- <div class="carousel-item">
                <h1 class="title-s">Litografía Vile</h1>
                <div class="img"></div>
            </div>
            <div class="carousel-item">
                <h1 class="title-s">Litografía Vile</h1>
                <div class="img"></div>
            </div> -->
        </div>

        <!--?flechas laterales-->
        <!--  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button> -->
    </div>

    <div class="cintillo-blanco">
        <div class="texto">
            <h2>Explora nuestros productos de <br> <span>Litografía Vile</span> </h2>
            <!-- <h2></h2> -->
        </div>
        <button routerLink="/productos" type="button">Explorar</button>
    </div>

    <div class="litografia-info">

        <div class="texto">
            <div class="titulo">
                <h2>Litografía para</h2>
                <h4>Todas las necesidades</h4>
            </div>
            <p>Conoce nuestra línea de impresiones en litografía.</p>
            <h5>Impresión comercial</h5>
            <h5>Impresión para editoriales</h5>
            <h5>Empaques</h5>
            <button routerLink="/productos" type="button">Comprar</button>
        </div>
        <div class="img"></div>
    </div>

    <div class="litografia-slider">
        <div class="titulo">
            <h2>Litografía <span>vile</span></h2>
            <hr>
        </div>
        <div class="car-desk">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjetas">
                            <div class="tarjeta">
                                <h5>Libros</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Revistas</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Folletos</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Bifoliares</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Papel Membretado</h5>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjetas">
                            <div class="tarjeta">
                                <h5>Impresión digital</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Volantes</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Impresión full color</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Impresión cajas</h5>
                            </div>
                            <div class="tarjeta">
                                <h5>Trifoliares</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <div class="car-mov">

            <div id="carouselmovil" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjeta">
                            <h5>Libros</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Revistas</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Folletos</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Bifoliares</h5>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <h5>Papel memmbretado</h5>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselmovil"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselmovil"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

        </div>
        <button routerLink="/contactenos" type="button">Cotiza hoy mismo</button>
    </div>

    <div class="galeria-litografia">
        <div class="conoce">
            <div class="titulo">
                <h2>Conoce nuestra</h2>
                <h2>línea ecológica</h2>
            </div>
            <p>En Litografía Vile, estamos consientes del medio ambiente, por eso escogemos minuciosamente a nuestros
                proveedores, los cuales garantizan practicas responsables en la fabricación del papel.</p>
            <button routerLink="/productos" type="button">Comprar</button>
        </div>
        <div class="pri-img"></div>
        <div class="seg-img"></div>
        <div class="empresas">
            <div class="titulo">
                <h2>Para empresas</h2>
                <h5>Papelería corporativa y publicitaria</h5>
            </div>
            <p>Papel membretado, etiquetas, notas de envío, formularios, tarjetas de presentación, volantes, afiches y
                más.</p>
            <button routerLink="/productos" type="button">Comprar</button>
        </div>
    </div>

    <div class="acabados-premium">
        <div class="titulo">
            <h2>Acabados <span>premium</span></h2>
            <hr>
            <h6>Tenemos diferentes acabados premium para que tus</h6>
            <h6>impresiones resalten.</h6>
        </div>
        <div class="tarjetas">
            <div class="tarjeta">
                <div class="imagen">
                    <img src="assets/img/premium-uv.png" alt="">
                </div>
                <h4>Barniz UV</h4>
                <button routerLink="/contactenos" type="button">Cotizar</button>
            </div>
            <div class="tarjeta">
                <div class="imagen">
                    <img src="assets/img/premium-barniz.png" alt="">
                </div>
                <h4>Barniz de máquina</h4>
                <button routerLink="/contactenos" type="button">Cotizar</button>
            </div>
            <div class="tarjeta">
                <div class="imagen">
                    <img src="assets/img/premium-relieve.png" alt="">
                </div>
                <h4>Relieve</h4>
                <button routerLink="/contactenos" type="button">Cotizar</button>
            </div>
            <div class="tarjeta">
                <div class="imagen">
                    <img src="assets/img/premium-laminado.png" alt="">
                </div>
                <h4>Laminado</h4>
                <button routerLink="/contactenos" type="button">Cotizar</button>
            </div>
        </div>
        <div class="car-mov">
            <div id="carouselmovilacabados" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="tarjeta">
                            <div class="imagen">
                                <img src="assets/img/premium-uv.png" alt="">
                            </div>
                            <h4>Barniz UV</h4>
                            <button routerLink="/contactenos" type="button">Cotizar</button>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <div class="imagen">
                                <img src="assets/img/premium-barniz.png" alt="">
                            </div>
                            <h4>Barniz de máquina</h4>
                            <button routerLink="/contactenos" type="button">Cotizar</button>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <div class="imagen">
                                <img src="assets/img/premium-relieve.png" alt="">
                            </div>
                            <h4>Relieve</h4>
                            <button routerLink="/contactenos" type="button">Cotizar</button>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="tarjeta">
                            <div class="imagen">
                                <img src="assets/img/premium-laminado.png" alt="">
                            </div>
                            <h4>Laminado</h4>
                            <button routerLink="/contactenos" type="button">Cotizar</button>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselmovilacabados"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselmovilacabados"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>

    <div class="cont-cintillo">
        <div class="vineta">
            <div class="cont"></div>
            <div class="cont"></div>
        </div>
        <div class="cintillo-naranja">
            <div class="texto">
                <h2>¿Te gusta lo que ves?</h2>
                <hr>
                <h5>Solicita una cotización hoy mismo</h5>
            </div>
            <button routerLink="/contactenos" type="button">Cotizar</button>
        </div>
    </div>
</div>
<app-footer></app-footer>