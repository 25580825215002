import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private url: string;
  private contType = {
    h: 'Content-Type',
    b: 'application/json',
  }
  private auth = {
    h: 'Authorization',
    b: '',
  }

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.API.url;
  }
  listCategories: () => Observable<any> = () => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.get(`${this.url}category/get_all`, { headers: headers });
  }

  /* getCategoryById: (categoryId: string) => Observable<any> = categoryId => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.get(`${this.url}admin/category/${categoryId}`, { headers: headers });
  } */


}
