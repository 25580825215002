import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-imprenta",
  templateUrl: "./imprenta.component.html",
  styleUrls: ["./imprenta.component.scss"],
})
export class ImprentaComponent implements OnInit {
  constructor(
    private title: Title,
    private meta: Meta
    ) {
  }
  
  
  ngOnInit(): void {
    this.title.setTitle('  Imprenta | Vile ');
    this.meta.updateTag({ name: 'keywords', content: ' elaboración de cajas, impresión de cajas, impresión de libros,Imprenta vile, imprenta guatemala, impresion dos tintas' });
    this.meta.updateTag({ name: 'description', content: 'Vile te ofrece el servicio de imprenta con tecnología de punta, trabajamos una, dos, tres tintas y completamente a Full Color. Impresión de libros, facturas, insertos, volantes, cajas, trifoliares, impresión full color' });
    this.meta.updateTag({ property: 'og:description', content: 'Vile te ofrece el servicio de imprenta con tecnología de punta, trabajamos una, dos, tres tintas y completamente a Full Color. Impresión de libros, facturas, insertos, volantes, cajas, trifoliares, impresión full color' });
  }
}
