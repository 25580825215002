<app-menu></app-menu>
<div class="pro">
  <div class="banner d-none">
    <div class="texto">
      <a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB"
        target="_blank"><button>Escribenos</button></a>
    </div>
  </div>
  <div class="productos">
    <div class="titulo">
      <h2>Explora nuestros porductos Multisellos Vile</h2>
    </div>
    <div class="contenedor-gen">
      <div class="izquierda">
        <div class="form-floating vis">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
            <option value="1">Producto Sello 1</option>
            <option value="2">Producto Sello 2</option>
            <option value="3">Producto Sello 3</option>
          </select>
          <label for="floatingSelect">Productos</label>
        </div>
        <h3>Busqueda avanzada</h3>
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
            <option value="1">Sello de Hule</option>
          </select>
          <label for="floatingSelect">Nombre productos:</label>
        </div>
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
            <option value="1">Trodat</option>
          </select>
          <label for="floatingSelect">Marcas:</label>
        </div>
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
            <option value="1">Código 4913</option>
          </select>
          <label for="floatingSelect">Producto:</label>
        </div>
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
            <option value="1">Escritorio Automático Hule</option>
          </select>
          <label for="floatingSelect">Categoria:</label>
        </div>
        <div class="precio">
          <h4>Precio:</h4>
          <input type="range" id="volume" step="any" name="volume" value="0" min="0" max="11" />
          <div class="valores">
            <p>Q.1.00</p>
            <p>Q.5,000.00</p>
          </div>
        </div>
      </div>
      <div class="derecha">
        <div class="form-floating esc">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
            <option value="1">Producto Sello 1</option>
            <option value="2">Producto Sello 2</option>
            <option value="3">Producto Sello 3</option>
          </select>
          <label for="floatingSelect">Productos</label>
        </div>
        <div class="botones">
          <button>Sellos</button>
          <button>Imprenta</button>
          <button>Litografía</button>
        </div>
        <div class="tarjetas">
          <div class="tarjeta img1">
            <button>Cotizar</button>
          </div>
          <div class="tarjeta img2">
            <button>Cotizar</button>
          </div>
          <div class="tarjeta img3">
            <button>Cotizar</button>
          </div>
        </div>
        <div class="tarjetas">
          <div class="tarjeta img1">
            <button>Cotizar</button>
          </div>
          <div class="tarjeta img2">
            <button>Cotizar</button>
          </div>
          <div class="tarjeta img3">
            <button>Cotizar</button>
          </div>
        </div>
        <div class="tarjetas">
          <div class="tarjeta img1">
            <button>Cotizar</button>
          </div>
          <div class="tarjeta img2">
            <button>Cotizar</button>
          </div>
          <div class="tarjeta img3">
            <button>Cotizar</button>
          </div>
        </div>
        <div class="sig">
          <button>Siguiente Página</button>
        </div>
      </div>
    </div>
  </div>
  <div class="seleccionado">
    <div class="texto">
      <h2>Producto Seleccionado</h2>
      <p class="naranja">Descripción del producto</p>
      <p>
        Características del producto. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exe.
      </p>
      <button class="vis">
        <a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB"
          target="_blank">Comprar</a>
      </button>
    </div>
    <div class="imagenes">
      <div class="grande">
        <img src="../../../assets/img/cp1-04.jpg" alt="" />
      </div>
      <div class="pequeñas vis">
        <div class="peq">
          <img src="../../../assets/img/cp1-04.jpg" alt="" />
        </div>
        <div class="peq">
          <img src="../../../assets/img/cp1-04.jpg" alt="" />
        </div>
        <div class="peq">
          <img src="../../../assets/img/cp1-04.jpg" alt="" />
        </div>
      </div>
      <div class="pequeñas esc">
        <div class="peq">
          <img src="../../../assets/img/cp1-04.jpg" alt="" />
        </div>
        <div class="peq">
          <img src="../../../assets/img/cp1-04.jpg" alt="" />
        </div>
        <div class="peq">
          <img src="../../../assets/img/cp1-04.jpg" alt="" />
        </div>
      </div>
    </div>
    <button class="esc">
      <a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB"
        target="_blank">Comprar</a>
    </button>
  </div>
  <div class="urgentes">
    <div class="tex">
      <h2>¿No encuentras el estilo que buscas?</h2>
      <p>Personaliza tu producto</p>
    </div>
    <button>Personalizar</button>
  </div>

  <div>
    <!-- <br>
    <br>
    <br> -->
    <div class="container-fluid vile-pro">
      <div class="row">
        <div class="col-lg-3 filters">
          <form class="buscador" (submit)="filtros()" #searchForm="ngForm">
            <div class="form-group">
              <h2>Búsqueda avanzada</h2>
              <span for="text"> Nombre del producto: </span>
              <input type="text" class="form-control" id="search" name="search" [(ngModel)]="buscarProducts.name"
                placeholder="Producto..." aria-label="Search" />
            </div>
            <div class="form-group my-3 real">
              <span for="text"> Marca: </span>
              <input type="text" class="form-control" id="search" name="search" [(ngModel)]="buscarProducts.marca"
                placeholder="Marca..." aria-label="Search" />
            </div>
            <div class="form-group my-3 real">
              <span for="text"> Producto </span>
              <div>
                <!-- <input type="radio" class="btn-check" name="first" id="btn-check-outlined">
                <label class="btn btn-outline-primary" for="btn-check-outlined">Sellos</label><br><br>

                <input type="radio" class="btn-check" name="first" id="btn-check-outlined1">
                <label class="btn btn-outline-primary" for="btn-check-outlined1">Litografia</label><br>
                <br> -->
                <!-- <input type="checkbox" name="input" [(ngModel)]="isChecked" (change)="checkValue(isChecked?'A':'B')" />
                <input type="checkbox" [checked]="checkbox" (change)="changed($event)" id="no" />
                <br><br> -->


                <input type="radio" id="feri" class="d-none" name="lewa" value="SELLO" (click)="click($event)">
                <label class="button-tipo" for="feri">Sellos</label><br><br>
                <input type="radio" id="feri1" class="d-none" name="lewa" value="LITOGRAFIA" (click)="click($event)">
                <label class="button-tipo1" for="feri1">Litografia</label><br><br>


                <!-- <input type="radio" name="lewa" value="b" (click)="click($event)">B -->
              </div>
            </div>
            <div class="form-group my-3 real">
              <span for="tags"> Categoria </span>
              <select class="form-control" id="tags" name="tags" #tags="ngModel" [(ngModel)]="buscarProducts.categoria">
                <option [value]="item.title" *ngFor="let item of categoriesDB">
                  {{ item.title }}
                </option>
              </select>
            </div>
            <div class="form-group my-3">
              <span for=""> Precio </span>
              <input type="range" class="form-control form-range rango" id="customRange1" name="search"
                [(ngModel)]="hola" placeholder="Precio" aria-label="Search" />
              <span *ngIf="hola != null"> {{ jose(hola) }}</span>
            </div>
            <p>Q. 0.00 ---------- Q 500.00</p>
            <div class="text-center">
              <button class="btn btn-search btn-sm mt-2 mx-2 text-white" type="submit">
                Buscar
              </button>
              <button class="btn btn-secondary btn-sm mx-2 mt-2 btn-clear" (click)="searchForm.reset(); general = ''">
                Limpiar
              </button>
            </div>
          </form>
        </div>
        <div class="filt">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1"><img src="assets/img/Consultas.png" alt="..." /></span>
            <input type="text" class="form-control" aria-label="Search" aria-describedby="basic-addon1" />
          </div>
          <button class="btn-filtros" data-bs-toggle="modal" data-bs-target="#modalfiltros">
            Filtros
          </button>
        </div>
        <div class="col-lg-9 mt-5">
          <br /><br />
          <div class="row" id="products">
            <div class="col-lg-3 col-sm-6 pb-2" *ngFor="let item of products">
              <!-- *ngFor="let item of products|searchBySpecie:specie|searchText:search|searchByTag:tag|searchByCategory:category|searchByBrand:brand|generalProductSearch:general|lengthOfArray:productsObj|slice:(page-1)*pageSize:page*pageSize"> -->
              <a [routerLink]="url(item.nombre)">
                <div class="card card-product h-100">
                  <img [src]="cleanURLResource(item.imgP)" class="card-img" alt="imagen producto" *ngIf="item.imgP" />
                  <!-- <div class="img-pro">
                </div> -->
                  <div class="card-body">
                    <h5 class="card-title title-card" [innerText]="item.nombre"></h5>
                    <h5 class="card-title cod-card" [innerText]="'Codigo ' + item.codigo"></h5>
                    <p class="price">
                      {{
                      item.precioU | currency: "GTQ":"symbol-narrow":"1.2-2"
                      }}
                    </p>
                    <!-- <p class="card-text paralelo" [innerText]="item.usos" *ngIf="item.usos"></p> -->
                    <a [routerLink]="url(item.nombre)" class="btn btn-success btn-card my-2 d-none">
                      Ver más
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <br>
    <br> -->
  </div>
</div>

<a data-bs-toggle="tooltip" data-bs-placement="left" title="Personalizar" routerLink="/personalizar"
  class="btn-flotante d-none">
  <img src="assets/svg/art.svg" width="63px" alt="icono Whatsapp" />
</a>

<app-footer></app-footer>

<!-- Modal -->
<div class="modal fade" id="modalfiltros" tabindex="-1" aria-labelledby="modalfiltros" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filtro</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="formulario-filtro">
          <form class="buscador" (submit)="filtros()" #searchForm="ngForm">
            <div class="row mb-3">
              <div class="col">
                <h4>Categorias</h4>
                <select class="form-control" id="tags" name="tags" #tags="ngModel"
                  [(ngModel)]="buscarProducts.categoria">
                  <option [value]="item.title" *ngFor="let item of categoriesDB">
                    {{ item.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <h4>Nombre del producto</h4>
                <input type="text" class="form-control" id="search" name="search" [(ngModel)]="buscarProducts.name"
                  placeholder="Producto..." aria-label="Search" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <h4>Marca</h4>
                <input type="text" class="form-control" id="search" name="search" [(ngModel)]="buscarProducts.marca"
                  placeholder="Marca..." aria-label="Search" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <h4>Precio</h4>
                <input type="range" class="form-control form-range rango" id="customRange1" name="search"
                  [(ngModel)]="hola" placeholder="Precio" aria-label="Search" />
                <label *ngIf="hola != null" class="text-center">
                  {{ jose(hola) }}</label>

                <p>Q. 0.00 ---------- Q 500.00</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="text-center">
                  <button data-bs-dismiss="modal" class="btn btn-search btn-sm mt-2 mx-2 text-white" type="submit">
                    Buscar
                  </button>
                  <button class="btn btn-secondary btn-sm mx-2 mt-2 btn-clear"
                    (click)="searchForm.reset(); general = ''">
                    Limpiar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-info d-none" (click)=" filtros()">
  HOLA
</button>

<div class="col-lg-12 text-center d-none" *ngIf="isLoading">
  <div class="spinner-border m-5" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<app-spinner [loading]="isLoading"></app-spinner>

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img src="assets/VileLogo.gif" width="300px" alt="">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> -->