import { Component, ElementRef, ViewChild, OnInit } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router"
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from "keen-slider"
import { stringify } from "querystring";
import { IStock } from "src/app/interfaces/IStock";
import { Product } from "src/app/models/product.model";
import { Url } from "src/app/models/url.model";
import { LocalStorageService } from "src/app/services/local-storage.service";

import { AppComponent } from "src/app/app.component";

/* import { Url } from 'src/app/models/url.model'; */
import { ProductsService } from "src/app/services/products.service";
import { Ticket } from "src/app/models/ticket.model";
import { CustomId } from "src/app/interfaces/CustomsId";
import { ProductsId } from "src/app/interfaces/ProductsId";
declare var $: any;
import Swal from 'sweetalert2';

function ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active")
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active")
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          main.moveToIdx(idx)
        })
      })
    }

    slider.on("created", () => {
      /* console.log(slider.track.details.rel) */
      addActive(slider.track.details.rel)
      addClickEvents()
      main.on("animationStarted", (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(next)
      })
    })
  }
}

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: [
    './producto.component.scss',
  ]
})

export class ProductoComponent implements OnInit {
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  @ViewChild("thumbnailRef") thumbnailRef: ElementRef<HTMLElement>
  id: string;
  productTitle: string;
  cantidadProducto = 1;
  product: Product;
  forCart: ProductsId;
  products: Product[];
  eleccionCase: string;
  colorBorder: string;
  colorElegido: string;
  cobrar = false;
  public status: string;
  public buscarUrl: Url;
  public fotos = [];

  public colors = [];
  slider: KeenSliderInstance = null;
  thumbnailSlider: KeenSliderInstance = null;
  isLoading = false;

  constructor(
    private _route: ActivatedRoute,
    private rutaActiva: ActivatedRoute,
    private productService: ProductsService,
    protected _sanitizer: DomSanitizer,
    private localStorage: LocalStorageService,
    private comp: AppComponent
  ) {
    this.buscarUrl = new Url('');
    this.product = new Product('', '', '', '', [this.clearIStock()], '', '', 0, [''], '', '', '', [''], '', '', '', '', '', '', '', false, true);
    this.forCart = this.clearIProduct();
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.productTitle = params['name'].replace('%20', ' ');

      this.id = "/" + this.rutaActiva.snapshot.params['name'];

    })
    this.listarProductos();
    this.listarProyecto();
  }

  clearICustom(): CustomId {
    return {
      customId: '',
      quantity: 0,
      colorTinta: '',
      colorCase: ''
    }
  }

  clearIProduct(): ProductsId {
    return {
      productId: '',
      quantity: 0,
      colorTinta: '',
      colorCase: '',
      precioU: 0,
    }
  }

  clearIStock(): IStock {
    return {
      color: '',
      stock: 0
    }
  }

  listarProyecto(): void {
    this.buscarUrl.name = this.productTitle;
    this.isLoading = true;
    this.productService.getProduct(this.buscarUrl).subscribe(
      res => {
        if (!res.products) {
          this.status = 'error';
        } else {
          this.product = res.products[0];
          this.fotos = res.products[0].img;
          this.colors = res.products[0].colores;
          /*  console.log(this.fotos)
           console.log(this.colors) */
          this.status = 'ok';
          this.isLoading = false;
        }
      },
      err => this.onError(err)
    );
  }

  onError(error: any): void {
    let errorMessage = error;
    alert("Error al ejecutar la acción");
    if (errorMessage != null) {
      this.status = 'error';
    }

  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement)
    this.thumbnailSlider = new KeenSlider(
      this.thumbnailRef.nativeElement,
      {
        initial: 0,
        slides: {
          perView: 4,
          spacing: 10,
        },
      },
      [ThumbnailPlugin(this.slider)]
    )
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
    if (this.thumbnailSlider) this.thumbnailSlider.destroy()
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  url(direccion: string): string {
    const nameProduct = direccion;
    return "/personalizar/" + nameProduct;
  }

  currency = function (string) {
    return new Intl.NumberFormat('en-ES', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(string);
  };

  listarProductos(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      // tslint:disable-next-line: deprecation
      const productsSubs = this.productService.listProducts().subscribe(
        res => {
          if (res.products?.length > 0) {
            this.products = res.products;
            this.products = this.products.slice(0, 4);
          } else {
            alert('No hay Productos almacenados');
            this.products = [];
          }
          this.status = 'ok';

        },
        error => this.onError(error),
        () => {
          productsSubs.unsubscribe();
          this.isLoading = false;
        }
      );
    }
  }

  slobo(newValue1: any) {
    this.colorBorder = newValue1;
    /* this.cobrarA(this.colorBorder); */

    if (newValue1 !== "black") {
      this.cobrar = true;
    } else {
      this.cobrar = false;
    }
  }

  functionCase(newValue1: any) {
    this.eleccionCase = newValue1;
  }

  agregarCart() {
    this.forCart.productId = this.product.id;
    this.forCart.quantity = this.cantidadProducto;
    if (this.product.id === "1010") {
      this.forCart.colorTinta = "black";
    } else {
      this.forCart.colorTinta = this.eleccionCase;
    }
    this.forCart.colorCase = this.colorElegido;
    this.forCart.precioU = this.product.precioU;
    this.localStorage.setShopping("ProductForCart", this.forCart);
    location.reload();
  }

  elegirColor(eleccion: any) {
    this.colorElegido = eleccion;
    /* console.log(eleccion) */
  }

  llevaBorde(eleccion: any): boolean {
    if (eleccion == this.colorElegido) {
      return true;
    } else {
      return false
    }
  }

  verificarCampos() {
    if (this.cantidadProducto != 0) {
      if (this.product.custom) {
        if (this.colorElegido != undefined) {
          $("#exampleModal1").modal("show");
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Elige un color de sello',
          })
        }
      } else {
        if (this.product.categoria === "Almohadillas") {
          console.log("hola");
          if (this.colorElegido != undefined) {

            this.agregarCart();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Elige un color de almoadilla',
            })
          }
        } else {
          this.agregarCart();
        }
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Elige la cantidad de sellos',
      })
    }

  }

  validatedCategorie(): boolean {

    switch (this.product.categoria) {
      case "Sellos de escritorio":
        return true;
        break;
      case "Fechadores":
        return false;
        break
      case "Sellos especiales":
        return false;
        break
      case "Repuestos":
        return false;
        break
      case "Sellos de agua":
        return true;
        break
      case "Sellos de bolsillo":
        return true;
        break
      case "Almohadillas":
        return false;
        break
    }
    return false;
  }
  validatedCategorie1(): boolean {

    switch (this.product.categoria) {
      case "Sellos de escritorio":
        return false;
        break;
      case "Fechadores":
        return false;
        break
      case "Sellos especiales":
        return false;
        break
      case "Almohadillas":
        return true;
        break
      case "Sellos de agua":
        return false;
        break
      case "Sellos de bolsillo":
        return false;
        break
    }
    return false;
  }

}