<nav class="navbar fixed-top navbar-expand-lg ">
    <div class="container-fluid ">
        <a class="navbar-brand" href="#"><img src="assets/svg/VILE-01.svg" width="100%" alt=""></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <img src="assets/svg/menu.svg" alt="">
        </button>

        <div class="collapse navbar-collapse " id="navbarNavDropdown">


            <ul class="navbar-nav ">

                <li class="nav-item" routerLink="/inicio">
                    <a class="nav-link active">Inicio</a>
                </li>
                <li class="nav-item" routerLink="/acerca-de">
                    <a class="nav-link active">Acerca de</a>
                </li>
                <li class="nav-item" routerLink="/litografia">
                    <a class="nav-link active">Litografía</a>
                </li>
                <li class="nav-item" routerLink="/imprenta">
                    <a class="nav-link active">Imprenta</a>
                </li>
                <li class="nav-item" routerLink="/sellos">
                    <a class="nav-link active">Sellos</a>
                </li>
                <li class="nav-item" routerLink="/productos" style="background: #FF5F3B">
                    <a class="nav-link active">Comprar aquí</a>
                </li>
                <li class="nav-item" routerLink="/contactenos">
                    <a class="nav-link active">Contácto</a>
                </li>
                <!--                 <div class="numeros">
                    <li class="nav-item sin">
                        <a class="nav-link active fil" href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank"><img src="../../../assets/svg/whatsapp_blue.svg" class="vis"><img src="../../../assets/svg/whatsapp_white_24dp.svg" class="esco">5755-6140</a>
                        <a class="nav-link active fil" href="tel:2314-2222"><img src="../../../assets/svg/phone.svg" class="vis"><img src="../../../assets/svg/phone_white.svg" class="esco" alt="...">2314-2222</a>
                    </li>
                </div> -->
            </ul>
            <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><img src="assets/img/Consultas.png" alt="..."></span>
                <input type="text" class="form-control" aria-label="Search" aria-describedby="basic-addon1">
            </div>
            <div class="icon-wh me-5">
                <a href="https://wa.me/50257556140?text=¡Buenas%20tardes!">
                    <img src="assets/svg/286165.svg" width="40px" alt="">
                </a>
            </div>
            <div class="icon-wh me-5">
                <span routerLink="/carrito" class="icono-carrito">
                    <img src="assets/svg/cart.svg" width="30px" alt="" class="svg-icono">
                </span>
            </div>
        </div>

    </div>
</nav>
<div class="linea-amarilla">
    <p>(30% de descuento por el bono 14)</p>
</div>
