
import { Injectable } from '@angular/core';
import { ProductsId } from '../interfaces/ProductsId';
import { Custom } from '../models/custom.mode';
import { Product } from '../models/product.model';
import { Ticket } from '../models/ticket.model';
const RESP = "resp";
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() {
        this.initialStorage();
    }

    public initialStorage(): void {
        const currents = JSON.parse(localStorage.getItem(RESP))
        if (!currents) {
            localStorage.setItem(RESP, JSON.stringify([]))
        }
    }

    set(key: string, data: boolean) {
        data = true;
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.log(e)

        }
    }

    setImg(key: string, data: File) {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.log(e)
        }
    }

    get(key: string) {
        try {
            return JSON.parse(localStorage.getItem(key))
        } catch (e) {
            console.log(e);
        }
    }

    setInicio(key: string, data: boolean) {
        data = true;
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.log(e)

        }
    }

    setInicio1(key: string, data: Custom) {
        try {
            var a: string[] = []
            a.push(JSON.parse(localStorage.getItem('Personalizado')));
            a.push(JSON.stringify(data));
            localStorage.setItem(key, JSON.stringify(a));

            /* localStorage.setItem(key, JSON.stringify(data)); */
        } catch (e) {
            console.log(e)

        }
    }

    setProduct(key: string, data: Product) {
        try {
            var datosLocal = localStorage.getItem(key)
            var objeto = datosLocal == null ? { datos: [] } : JSON.parse(datosLocal)
            objeto.datos.push(data)
            localStorage.setItem(key, JSON.stringify(objeto))


            /*  var a: Product[] = []
             if (localStorage.getItem('ProductoCarrito') == undefined) {
                 a.push(data);
                 localStorage.setItem(key, JSON.stringify(a));
             } else {
                 a.push(data);
                 a.push(JSON.parse(localStorage.getItem('ProductoCarrito')));
                 localStorage.setItem(key, JSON.stringify(a));
             }
  */
            /* localStorage.setItem(key, JSON.stringify(data)); */
        } catch (e) {
            console.log(e)

        }
    }

    setCartAfterDelete(key: string, data: any) {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.log(e)

        }
    }

    getInicio(key: string) {
        try {
            return JSON.parse(localStorage.getItem(key))
        } catch (e) {
            console.log(e);
        }
    }

    setShopping(key: string, data: ProductsId) {
        try {
            var datosLocal = localStorage.getItem(key)
            var objeto = datosLocal == null ? { datos: [] } : JSON.parse(datosLocal)
            objeto.datos.push(data)
            localStorage.setItem(key, JSON.stringify(objeto))
        } catch (e) {
            console.log(e)

        }
    }

    setCustomShop(key: string, data: Custom) {
        try {
            var datosLocal = localStorage.getItem(key)
            var objeto = datosLocal == null ? { datos: [] } : JSON.parse(datosLocal)
            objeto.datos.push(data)
            localStorage.setItem(key, JSON.stringify(objeto))
        } catch (e) {
            console.log(e)

        }
    }

    removeVar(key: string) {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.log(e)
        }
    }

}
