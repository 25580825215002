export enum LABELS {
  IN_STOCK   = 'EN STOCK',
  NEW   = 'NUEVO INGRESO',
  SOLD   = 'AGOTADO',
  SOON   = 'PROXIMAMENTE',
  BEST_SELLER   = 'MAS VENDIDO',
}
export enum TYPES {
  STAMP   = 'SELLO',
  LITHOGRAPHY   = 'LITOGRAFIA',
}
