<div class="ancla">
    <hr>
    <div class="texto">
        <h2>¿Te gusta lo que ves?</h2>
        <p>Solicita una cotización hoy mismo</p>
    </div>
    <div class="boton">
        <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB" target="_blank">Cotizar</a></button>
    </div>
</div>
