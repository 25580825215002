<app-menu></app-menu>

<div class="contac">
  <div class="banner">
    <div class="texto">
      <h1>Contáctanos</h1>

    </div>
  </div>
  <div class="contactanos">
    <div class="info">
      <div class="titulo">
        <h2>Contáctanos, será un gusto trabajar contigo</h2>
      </div>
      <div class="texto">
        <div class="fila">
          <img src="../../../assets/svg/schedule_blue_24dp.svg" alt="">
          <h3>Horarios de atención:</h3>
          <div class="jut">
            <p>Lunes - Jueves<br> 7:00 a 16:30hrs</p>
            <p class="esp">Viernes<br> 7:00 a 15:30hrs</p>
          </div>
        </div>
        <div class="fila">
          <img src="../../../assets/svg/support_agent_blue_24dp.svg" alt="...">
          <h3>Teléfono</h3>
          <p>PBX: 2314-2222 <br> Whatsapp: 5755-6140</p>
        </div>
        <div class="fila">
          <img src="../../../assets/svg/home_blue_24dp.svg" alt="">
          <h3>Dirección</h3>
          <p>6a. ave. 5-31 zona 2,<br> Guatemala, CA</p>
        </div>
      </div>
    </div>
    <div class="mapa">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30881.804282673496!2d-90.53126475938174!3d14.643135027421888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a39ab5a06927%3A0x8795b34540cbe838!2sMultisellos%20Vile!5e0!3m2!1ses-419!2sgt!4v1631207711835!5m2!1ses-419!2sgt"
        width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
  <div class="formulario">
    <h2>Contáctanos, será un gusto trabajar contigo</h2>
    <form #contactForm="ngForm" (ngSubmit)="sendContact()">
      <div class="form-floating">
        <input type="text" class="form-control" name="name" id="Name" [(ngModel)]="contact.name" #name="ngModel"
          placeholder="Nombre">
        <label for="floatingInput">Nombre</label>
      </div>
      <div class="form-floating">
        <input type="text" class="form-control" name="phone" id="phone" [(ngModel)]="contact.phone" #phone="ngModel"
          placeholder="Teléfono">
        <label for="floatingInput">Teléfono</label>
      </div>
      <div class="form-floating">
        <input type="text" class="form-control" name="address" id="address" [(ngModel)]="contact.address"
          #address="ngModel" placeholder="Ciudad/País">
        <label for="floatingInput">Ciudad/País</label>
      </div>
      <div class="form-floating">
        <textarea class="form-control" placeholder="Mensaje" name="message" id="Message" [(ngModel)]="contact.message"
          #message="ngModel"></textarea>
        <label for="floatingTextarea">Mensaje</label>
      </div>

      <div class="cap">
        <ng-hcaptcha (verify)="onVerify($event)" (expired)="onExpired($event)" (error)="onError1($event)">
        </ng-hcaptcha>
      </div>

      <div class="boton">
        <button type="submit" [ngClass]="{lessTone:!validated}" [disabled]="isSending || !validated" class="btn-orange btnsend">Enviar</button>
      </div>
    </form>
  </div>
<!--  <div class="noticias">-->
<!--    <div class="titulo">-->
<!--      <h2>¿Por qué comprar nuestros productos?</h2>-->
<!--      <p>Conoce el plus nuestros materiales</p>-->
<!--    </div>-->
<!--    <div class="boton">-->
<!--      <button>Descargar PDF</button>-->
<!--    </div>-->
<!--  </div>-->
</div>
<app-footer></app-footer>
