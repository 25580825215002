import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global.service';
import { Observable } from 'rxjs';
import { Url } from '../models/url.model';
import { UrlId } from '../models/urlId.model';
import { Custom } from '../models/custom.mode';
import { Ticket } from '../models/ticket.model';
import { Product } from '../models/product.model';
import { Filter } from '../models/filter.model';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private url: string;
  private contType = {
    h: 'Content-Type',
    b: 'application/json',
  }
  private auth = {
    h: 'Authorization',
    b: '',
  }

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.API.url;
  }

  listProducts: () => Observable<any> = () => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.get(`${this.url}products/get_all`, { headers: headers });
  }

  getProduct: (name: Url) => Observable<any> = (name) => {
    let params = name;
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}products/filter`, params, { headers: headers });
  }

  getProductId: (name: UrlId) => Observable<any> = (name) => {
    let params = name;
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}products/filter`, params, { headers: headers });
  }

  getById: (name: UrlId) => Observable<any> = (name) => {
    let params = name;
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}products/filter`, params, { headers: headers });
  }

  filter: (product: Filter) => Observable<any> = (product) => {
    let params = product;
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}products/filter`, params, { headers: headers });
  }

  getProductById: (productId: string) => Observable<any> = productId => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.get(`${this.url}products/get/${productId}`, { headers: headers });
  }

  addProduct: (product: Custom) => Observable<any> = product => {
    let params = JSON.stringify(product);
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}admin/custom/add`, params, { headers: headers });
  }

  addTicket: (product: Ticket) => Observable<any> = product => {
    let params = JSON.stringify(product);
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}admin/ticket/add`, params, { headers: headers });
  }


  async uploadFilesRecursively(file: Custom[], filesSaved: any[] = [], idx: number = 0) {
    if (file[idx]) {
      let savedFile = await this.addProduct(file[idx]);
      filesSaved.push(savedFile);
      if (idx < file.length - 1) {
        await this.uploadFilesRecursively(file, filesSaved, idx + 1);
      }
      return filesSaved;
    }
    return filesSaved;
  }

  addProductCustom: (product: Custom[]) => Observable<any> = product => {
    let params = JSON.stringify(product);
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}admin/custom/multi_add`, params, { headers: headers });
  }



}
