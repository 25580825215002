<app-menu></app-menu>
<div class="producto">
  <br>
  <a routerLink="/productos">
    < Regresar a la tienda </a>
      <br>
      <div class="producto-info">
        <div class="row cambiar" style="align-items: center;">
          <div class="col-6 bajar">
            <div id="slider" class="carousel slide carousel-dark" data-bs-ride="carousel">
              <ol class="carousel-indicators">
                <div *ngFor="let image of fotos; index as i; first as isFirst">
                  <li data-bs-target="#slider" [attr.data-bs-slide-to]="i" [class.active]="isFirst">
                    <img src="{{ image }}" />
                  </li>
                </div>
              </ol>

              <div class="carousel-inner">
                <div *ngFor="let image of fotos; first as isFirst" class="carousel-item" [class.active]="isFirst">
                  <img class="d-block w-100" src="{{ image }}" />
                </div>
              </div>

              <button class="carousel-control-prev" type="button" data-bs-target="#slider" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#slider" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>

            <div id="carouselExampleControls" class="carousel carousel-dark  slide" data-bs-ride="carousel"
              *ngIf="false">
              <div class="carousel-inner">
                <div class="carousel-item" *ngFor="let item of fotos; let a = index;" [ngClass]="{'active': a < 1}">
                  <img width="400px" [src]="item" alt="...">
                </div>

              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>


            <!--   <div class="w-75 m-auto">
              <div class="keen-slider" #sliderRef>
                <div *ngFor="let item of fotos;" class="keen-slider__slide number-slide1">
                  <img width="100%" [src]="item">
                </div>
              </div>
              <div class="keen-slider thumbnail" #thumbnailRef>
                <div *ngFor="let item of fotos;" class="keen-slider__slide number-slide1">
                  <img width="100%" [src]="item">
                </div>
              </div>
            </div> -->
          </div>
          <div class="col-6">
            <h2>{{product.nombre}}</h2>
            <h3>Codigo: {{ product.codigo }}</h3>
            <h4>{{ currency(product.precioU) }}</h4>
            <!-- <span>Cantidad</span> -->

            <div class="cantidad-info">
              <label for="view-title" class="group-title pb-1">
                Cantidad
              </label>
              <input type="number" min="0" [(ngModel)]="cantidadProducto" class="form-control" id="view-title"
                name="cantidadPro">
            </div>
            <hr>
            <div class="colores-info">
              <div class="txt-col" *ngIf="validatedCategorie()">
                <span>Color de case</span>
              </div>
              <div class="balls" *ngIf="validatedCategorie()">
                <div class="mx-2 my-2 d-flex align-items-center justify-content-center"
                  *ngFor="let item of colors; let i = index;" (click)="elegirColor(item)">
                  <div
                    [ngClass]="{'gris': item == 'Gris','rojo': item == 'Rojo','negro': item == 'Negro','verde': item == 'Verde',
                  'rosado-neon': item == 'Rosado/Neon','naranja-neon': item == 'Naranja/Neon','fucsia': item == 'Fucsia','azul': item == 'Azul','amarillo': item == 'Amarillo','lila': item == 'Lila',
                  'verde-celeste': item == 'Verde/Celeste','rosado': item == 'Rosado','rosado-turquesa': item == 'Rosado/Turquesa','blanco': item == 'Blanco','plateado': item == 'Plateado','azul-rojo': item == 'Azul/Rojo'}">
                  </div>
                </div>
              </div>
              <div class="balls" *ngIf="validatedCategorie1()">
                <div class="mx-2 my-2 d-flex align-items-center justify-content-center"
                  *ngFor="let item of colors; let i = index;" (click)="elegirColor(item)"
                  [ngClass]="{'bordeEleccion':llevaBorde(item)}">
                  <div
                    [ngClass]="{'gris': item == 'Gris','rojo': item == 'Rojo','negro': item == 'Negro','verde': item == 'Verde',
                  'rosado-neon': item == 'Rosado/Neon','naranja-neon': item == 'Naranja/Neon','fucsia': item == 'Fucsia','azul': item == 'Azul','amarillo': item == 'Amarillo','lila': item == 'Lila',
                  'verde-celeste': item == 'Verde/Celeste','rosado': item == 'Rosado','rosado-turquesa': item == 'Rosado/Turquesa','blanco': item == 'Blanco','plateado': item == 'Plateado','azul-rojo': item == 'Azul/Rojo'}">
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="d-flex justify-content-between text-start btn-acs">
              <!-- <a routerLink="/carrito"> -->
              <a *ngIf="!product.custom">
                <!-- <button data-bs-toggle="modal" data-bs-target="#exampleModal1"> -->
                <button [disabled]="isLoading" (click)="verificarCampos()">
                  Agregar a carrito
                </button>
              </a>
              <button data-bs-toggle="modal" class="d-none" data-bs-target="#exampleModal1">
                Q{{ product.precioU }} - Comprar
                <!-- Q{{ product.precioU }} - Agregar al carrito -->
              </button>
              <a *ngIf="product.custom" [routerLink]="url(product.id)">
                <button>
                  Personalizar
                </button>
              </a>
            </div>
            <div>
              <div class="row w-75 mt-3">
                <div class="col-4">
                  <span>
                    Usos
                  </span>
                </div>
                <div class="col-8">
                  <p>
                    {{product.usos}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont-info d-none">

          <div class="image-product">
            <div class="w-50 m-auto">
              <div class="keen-slider" #sliderRef>
                <div class="keen-slider__slide number-slide1"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide2"><img
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide3"><img
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide4"><img
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide5"><img
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide6"><img
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
              </div>
              <div class="keen-slider thumbnail" #thumbnailRef>
                <div class="keen-slider__slide number-slide1"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide2"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide3"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide4"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide5"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
                <div class="keen-slider__slide number-slide6"><img width="100%"
                    src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
                </div>
              </div>
            </div>
          </div>
          <div class="info-product">
            <h2>
              Sello de bolsillo Trodat
            </h2>
          </div>
        </div>

        <div class="detalles">
          <h2>
            Información del producto
          </h2>
          <h3>
            Especificaciones técnicas
          </h3>
          <hr>
          <table class="container">
            <!--  <thead>
              <tr>
                <th class="rojo">
                  <h1>Nombre</h1>
                </th>
                <th>
                  <h1>Precio</h1>
                </th>
              </tr>
            </thead> -->
            <tbody>
              <tr>
                <td class="rojo">
                  <h5>
                    Marca
                  </h5>
                </td>
                <td>
                  <h6>
                    {{product.marca}}
                  </h6>
                </td>
              </tr>
              <tr>
                <td class="rojo">
                  <h5>
                    Categoría
                  </h5>
                </td>
                <td>
                  <h6>
                    {{product.categoria}}
                  </h6>
                </td>
              </tr>
              <tr>
                <td class="rojo">
                  <h5>
                    Medidas
                  </h5>
                </td>
                <td>
                  <h6>
                    {{product.medidas}}
                  </h6>
                </td>
              </tr>
              <tr class="">
                <td class="rojo">
                  <h5>
                    Líneas de texto máximo a colocar
                  </h5>
                </td>
                <td>
                  <h6>
                    {{product.lineasMaxTexto}}
                  </h6>
                </td>
              </tr>
              <tr>
                <td class="rojo">
                  <h5>
                    Material
                  </h5>
                </td>
                <td>
                  <h6>
                    {{product.material}}
                  </h6>
                </td>
              </tr>
              <tr>
                <td class="rojo">
                  <h5>
                    Información adicional
                  </h5>
                </td>
                <td>
                  <h6>
                    {{product.infoAdicional}}
                  </h6>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <!--  <div class="video">
        <iframe src="https://www.youtube.com/embed/ElRTWqPZ9es" width="50%" height="500px" frameborder="0"></iframe>
      </div> -->


      <div class="video">

        <iframe [src]="cleanURLResource('https://www.youtube.com/embed/' + product.video)" width="40%" height="500px"
          frameborder="0" *ngIf="product.video"></iframe>
      </div>

      <div class="wrapper d-none">
        <div class="one">
          <div class="w-50 m-auto">
            <div class="keen-slider" #sliderRef>
              <div class="keen-slider__slide number-slide1"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide2"><img
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide3"><img
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide4"><img
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide5"><img
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide6"><img
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
            </div>
            <div class="keen-slider thumbnail" #thumbnailRef>
              <div class="keen-slider__slide number-slide1"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide2"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide3"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide4"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide5"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
              <div class="keen-slider__slide number-slide6"><img width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/proyectos-fha-be.appspot.com/o/Lewa%2F1643298770429_house_drawing_tutorial.png?alt=media&token=b402bd1f-43dd-4d90-a273-635c42242e77">
              </div>
            </div>
          </div>

        </div>
        <div class="two">Two</div>

      </div>

      <div class="interes">
        <br>
        <h2>
          Te puede interesar
        </h2>
        <div class="cont-interes res-int">
          <div class="item d-none">
            <div class="image">
              <img src="assets/img/prueba/nfl.jpg" alt="">
            </div>
            <div class="txt">
              <h3>
                Sello Trodat
              </h3>
              <p>
                SELLO
                <br>
                $40.00
              </p>
            </div>
          </div>
          <div class="item" *ngFor="let produ of products">
            <div class="image">
              <img [src]="cleanURLResource(produ.imgP)">
            </div>
            <div class="txt">
              <h3>
                {{produ.nombre}}
              </h3>
              <p>
                Q. {{produ.precioU}}
              </p>
            </div>
          </div>
        </div>

        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let produ of products, first as isFirst" [class.active]="isFirst">
              <div class="cont-interes">
                <div class="item">
                  <div class="image">
                    <img [src]="cleanURLResource(produ.imgP)">
                  </div>
                  <div class="txt">
                    <h3>
                      {{produ.nombre}}
                    </h3>
                    <p>
                      Q. {{produ.precioU}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>


      </div>

      <br><br><br>



      <div id="my-carousel" class="thumbnail-carousel carousel slide" data-bs-ride="carousel" *ngIf="false">
        <!-- Indicators -->
        <!--thumbnails-->
        <ol class="carousel-indicators">
          <div *ngFor="let item of fotos; let i = index;">

            <li data-bs-target="#my-carousel" class="active" [data-bs-slide-to]="i" *ngIf=" i === 0  ">
              <img width="100%" [src]="item" alt="...">
            </li>
            <li data-bs-target="#my-carousel" [data-bs-slide-to]="i" *ngIf=" i != 0  ">
              <img width="100%" [src]="item" alt="...">
            </li>
          </div>
        </ol>

        <!--  <div class="carousel-indicators">

          <button aria-label="Slide 1" type="button" data-bs-target="#my-carousel"
            *ngFor="let item of fotos; let i = index;" [ngClass]="{'active': i < 1}" [data-bs-slide-to]="i">
            <img width="100%" class="img-fluid" [src]="item">
          </button>



        </div>
 -->
        <!-- Wrapper for slides -->
        <div class="carousel-inner">

          <div class="carousel-item" *ngFor="let item of fotos; let a = index;" [ngClass]="{'active': a < 1}">
            <img width="400px" [src]="item" alt="...">
          </div>

        </div>

        <!-- Controls -->
        <button class="carousel-control-prev" type="button" data-bs-target="#my-carousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#my-carousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>


      <div class="carousel slide" data-bs-ride="carousel" id="carouselExampleCaptions" *ngIf="false">
        <div class="carousel-inner">

          <div class="carousel-item" role="option" *ngFor="let item of fotos; let a = index;"
            [ngClass]="{'active': a < 1}">
            <img width="400px" [src]="item" alt="...">
          </div>

        </div>
        <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleCaptions"
          type="button">
          <span aria-hidden="true" class="carousel-control-prev-icon"></span> <span
            class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleCaptions"
          type="button"><span aria-hidden="true" class="carousel-control-next-icon"></span> <span
            class="visually-hidden">Next</span>
        </button>


        <!--thumbnails-->
        <div class="carousel-indicators">


          <button aria-label="Slide 1" *ngFor="let item of fotos; let i = index;" [data-bs-slide-to]="index"
            data-bs-target="#carouselExampleCaptions" type="button" [ngClass]="{'active': i < 1}">
            <img class="img-fluid" src="assets/img/prueba/nfl.jpg">
          </button>

          <!--

          <button aria-label="Slide 1" class="active" data-bs-slide-to="0" data-bs-target="#carouselExampleCaptions"
            type="button">
            <img class="img-fluid" src="assets/img/prueba/nfl.jpg">
          </button>
          <button aria-label="Slide 2" data-bs-slide-to="1" data-bs-target="#carouselExampleCaptions" type="button">
            <img class="img-fluid" src="assets/img/prueba/nfl.jpg">
          </button>
          <button aria-label="Slide 3" data-bs-slide-to="2" data-bs-target="#carouselExampleCaptions" type="button"><img
              class="img-fluid" src="assets/img/prueba/nfl.jpg"></button>
          <button aria-label="Slide 4" data-bs-slide-to="3" data-bs-target="#carouselExampleCaptions" type="button"><img
              class="img-fluid" src="assets/img/prueba/nfl.jpg"></button> -->
        </div>
      </div>


      <div id="slider" class="carousel slide" data-bs-ride="carousel" *ngIf="false">
        <ol class="carousel-indicators">
          <div *ngFor="let image of fotos; index as i; first as isFirst">
            <li data-bs-target="#slider" [attr.data-bs-slide-to]="i" [class.active]="isFirst">
              <img src="{{ image }}" />
            </li>
          </div>
        </ol>

        <div class="carousel-inner">
          <div *ngFor="let image of fotos; first as isFirst" class="carousel-item" [class.active]="isFirst">
            <img class="d-block w-100" src="{{ image }}" />
          </div>
        </div>

        <button class="carousel-control-prev" type="button" data-bs-target="#slider" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#slider" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header text-center border-0">
        <h5 class="modal-title" id="exampleModalLabel">{{product.nombre}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-none">
        ...
      </div>
      <div class="modal-footer border-top-0 text-center">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Agregar al carrito</button>
        <a [routerLink]="url(product.id)">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Personalizar</button>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Color de tinta</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 d-none">
          <label for="selectAccionProducto" class="form-label">Seleccione color de Sello</label>
          <select class="form-select" aria-label="Default select example" id="selectAccionProducto">
            <option *ngFor="let item of colors" [value]="item">
              {{ item|titlecase }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="selectAccionProducto" class="form-label">Seleccione color de Tinta</label>
          <select class="form-select" [ngModel]="eleccionCase" (ngModelChange)="functionCase($event)"
            aria-label="Default select example" id="selectAccionProducto">
            <option selected>Seleccione</option>
            <option value="black">Negro</option>
            <option value="green">Verde</option>
            <option value="purple">Morado</option>
            <option value="orange">Naranja</option>
            <option value="red">Rojo</option>
            <option value="blue">Azul</option>
            <option value="chocolate">Cafe</option>
            <option value="turquoise">Turquesa</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <a data-bs-dismiss="modal" (click)="agregarCart()">
          <button type="button" data-bs-dismiss="modal" class="btn btn-primary">Agregar</button>
        </a>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Color de tinta</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="selectAccionProducto" class="form-label">Seleccione color de Sello</label>
          <select class="form-select" aria-label="Default select example" id="selectAccionProducto">
            <option *ngFor="let item of colors" [value]="item">
              {{ item|titlecase }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="selectAccionProducto" class="form-label">Seleccione color de Tinta</label>
          <select [ngModel]="colorBorder" (ngModelChange)="slobo($event)" class="form-select"
            aria-label="Default select example">
            <option value="black">Negro</option>
            <option value="green">Verde</option>
            <option value="purple">Morado</option>
            <option value="orange">Naranja</option>
            <option value="red">Rojo</option>
            <option value="blue">Azul</option>
            <option value="chocolate">Cafe</option>
            <option value="turquoise">Turquesa</option>
          </select>
          <span class="text-primary" *ngIf="cobrar">
            +(Q.5.00 por cambio de tinta)
          </span>




        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <a [routerLink]="url(product.id)">
          <button type="button" data-bs-dismiss="modal" class="btn btn-primary">Agregar</button>
        </a>
      </div>
    </div>
  </div>
</div>

<app-spinner [loading]="isLoading"></app-spinner>


<app-footer></app-footer>