import { IStock } from "../interfaces/IStock";

export class Product {
  constructor(
    public id:                            string,
    public nombre:                        string,
    public codigo:                        string,
    public tipo:                          string,
    public stock:                         IStock[],
    public marca:                         string,
    public categoria:                     string,
    public precioU:                       number,
    public colores:                       string[],
    public medidas:                       string,
    public material:                      string,
    public usos:                          string,
    public img :                          string[],
    public imgP :                         string,
    public video :                        string,
    public extras :                       string,
    public lineasMaxTexto :               string,
    public presentacion:                  string,
    public infoAdicional:                 string,
    public etiqueta:                      string,
    public custom:                      boolean,
    public isActive:                      boolean,
  ) { }
}
