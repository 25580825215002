<app-menu></app-menu>

<br><br><br><br>
<br><br>

<!-- 
<button class="btn btn-primary" (click)="holabb()">
	HOLA
</button> -->
<!-- <div class="comprobacion">
	<div class='container'>
		<h2>Card Number Validator</h2>
		<form [formGroup]="cardNumberGroup">
			<input [formControl]="getCardNumberControl()" placeholder="Card Number">

			<div *ngIf="getCardNumberControl().dirty">
				<p [class.invalid]="getCardNumberControl().invalid"></p>
				<p [class.valid]="getCardNumberControl().valid"></p>
			</div>
		</form>
	</div>
</div> -->

<div class="contentCheckout mt-5">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-6">
				<div class="checkout-items">
					<div class="list-checkout">
						<ul class="steps-checkout">
							<li class="steps"><a [ngClass]="{'active-check' : contSend}"> <span
										class="fa fa-truck"></span>Envio</a></li>
							<li class="steps"><a [ngClass]="{'active-check' : contPago}"> <span
										class="fa fa-credit-card"></span>Pago</a></li>
							<!-- <li class="steps"><a [ngClass]="{'active-check' : contConfir}"> <span
										class="fa fa-check"></span>Envio</a></li> -->
						</ul>
					</div>

					<div class="contentShipping" *ngIf="contSend">
						<form [formGroup]="productForm">
							<fieldset>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="nome">Nombre</label>
											<input name="nombre" class="form-control chkndo-input" type="text"
												formControlName="nombre" id="nombre">
										</div>
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="surname">Apellidos</label>
											<input name="apellido" class="form-control chkndo-input"
												formControlName="apellido" type="text" id="apellido">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-sd-12 col-md-6">
											<label class="control-label" for="surname">Numero de Celular</label>
											<input name="celular" class="form-control chkndo-input" type="text"
												formControlName="celular" id="celular">
										</div>
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="inp-nit">Nit</label>
											<input name="inp-nit" class="form-control chkndo-input"
												   maxlength="13"
												formControlName="nit" type="text" id="in-nit">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="email">Email</label>
											<input name="email" class="form-control chkndo-input"
												formControlName="email" id="email" type="text">
										</div>
									</div>
								</div>
								<div class="form-group my-4 d-none">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="email">Email</label>
											<input name="email" class="form-control chkndo-input"
												formControlName="email" id="email" type="text">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="departamento">Departamento</label>
											<select class="depart-selec" formControlName="departamento" name="hola"
												id="hola" (ngModelChange)="onChangeState($event)">
												<option value="Guatemala">Guatemala</option>
												<option value="Alta Verapaz">Alta Verapaz</option>
												<option value="Baja Verapaz">Baja Verapaz</option>
												<option value="Chimaltenango">Chimaltenango</option>
												<option value="Chiquimula">Chiquimula</option>
												<option value="El Progreso">El Progreso</option>
												<option value="Escuintla">Escuintla</option>
												<option value="Huehuetenango">Huehuetenango</option>
												<option value="Izabal">Izabal</option>
												<option value="Jalapa">Jalapa</option>
												<option value="Jutiapa">Jutiapa</option>
												<option value="Petén">Peten</option>
												<option value="Quetzaltenango">Quetzaltenango</option>
												<option value="Quiché">Quiche</option>
												<option value="Retalhuleu">Retalhuleu</option>
												<option value="Sacatepéquez">Sacatepéquez</option>
												<option value="San Marcos">San Marcos</option>
												<option value="Santa Rosa">Santa Rosa</option>
												<option value="Sololá">Sololá</option>
												<option value="Suchitepéquez">Suchitepéquez</option>
												<option value="Totonicapán">Totonicapan</option>
												<option value="Zacapa">Zacapa</option>
											</select>
											<!-- <input name="departamento" class="form-control chkndo-input"
												formControlName="departamento" type="text" id="departamento"> -->
										</div>
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="surname">Municipio</label>
											<select class="depart-selec" formControlName="municipio" name="hola"
												id="hola" (ngModelChange)="onChangeMuni($event)">
												<option *ngFor="let item of listaMunicipio" [value]="item">
													{{item}}</option>
											</select>
											<!-- <input name="surname" class="form-control chkndo-input" placeholder=""
												type="text"> -->
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<label class="control-label" for="send">Tipo de Envio</label>
									<div class="row">
<!--										<div class="col-6">-->
<!--											<div class="form-check">-->
<!--												<input class="form-check-input" type="radio" name="activoFijo"-->
<!--													   (click)="updateTipoEnvio('Envío a domicilio')" id="flexRadioDefault1">-->
<!--												<label class="form-check-label" for="flexRadioDefault1">-->
<!--													Envío a domicilio-->
<!--												</label>-->
<!--											</div>-->
<!--										</div>-->
										<div class="col-6">
											<div class="form-check">
												<input class="form-check-input" type="radio" name="activoFijo"
													   (click)="envio('si')" id="flexRadioDefault1">
												<label class="form-check-label" for="flexRadioDefault1">
													Envío a domicilio
												</label>
											</div>
										</div>
										<div class="col-6">
											<div class="form-check">
												<input class="form-check-input" type="radio" name="activoFijo"
													   (click)="envio('no')"  id="flexRadioDefault2" checked>
												<label class="form-check-label" for="flexRadioDefault2">
													Pickup (Recoge en tienda)
												</label>
											</div>
										</div>
									</div>
								</div>
								<section>
									<div class="form-group my-4" *ngIf="mostrarDireccion">
										<div class="row">
											<div class="col-md-12">
												<label class="control-label" for="district">Dirección exacta</label>
												<input name="direccion" class="form-control chkndo-input"
													formControlName="direccion" id="direccion" type="text">
											</div>
										</div>
									</div>
									<div *ngIf="mostrarEnvio">
										<div class="form-group my-4" *ngIf="mostrarProveedor()">
											<div class="row">
												<div class="col-sm-12 col-md-6">
													<label class="control-label" for="departamento">Seleccione un proveedor
														de envio</label>
													<select name="hola" id="hola" (ngModelChange)="onChangeState($event)">
														<option value="Guatex">Guatex</option>
														<option value="Forza">Forza</option>
														<option value="Cargo">Cargo Express</option>
													</select>
												</div>
											</div>
											<p class="w-50">El pago del envio se cancela con la empresa de transporte al
												momento de recibir el producto</p>
										</div>
									</div>
								</section>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12">
											<button type="button" [disabled]="productForm.invalid" (click)="step2()"
												class="btn btn-primary btn-lg btn-block next-chk">Siguiente</button>
										</div>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
					<div class="contentShipping" *ngIf="contPago">
						<div class="card-wrap">
							<div class="card card-front animate">
								<div class="number">
									<div class="label">número de tarjeta</div>
									<span>{{mostrarNumero}}</span>
								</div>
								<div class="owner-data">
									<div class="name">
										<div class="label">Nombre del titular de la tarjeta</div>
										<div class="value">{{nombreTarjeta}}</div>
									</div>
									<div class="validate">
										<div class="label">Valid THU</div>
										<div class="value">{{monthExpires}}/{{yearExpires}}</div>
									</div>
								</div>
								<div class="flag">
									<img class=" mt-2 w-75" src="assets/Visa_Logo.png" *ngIf="fotoVisa"
										alt="mastercard">
									<img class="w-75" src="assets/logo-Mastercard.png" *ngIf="fotoMaster"
										alt="mastercard">
								</div>
							</div>
							<div class="card card-back animate">
								<div class="bar"></div>
								<div class="secret-code">
									<div class="label">CVV</div>
									<div class="value">{{securityCode}}</div>
								</div>
							</div>
						</div>
						<form [formGroup]="cardNumberGroup">
							<fieldset>
								<!-- <form id="form-shipping" class="" name="form-shipping" action="#"> -->
								<!-- <input [formControl]="getCardNumberControl()" placeholder="Card Number"> -->
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="name">Nombre del titular</label>
											<input name="name" class="form-control chkndo-input" id="name"
												formControlName="nameNumber" type="text" (ngModelChange)="nombre()">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="number">Número de tarjeta</label>
											<input name="number" class="form-control chkndo-input"
												onKeyPress="if(this.value.length==19) return false;"
												[formControl]="getCardNumberControl()" (ngModelChange)="soloLlamo()">

											<div *ngIf="getCardNumberControl().dirty">
												<p [class.invalid]="getCardNumberControl().invalid"></p>
												<p [class.valid]="getCardNumberControl().valid"></p>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<label class="control-label" for="district">Fecha de caducidad</label>
										<div class="col-sm-12  col-md-6">
											<select formControlName="monthCard" (ngModelChange)="mesFunction($event)"
												name="mes">
												<option value="01">01</option>
												<option value="02">02</option>
												<option value="03">03</option>
												<option value="04">04</option>
												<option value="05">05</option>
												<option value="06">06</option>
												<option value="07">07</option>
												<option value="08">08</option>
												<option value="09">09</option>
												<option value="10">10</option>
												<option value="11">11</option>
												<option value="12">12</option>
											</select>
										</div>
										<div class="col-sm-12 col-md-6">
											<select formControlName="yearCard" (ngModelChange)="anioFunction($event)"
												name="anio">
												<option value="22">2022</option>
												<option value="23">2023</option>
												<option value="24">2024</option>
												<option value="25">2025</option>
												<option value="26">2026</option>
												<option value="27">2027</option>
												<option value="28">2028</option>
												<option value="29">2029</option>
												<option value="30">2030</option>
												<option value="31">2031</option>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-sm-12">
											<label class="control-label" for="district">Número de verificación de
												tarjeta</label>
											<input name="district" formControlName="codeVer"
												class="form-control chkndo-input" placeholder=""
												(ngModelChange)="nombre()" type="text">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="number">Dirección de facturacion</label>
											<input name="number" class="form-control chkndo-input"
												   formControlName="direccionFacturacion">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12">
											<button type="button" (click)="realizarPago()"
												class="btn btn-primary btn-lg btn-block next-chk">Pagar</button>
										</div>
									</div>
								</div>
								<!-- <div class="form-group my-4">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="nome">Nombre del titular</label>
											<input name="nome" class="form-control chkndo-input"
												[(ngModel)]="nombreTarjeta" placeholder="" type="text">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12">
											<label class="control-label" for="district">Número de tarjeta </label>
											<input name="district" class="form-control chkndo-input" placeholder=""
												onKeyPress="if(this.value.length==16) return false;"
												[(ngModel)]="numeroTarjetas" (ngModelChange)="soloLlamo()" type="number"
												[formControl]="getCardNumberControl()">

											<div *ngIf="getCardNumberControl().dirty">
												<p [class.invalid]="getCardNumberControl().invalid"></p>
												<p [class.valid]="getCardNumberControl().valid"></p>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<label class="control-label" for="district">Fecha de caducidad</label>
										<div class="col-sm-12  col-md-6">
											<select [ngModel]="monthExpires" (ngModelChange)="mesFunction($event)"
												name="mes">
												<option value="01">01</option>
												<option value="02">02</option>
												<option value="03">03</option>
												<option value="04">04</option>
												<option value="05">05</option>
												<option value="06">06</option>
												<option value="07">07</option>
												<option value="08">08</option>
												<option value="09">09</option>
												<option value="10">10</option>
												<option value="11">11</option>
												<option value="12">12</option>
											</select>
										</div>
										<div class="col-sm-12 col-md-6">
											<select [ngModel]="yearExpires" (ngModelChange)="anioFunction($event)"
												name="anio">
												<option value="22">2022</option>
												<option value="23">2023</option>
												<option value="24">2024</option>
												<option value="25">2025</option>
												<option value="26">2026</option>
												<option value="27">2027</option>
												<option value="28">2028</option>
												<option value="29">2029</option>
												<option value="30">2030</option>
												<option value="31">2031</option>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-sm-12">
											<label class="control-label" for="district">Número de verificación de
												tarjeta</label>
											<input name="district" [(ngModel)]="securityCode"
												class="form-control chkndo-input" placeholder="" type="text">
										</div>
									</div>
								</div>
								<div class="form-group my-4">
									<div class="row">
										<div class="col-md-12">
											<button type="button" (click)="procesoCompra()"
												class="btn btn-primary btn-lg btn-block next-chk">Pagar</button>
										</div>
									</div>
								</div> -->
							</fieldset>

							<div class="form-group my-4">
								<div class="row">
									<div class="col-md-12">
										<button type="button" [disabled]="cardNumberGroup.invalid"
											(click)="realfizarPago()"
											class="btn btn-primary btn-lg btn-block next-chk d-none">Siguiente</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="contentShipping" *ngIf="contConfir">
						<form id="form-shipping" class="" name="form-shipping" action="#">
							<fieldset>
								<div class="form-group">
									<div class="row">
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="nome">Nombre</label>
											<input name="nome" class="form-control chkndo-input" placeholder="Mario"
												type="text">
										</div>
										<div class="col-sm-12 col-md-6">
											<label class="control-label" for="surname">Apellidos</label>
											<input name="surname" class="form-control chkndo-input" placeholder="Díaz"
												type="text">
										</div>
									</div>
								</div>

								<div class="form-group">
									<div class="row">
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="surname">Numero de Celular</label>
											<input name="surname" class="form-control chkndo-input"
												placeholder="99 80 12 34" type="text">
										</div>
										<div class="col-md-12 col-md-6">
											<label class="control-label" for="email">Email</label>
											<input name="email" class="form-control chkndo-input"
												placeholder="mario@correo.com" type="text">
										</div>
									</div>
								</div>

								<div class="form-group">
									<div class="row">
										<div class="col-md-12">
											<label class="control-label" for="district">Codigo Postal</label>
											<input name="district" class="form-control chkndo-input"
												placeholder="100001" type="text">
										</div>
									</div>
								</div>

								<div class="form-group">
									<div class="row">
										<div class="col-md-12">
											<button type="button"
												class="btn btn-primary btn-lg btn-block next-chk">Next</button>
										</div>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6">
				<div class="cartForCheckout">
					<h2>Carrito de Compras</h2>
					<div class="contenCart">
						<div class="products-forCheckout">
							<ul class="ul-forCheckoutItems">
								<div class="divider"></div>
								<h4 *ngIf="bad" class="mt-4">Productos</h4>
								<li *ngIf="bad" class="countCheckout">
									<ul class="p-0 w-100">
										<li *ngFor="let item of bad.datos; let i = index;" class="w-100">
											<div class="d-flex justify-content-between w-100 align-items-center">
												<p class="objetc m-0">Sello: {{item.productId}} <span
														class="text-danger">({{item.quantity}})</span> </p>
												<div class="d-flex align-items-center">
													<p class="price me-3 mb-0">{{ currency( subTotalItem(item.precioU,
														item.quantity) ) }}</p>
													<span style="cursor: pointer;" (click)="eliminarProductoCarrito(i)"
														class="text-danger">x</span>
												</div>
											</div>
											<div *ngIf="item.colorTinta != 'black' && item.colorTinta != null"
												class="d-flex justify-content-between w-100 align-items-center text-primary">
												<p class="objetc m-0">&nbsp;&nbsp;Tinta:
													{{traducirColor(item.colorTinta)}} </p>
												<div class="d-flex align-items-center">
													<p class="price me-3 mb-0">{{ currency( 5 ) }}</p>
												</div>
											</div>
										</li>
									</ul>
								</li>
								<h4 *ngIf="custom" class="mt-4">Sellos Personalizados</h4>
								<li *ngIf="custom" class="countCheckout">
									<ul class="p-0 w-100">
										<li *ngFor="let item of custom.datos; let i = index;" class="w-100">
											<div class="d-flex justify-content-between align-items-center">
												<p class="objetc m-0">Sello: {{item.stamp_code}}</p>
												<div class="d-flex align-items-center">
													<p class="price me-3 mb-0">{{ currency( item.extra3 ) }}</p>
													<span style="cursor: pointer;" (click)="eliminarCustomCarrito(i)"
														class="text-danger">x</span>
												</div>
											</div>
											<div *ngIf="item.color != 'black'"
												class="d-flex justify-content-between w-100 align-items-center text-primary">
												<p class="objetc m-0">&nbsp;&nbsp;Tinta:
													{{traducirColor(item.color)}} </p>
												<div class="d-flex align-items-center">
													<p class="price me-3 mb-0">{{ currency( 5 ) }}</p>
												</div>
											</div>
										</li>
									</ul>
								</li>
								<!-- <div *ngIf="bad; else custom" class="divider my-3"></div> -->
								<li class="countCheckout">
									<h5 class="objetc">Sub Total</h5>
									<p class="price">{{currency(subTotal)}}</p>
								</li>
								<li class="countCheckout" *ngIf="mostrarEnvio">
									<h5 class="objetc">Envio</h5>
									<p class="price">{{calcularEnvio()}}</p>
								</li>
								<div class="divider"></div>
								<li class="countCheckout">
									<p class="objetc totalPrice">Total</p>
									<p class="price totalPrice">{{currency(total)}}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
