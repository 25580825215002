import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.scss'],
})
export class AcercaDeComponent implements OnInit {
  constructor(
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('  Acerca de | Vile ');
  }
}
