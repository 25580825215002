import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-litografia-e-imprenta',
  templateUrl: './litografia-e-imprenta.component.html',
  styleUrls: ['./litografia-e-imprenta.component.scss']
})
export class LitografiaEImprentaComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.title.setTitle('  Litografia | Vile ');
    this.meta.updateTag({ name: 'keywords', content: 'Impresión revistas, impresion trifoleares, impresión afiches,  papelería de oficina, hojas membretadas, folders, empaques, etiquetas, stickers, adhesivos., Volantes, Afiches, litografía' });
    this.meta.updateTag({ name: 'description', content: 'Vile te ofrece el servicio de litografía para impresión comercial, impresión para editoriales y empaques Full Color. Impresión digital, volantes, cajas, trifoliares, impresión full color, revistas, folletos' });
    this.meta.updateTag({ property: 'og:description', content: 'Vile te ofrece el servicio de litografía para impresión comercial, impresión para editoriales y empaques Full Color. Impresión digital, volantes, cajas, trifoliares, impresión full color, revistas, folletos' });
  }

}
