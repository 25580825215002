import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Contact } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/services/contact.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.scss']
})
export class ContactenosComponent implements OnInit {

  contact: Contact;
  status: string;
  isSending = false;
  validated = false;
  form!: FormGroup
  recaptcha: string;

  constructor(
    private formBuilder: FormBuilder,
    private title: Title,
    private contactService: ContactService
  ) {
    this.buildForm();
    this.contact = new Contact('', '', '', '','', 'Pagina Web: Contáctenos', ['asistente@vile.com.gt']);
    this.status = '';
  }

  ngOnInit(): void {
    this.title.setTitle('  Contáctenos | Vile ');
  }


  private buildForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],

    });

    /* this.form.valueChanges
       .subscribe(value => {
         console.log(value);
       });*/
  }

  save(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;
      console.log(value);
    }
  }


  resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    if (this.recaptcha) {
      this.validated = true;
    }
    console.log('Resolved captcha with response: ', this.recaptcha);
  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


  sendContact(): void {
    console.log(this.contact);
    if (this.validated) {
      this.isSending = true;
      this.contactService.sendEmail(this.contact).subscribe(
        res => {
          Swal.fire({
            title: 'Correo Enviado',
            html: 'Se ha enviado correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.isSending = false;
              this.cleanContac();
              window.location.reload();
            }
          })
        },
        err => {
          this.isSending = false;
          this.onError(err);
        },
      );
    }
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
      console.error(errorMessage);
    }
  }

  cleanContac() {
    this.contact = new Contact('', '', '', '', '', 'Pagina Web: Contacténos', ['asistente@vile.com.gt']);
    this.validated = true;
  }


  onVerify(token: string) {
    // The verification process was successful.
    // You can verify the token on your server now.
    this.validated = true;
  }

  onExpired(response: any) {
    // The verification expired.
  }

  onError1(error: any) {
    // An error occured during the verification process.
  }

}
