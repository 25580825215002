export const GLOBAL = {
  API: {
    url: 'https://us-central1-be-vile.cloudfunctions.net/Api/'
     //url: 'http://localhost:3000/'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBovOsBQV_sMjuQUa3CXIjSacv3X_3S7D4",
    authDomain: "be-vile.firebaseapp.com",
    projectId: "be-vile",
    storageBucket: "be-vile.appspot.com",
    messagingSenderId: "210968936684",
    appId: "1:210968936684:web:4911add600ba747ab8e5c9"
  },
};
