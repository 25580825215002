<app-menu></app-menu>
<div class="acerca">
    <div class="banner">
        <div class="texto">
            <h1>Acerca de Vile</h1>
            <p>Empresa con más de 90 años de trayectoria en el mercado nacional</p>
        </div>
    </div>
    <div class="pilares esco">
        <div id="carouselExampleIndicators3" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="tarjeta">
                        <div class="icono"><img src="../../../assets/svg/star_outline_black_24dp.svg" alt=""></div>
                        <div class="texto">
                            <p>Más de 90 años de experiencia en fabricación de sellos</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta">
                        <div class="icono"><img src="../../../assets/svg/thumb_up_black_24dp.svg" alt=""></div>
                        <div class="texto">
                            <p>Más de 40 años de experiencia en las artes gráficas</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta">
                        <div class="icono"><img src="../../../assets/svg/thumb_up_black_24dp.svg" alt=""></div>
                        <div class="texto">
                            <p>Calidad</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta">
                        <div class="icono"><img src="../../../assets/svg/trending_up_black_24dp.svg" alt=""></div>
                        <div class="texto">
                            <p>Capacidad de producción</p>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
        </div>
    </div>
    <div class="pilares vis">
        <div class="tarjeta">
            <div class="icono"><img src="../../../assets/svg/star_outline_black_24dp.svg" alt=""></div>
            <div class="texto">
                <p>Más de 90 años de experiencia en fabricación de sellos</p>
            </div>
        </div>
        <div class="tarjeta">
            <div class="icono"><img src="../../../assets/svg/star_outline_black_24dp.svg" alt=""></div>
            <div class="texto">
                <p>Más de 40 años de experiencia en las artes gráficas</p>
            </div>
        </div>
        <div class="tarjeta">
            <div class="icono"><img src="../../../assets/svg/thumb_up_black_24dp.svg" alt=""></div>
            <div class="texto">
                <p>Calidad</p>
            </div>
        </div>
        <div class="tarjeta">
            <div class="icono"><img src="../../../assets/svg/trending_up_black_24dp.svg" alt=""></div>
            <div class="texto">
                <p>Capacidad de producción</p>
            </div>
        </div>
    </div>
    <div class="tarjetas inv">
        <div class="tarjeta">
            <div class="texto">


                <p>Vile se caracteriza por tener un equipo comprometido con su trabajo, entregando calidad en cada material que elaboran. Estos 90 años de trayectoria nos han enseñado algo:</p>
                <p class="negro">"La calidad y el servicio al cliente es primero"</p>
                <button><a href="https://api.whatsapp.com/send?phone=50257556140&text=%F0%9F%87%AC%F0%9F%87%B9%20Hola%20estoy%20en%20tu%20web%20necesito%20informaci%C3%B3n%20%F0%9F%92%BB"target="_blank">Solicita una cotización</a></button>
            </div>
        </div>
        <div class="tarjeta img bk1">
            <div class="texto">
                <h3>Conoce a Vile</h3>

            </div>
        </div>
    </div>
    <div class="fabrica">
        <div class="titulo">
            <h2>En VILE pensamos en tu comodidad</h2>
        </div>
        <div class="tarjetas vis">
            <div class="tarjeta img1">
                <p class="bold">1</p>
                <p>Envía todas las especificaciones del producto que deseas para poder realizar tu cotización.</p>
            </div>
            <div class="tarjeta img2">
                <p class="bold">2</p>
                <p>Autoriza tu cotización.</p>
            </div>
            <div class="tarjeta img3">
                <p class="bold">3</p>
                <p>Revisa y autoriza tus artes finales.</p>
            </div>
            <div class="tarjeta img4">
                <p class="bold">4</p>
                <p>Recibe tu producto.</p>
            </div>
        </div>
        <div id="carouselExampleIndicators4" class="carousel slide carousel-fade esc" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="tarjeta img1">
                        <p class="bold">1</p>
                        <p>Envía todas las especificaciones del producto que deseas para poder realizar tu cotización.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img2">
                        <p class="bold">2</p>
                        <p>Autoriza tu cotización.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img3">
                        <p class="bold">3</p>
                        <p>Revisa y autoriza tus artes finales.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="tarjeta img4">
                        <p class="bold">4</p>
                        <p>Recibe tu producto.</p>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
        </div>
    </div>
    <div class="noticias">
        <div class="titulo">
            <h2>Noticias, lanzamiento de nuevos productos, plazas y más contenido que puede interesarte en nuestro blog</h2>
        </div>
        <div class="boton">
            <button>Noticias</button>
        </div>
    </div>
    <div class="tarjetas oc">
        <div class="tarjeta">
            <div class="texto">
                <h2>Sección destacada de la empresa</h2>
                <p class="naranja">Queremos compartirte esta información</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullam</p>

            </div>
        </div>
        <div class="tarjeta img bk2">

        </div>
    </div>
    <div class="noticias nt2">
        <div class="titulo">
            <h2>Nuestra historia</h2>
        </div>
        <div class="boton">
            <button>Leer historia</button>
        </div>
    </div>

</div>
<app-ancla></app-ancla>
<app-footer></app-footer>