import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    public task: AngularFireUploadTask;
    public percentage: Observable<number>;
    public snapshot: Observable<any>;
    public downloadUrl: string;
    public fileUrl: string;
    constructor(
        private _fireStorage: AngularFireStorage,
        private readonly storage: AngularFireStorage
    ) { }


    uploadFile: (folder: string, filename: string, file: any) => Promise<string> = (folder, filename, doc) => {
        return new Promise((resolve, reject) => {
            let path = `${folder}/${Date.now()}_${filename}`;
            let ref = this._fireStorage.ref(path);
            let file = doc;
            this.task = this._fireStorage.upload(path, file);
            this.percentage = this.task.percentageChanges();
            this.task.snapshotChanges().pipe(finalize(async () => {
                this.fileUrl = await ref.getDownloadURL().toPromise();
                resolve(this.fileUrl);
            })).subscribe(() => { }, err => reject(err));
        });
    }

    /*  async uploadFilesRecursively(path: string, file: File[], filesSaved: string[] = [], idx: number = 0) {
         if (file[idx]) {
             let savedFile = await this.uploadFile(path, `${Date.now()}_${file[idx].name}`, file[idx]);
             filesSaved.push(savedFile);
             if (idx < file.length - 1) {
                 await this.uploadFilesRecursively(path, file, filesSaved, idx + 1);
             }
             return filesSaved;
         }
     } */

    async uploadFilesRecursively(path: string, file: File[], filesSaved: string[] = [], idx: number = 0) {
        if (file[idx]) {
            let savedFile = await this.uploadFile(path, `${Date.now()}_${file[idx].name}`, file[idx]);
            filesSaved.push(savedFile);
            if (idx < file.length - 1) {
                await this.uploadFilesRecursively(path, file, filesSaved, idx + 1);
            }
            return filesSaved;
        }
        return filesSaved;
    }

}
