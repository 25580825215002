import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AppComponent } from 'src/app/app.component';
import { ProductsService } from 'src/app/services/products.service';
import { Ticket } from 'src/app/models/ticket.model';
import Swal from 'sweetalert2';
import { CustomId } from 'src/app/interfaces/CustomsId';
import { ProductsId } from 'src/app/interfaces/ProductsId';
import { Custom } from 'src/app/models/custom.mode';
import { ILine } from 'src/app/interfaces/ILine';
import { Title } from '@angular/platform-browser';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getValidationConfigFromCardNo } from 'src/app/helpers/card.helper';
import { luhnValidator } from 'src/app/validators/luhnValidator';
import { Transaccion } from '../../models/transaccion.model';
import { TransaccionService } from 'src/app/services/transaccion.service';
import {Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  public status: string;
  cardNumberGroup: FormGroup;
  productForm: FormGroup;
  contPago = false;
  contConfir = false;
  statusEditar: string;
  contSend = true;
  numeroTarjetas = 0;
  costoEnvio = 0;
  nombreTarjeta: string;
  securityCode: string;
  yearExpires: string;
  monthExpires: string;
  infoTicket: Ticket;
  mostrarNumero: any;
  hola: any[];
  kevinlw: any;
  isSubmitting: boolean = false;
  suma = 0;
  sumaCustom = 0;
  subTotal = 0;
  total = 0;
  fotoVisa = false;
  fotoMaster = false;
  mostrarEnvio = false;
  mostrarDireccion = false;
  infoCustom: Custom;
  arrayInfo: Custom[];
  acabadoAgregar: string[];
  informacionSubir: CustomId[] = [];
  informacionIndividual: CustomId;
  listaMunicipio: string[];
  muniElegido: string;
  hoy = new Date();
  bad = {
    "datos": []
  }
  custom = {
    "datos": []
  }


  deviceFingerPrintID = new Date().getTime();
  merchantId = "visanetgt_multisellosvile";
  orgID = "1snn5n9w";
  orgIDProd = "k8vif92e";
  sessionID = this.merchantId + this.deviceFingerPrintID;
  cyberScriptUrl: any;
  cyberScriptUrlObj: any;

  constructor(
    private title: Title,
    private localStorage: LocalStorageService,
    private comp: AppComponent,
    private _fb: FormBuilder,
    private productService: ProductsService,
    private _transaccionService: TransaccionService,
    private _router: Router
  ) {
    this.cyberScriptUrlObj = `https://h.online-metrix.net/fp/tags?org_id=${encodeURI(this.orgIDProd)}&session_id=${encodeURI(this.sessionID)}`;
    var noscript = document.createElement('noscript');
    var iframe = document.createElement('iframe');
    iframe.setAttribute("height", "100px");
    iframe.setAttribute("width", "100px");
    iframe.setAttribute("border", "0");
    iframe.setAttribute("position", "absolute");
    iframe.setAttribute("top", "-5000px");
    iframe.setAttribute("src", this.cyberScriptUrlObj);
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  }

  ngOnInit() {
    this.title.setTitle('  Carrito de compra | Vile ');
    if (this.localStorage.getInicio("ProductForCart") != null) {
      this.bad = this.localStorage.getInicio("ProductForCart");
    } else {
      this.bad.datos = [];
    }
    this.loadScripts();
    this.custom = this.localStorage.getInicio("Personalizado");
    this.infoTicket = new Ticket([this.clearICustom()], [this.clearIProduct()], 'Pagina Web', 0, '', 'asistente@vile.com.gt', this.hoy.toDateString(), '', '', '', '', '', '', 0, '');
    this.infoCustom = new Custom('', '', '', '', '', this.clearIGroup(), this.clearIGroup(), this.clearIGroup(), this.clearIGroup(), '', '', '', '', [''], '', '', '', '', '', '');
    this.informacionIndividual = this.clearICustom();
    this.realizarSuma();
    this.initFormBuilder();
    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(12),
        luhnValidator()
      ]),
      nameNumber: new FormControl('', [
        Validators.required
      ]),
      monthCard: new FormControl('', [
        Validators.required
      ]),
      yearCard: new FormControl('', [
        Validators.required
      ]),
      codeVer: new FormControl('', [
        Validators.required
      ]),
      direccionFacturacion: new FormControl(''),
    });
  }

  initFormBuilder(): void {
    this.productForm = this._fb.group({
      deviceFingerPrint: [''],
      numTarjeta: [''],
      expMes: [''],
      expAnno: [''],
      securityCode: [''],
      monto: [''],
      moneda: [''],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      direccion: [''],
      departamento: ['', Validators.required],
      codPostal: [''],
      pais: [''],
      email: ['', Validators.required],
      celular: ['', Validators.required],
      idEmpresa: [0],
      idCliente: [0],
      idTarjeta: [0],
      noAutorizado: [0],
      ipOrigen: [0],
      descripcion: [''],
      estadoTransaccion: [0],
      estadoRegistro: [0],
      tipoEnvio: '',
      nit: ['0', [Validators.required, Validators.maxLength(13)]],
      municipio: ['', Validators.required],
    }, { updateOn: 'change' });

    this.productForm.get('tipoEnvio').setValue('Recoger en tienda');
  }

  clearICustom(): CustomId {
    return {
      customId: '',
      quantity: 0,
      colorTinta: '',
      colorCase: ''
    }
  }

  clearIGroup(): ILine {
    return {
      texto: '',
      tipoFuente: '',
      tamanioFuente: '',
      alineacion: '',
      interlineado: '',
      bold: false,
      italic: false,
      underline: false
    }
  }

  updateTipoEnvio(tipoEnvio: string) {
    this.infoTicket.tipoEnvio = tipoEnvio;
  }

  clearIProduct(): ProductsId {
    return {
      productId: '',
      quantity: 0,
      colorTinta: '',
      colorCase: ''
    }
  }

  step2(): void {
    this.contPago = true;
    this.contSend = false;
  }

  agregarCaracter = (cadena, caracter, pasos) => {
    let cadenaConCaracteres = "";
    const longitudCadena = cadena.length;
    for (let i = 0; i < longitudCadena; i += pasos) {
      if (i + pasos < longitudCadena) {
        cadenaConCaracteres += cadena.substring(i, i + pasos) + caracter;
      } else {
        cadenaConCaracteres += cadena.substring(i, longitudCadena);
      }
    }
    this.mostrarNumero = cadenaConCaracteres;
  }

  soloLlamo(): void {
    let numerosCorrectos = this.cardNumberGroup.get('cardNumber').value;
    this.agregarCaracter(numerosCorrectos, " ", 4);

    var wisin = this.cardNumberGroup.get('cardNumber').value;
    if (wisin.match(/^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/)) {
      this.fotoVisa = true;
      this.fotoMaster = false;
    } else if (wisin.match(/^(5[1-5]\d{4}|677189)\d{10}$/)) {
      this.fotoVisa = false;
      this.fotoMaster = true;
    }
  }

  nombre(): void {
    this.nombreTarjeta = this.cardNumberGroup.get('nameNumber').value;
    this.securityCode = this.cardNumberGroup.get('codeVer').value;
  }

  mesFunction(value: any) {
    this.monthExpires = value;
  }

  anioFunction(value: any) {
    this.yearExpires = value;
  }

  getCardNumberControl(): AbstractControl | null {
    return this.cardNumberGroup && this.cardNumberGroup.get('cardNumber');
  }

  currency = function (string) {
    return new Intl.NumberFormat('en-ES', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(string);
  };

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  eliminarProductoCarrito(id: number): void {
    this.bad.datos.splice(id, 1)

    this.suma = 0;
    this.total = 0;
    this.sumaCustom = 0;

    this.realizarSuma();

    this.localStorage.setCartAfterDelete("ProductForCart", this.bad);
    this.comp.ngOnInit();

  }

  eliminarCustomCarrito(id: number): void {
    this.custom.datos.splice(id, 1)

    this.suma = 0;
    this.total = 0;
    this.sumaCustom = 0;

    this.realizarSuma();

    this.localStorage.setCartAfterDelete("Personalizado", this.custom);
    this.comp.ngOnInit();

  }

  realizarSuma(restaEnvio: boolean = true): void {
    if (this.bad && restaEnvio) {
      for (let clave in this.bad.datos) {
        if (this.bad.datos[clave].colorTinta != "black" && this.bad.datos[clave].colorTinta != "" && this.bad.datos[clave].colorTinta != undefined) {
          this.suma = this.suma + 5;
        }
        this.suma = (this.suma + (this.bad.datos[clave].precioU * this.bad.datos[clave].quantity));
      }
    }

    if (this.custom) {
      if(!restaEnvio){
        this.sumaCustom = 0;
        this.suma = 0;
      }
      for (let clave in this.custom.datos) {
        if (this.custom.datos[clave].color != "black") {
          this.suma = this.suma + 5;
        }
        // Refactorizacion de la suma cuando no es envio
        this.sumaCustom = (this.sumaCustom + (this.custom.datos[clave].extra3 * 1));
      }
    }
    this.subTotal = this.suma + this.sumaCustom;

    if (this.mostrarEnvio) {
      this.subTotal = this.total = this.suma + this.sumaCustom + this.costoEnvio;
      this.infoTicket.totalPrice = this.subTotal;
    } else {
      this.total = this.suma + this.sumaCustom;
      this.infoTicket.totalPrice = this.total;
    }


  }

  subTotalItem(valor1: number, valor2: number): number {
    let subItem = valor1 * valor2;
    return subItem;
  }

  addTicket(): void {
    if (!this.isSubmitting) {
      this.isSubmitting = true;
      if (this.infoTicket.productsId) {
        this.infoTicket.productsId = this.bad.datos;
      } else {

      }
      if (this.infoTicket.customsId) {
        this.infoTicket.customsId = this.informacionSubir;
      }

      this.infoTicket.nombre = this.aplicarTrim(this.productForm.get('nombre').value) + ' ' + this.aplicarTrim(this.productForm.get('apellido').value);
      this.infoTicket.direccion = this.aplicarTrim(this.productForm.get('direccion').value) + ' departamento ' + this.aplicarTrim(this.productForm.get('departamento').value) + ' municipio ' + this.aplicarTrim(this.productForm.get('municipio').value);
      this.infoTicket.email = this.aplicarTrim(this.productForm.get('email').value);
      this.infoTicket.telefono = this.aplicarTrim(this.productForm.get('celular').value);
      this.infoTicket.nit = this.aplicarTrim(this.productForm.get('nit').value);

      let addCatSubs = this.productService.addTicket(this.infoTicket).subscribe(
        res => {
          if (!res.message) {
            this.status = 'error';
            alert('No se pudo agregar el Ticket')
          } else {
            this.status = 'ok';
            Swal.fire({
              title: 'Acción completada',
              html: 'Se ha enviado un correo correctamente',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.localStorage.removeVar('Personalizado');
            this.localStorage.removeVar('ProductForCart');
            this.cerrar();
          }
          addCatSubs.unsubscribe();
          this.isSubmitting = false;
        },
        error => {
          this.onError(error);
          this.isSubmitting = false;
          addCatSubs.unsubscribe();
        }
      );
    }
  }

  onError(error: any): void {
    let errorMessage = error;
    alert("Error al ejecutar la acción");
    if (errorMessage != null) {
      this.status = 'error';
    }

  }

  addCustom() {
    this.arrayInfo = this.custom.datos;
    let addCatSubs = this.productService.addProductCustom(this.arrayInfo).subscribe(
      res => {
        if (!res.message) {
          this.status = 'error';
          alert('No se pudo agregar el producto')
        } else {
          this.status = 'ok';
          this.acabadoAgregar = res.ids;
          for (let clave in this.custom.datos) {
            this.informacionIndividual.colorCase = this.custom.datos[clave].carcasa;
            this.informacionIndividual.colorTinta = this.custom.datos[clave].color;
            this.informacionIndividual.quantity = 1;
            this.informacionIndividual.customId = this.acabadoAgregar[clave];
            this.informacionSubir.push(this.informacionIndividual)
            this.informacionIndividual = {};
          }
          this.addTicket();
        }
        addCatSubs.unsubscribe();
        this.isSubmitting = false;
      },
      error => {
        this.onError(error);
        this.isSubmitting = false;
        addCatSubs.unsubscribe();
      }
    );
  }

  procesoCompra() {
    this.addCustom();
  }

  realizarPago() {
    this.productForm.disable();
    const promisesToResolve = [];
    Promise.all(promisesToResolve).then(imgRes => {
      const product = new Transaccion(
        this.deviceFingerPrintID,
        this.cardNumberGroup.get('cardNumber').value,
        this.cardNumberGroup.get('direccionFacturacion').value,
        this.monthExpires,
        this.yearExpires,
        this.securityCode,
        this.total.toString(),
        "GTQ",
        this.productForm.get('nombre').value?.trim(),
        this.productForm.get('apellido').value?.trim(),
        this.productForm.get('direccion').value?.trim(),
        this.productForm.get('departamento').value?.trim(),
        "01010",
        "GT",
        this.productForm.get('email').value?.trim(),
        this.productForm.get('celular').value?.trim(),
        1,
        1,
        1,
        1,
        1,
        "Productos Vile",
        1,
        1,
        this.productForm.get('tipoEnvio').value?.trim(),
      );


      //tslint:disable-next-line: deprecation
      // const addProductSubs = this._transaccionService.sendPayment(product).subscribe(
      //   res => {
      //     if (!res.transaccion) {
      //       this.status = 'error';
      //       alert('No se pudo realizar la transacción.');
      //     } else {
      //       Swal.fire({
      //         title: 'Acción completada',
      //         html: 'Se ha realizado la transacción correctamente.',
      //         icon: 'success',
      //         confirmButtonText: 'Aceptar',
      //       });
      //       if (this.custom != null) {
      //         if (this.custom.datos.length > 0) {
      //           this.addCustom();
      //         } else {
      //           this.addTicket();
      //         }
      //       } else {
      //         this.addTicket();
      //       }
      //       this._router.navigate([`inicio`]).then();
      //       localStorage.clear();
      //     }
      //     this.isSubmitting = false;
      //     this.productForm.enable();
      //     addProductSubs.unsubscribe();
      //     this.status = 'ok';
      //   },
      //   error => this.onError(error),
      // );

      // REFACTOR DEL ENVIO DE LOS DATOS PARA EL PAGO
      Swal.fire({
        title: 'Realizando transaccion ...',
        didOpen: () => {
          Swal.showLoading();
          this._transaccionService.sendPayment(product).subscribe({
            next: (request: any) => {
              Swal.close();
              if(request != null) {
                if (request.hasOwnProperty('statusCode')) {
                  if(request.statusCode === 200){
                    Swal.fire({
                      title: 'Acción completada',
                      html: 'Se ha realizado la transacción correctamente.',
                      icon: 'success',
                      confirmButtonText: 'Aceptar',
                    }).then();
                    if (this.custom != null) {
                      if (this.custom.datos.length > 0) {
                        this.addCustom();
                      } else {
                        this.addTicket();
                      }
                    } else {
                      this.addTicket();
                    }
                    localStorage.clear();
                    this._router.navigate([`inicio`]).then();
                  } else {
                    this.status = 'error';
                    // alert('No se pudo realizar la transacción.');
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: 'Error en el metodo del pago.'
                    }).then();
                  }
                } else {
                  this.status = 'error';
                  // alert('No se pudo realizar la transacción.');
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo realizar la transacción.'
                  }).then();
                }
              } else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Error en la transaccion'
                }).then();
              }
              this.isSubmitting = false;
              this.productForm.enable();
              this.status = 'ok';
            }, error: (err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Occurrio un error en la transacción.'
              }).then();
            }
          });
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then();



    }).catch((err) => {
      this.onError(err);
    });
  }

  cerrar(): void {
    $(document).ready(function () {
      // show the alert
      setTimeout(function () {
        /* location.reload(); */
      }, 1000);
    });
  }

  loadScripts() {
    let node = document.createElement('script');
    node.src = `https://h.online-metrix.net/fp/tags.js?org_id=${encodeURI(this.orgIDProd)}&session_id=${encodeURI(this.sessionID)}`;
    node.type = 'text/javascript';
    node.async = true;
    let laod = document.getElementsByTagName('head')[0]
    laod.appendChild(node);
  }

  envio(decision: string) {
    if (decision === 'si') {
      this.mostrarDireccion = true;
      this.calcularEnvioDepa();
      this.productForm.get('tipoEnvio').setValue('Envío a domicilio');
      this.infoTicket.tipoEnvio = 'Envío a domicilio';
      this.mostrarEnvio = true;
      this.infoTicket.envioTipo = "envio a dominicilio";
      this.suma = 0;
      this.total = 0;
      this.sumaCustom = 0;

      this.realizarSuma();
    } else {
      this.mostrarDireccion = false;
      this.costoEnvio = 0;
      this.productForm.get('tipoEnvio').setValue('recoge en tienda');
      this.infoTicket.tipoEnvio = 'recoge en tienda';
      this.mostrarEnvio = false;
      this.infoTicket.envioTipo = "recoge en tienda";
      this.realizarSuma(false);
    }
  }

  cargarMunicipio(deparSelect: string) {
    switch (deparSelect) {
      case 'Guatemala':
        this.listaMunicipio = [
          "Guatemala",
          "Santa Catarina Pinula",
          "San José Pinula",
          "San José del Golfo",
          "Palencia",
          "Chinautla",
          "San Pedro Ayampuc",
          "Mixco",
          "San Pedro Sacatepéquez",
          "San Juan Sacatepéquez",
          "San Raymundo",
          "Chuarrancho",
          "Fraijanes",
          "Amatitlán",
          "Villa Nueva",
          "Villa Canales",
          "Petapa"
        ]
        break;
      case 'El Progreso':
        this.listaMunicipio = [
          "Guastatoya",
          "Morazán",
          "San Agustín Acasaguastlán",
          "San Cristóbal Acasaguastlán",
          "El Jícaro",
          "Sansare",
          "Sanarate",
          "San Antonio la Paz"
        ]
        break;
      case 'Sacatepéquez':
        this.listaMunicipio = [
          "Antigua Guatemala",
          "Jocotenango",
          "Pastores",
          "Sumpango",
          "Santo Domingo Xenacoj",
          "Santiago Sacatepéquez",
          "San Bartolomé Milpas Altas",
          "San Lucas Sacatepéquez",
          "Santa Lucía Milpas Altas",
          "Magdalena Milpas Altas",
          "Santa María de Jesús",
          "Ciudad Vieja",
          "San Miguel Dueñas",
          "Alotenango",
          "San Antonio Aguas Calientes",
          "Santa Catarina Barahona",
        ]
        break;
      case 'Chimaltenango':
        this.listaMunicipio = [
          "Chimaltenango",
          "San José Poaquil",
          "San Martín Jilotepeque",
          "Comalapa",
          "Santa Apolonia",
          "Tecpán Guatemala",
          "Patzún",
          "Pochuta",
          "Patzicía",
          "Santa Cruz Balanyá",
          "Acatenango",
          "Yepocapa",
          "San Andrés Itzapa",
          "Parramos",
          "Zaragoza",
          "El Tejar"
        ]
        break;
      case 'Escuintla':
        this.listaMunicipio = [
          "Escuintla",
          "Santa Lucía Cotzumalguapa",
          "La Democracia",
          "Siquinalá",
          "Masagua",
          "Tiquisate",
          "La Gomera",
          "Guanagazapa",
          "San José",
          "Iztapa",
          "Palín",
          "San Vicente Pacaya",
          "Nueva Concepción"
        ]
        break;
      case 'Santa Rosa':
        this.listaMunicipio = [
          "Cuilapa",
          "Barberena",
          "Santa Rosa de Lima",
          "Casillas",
          "San Rafael las Flores",
          "Oratorio",
          "San Juan Tecuaco",
          "Chiquimulilla",
          "Taxisco",
          "Santa María Ixhuatán",
          "Guazacapán",
          "Santa Cruz Naranjo",
          "Pueblo Nuevo Viñas",
          "Nueva Santa Rosa"
        ]
        break;
      case 'Sololá':
        this.listaMunicipio = [
          "Sololá",
          "San José Chacayá",
          "Santa María Visitación",
          "Santa Lucía Utatlán",
          "Nahualá",
          "Santa Catarina Ixtahuacán",
          "Santa Clara la Laguna",
          "Concepción",
          "San Andrés Semetabaj",
          "Panajachel",
          "Santa Catarina Palopó",
          "San Antonio Palopó",
          "San Lucas Tolimán",
          "Santa Cruz la Laguna",
          "San Pablo la Laguna",
          "San Marcos la Laguna",
          "San Juan la Laguna",
          "San Pedro la Laguna",
          "Santiago Atitlán"
        ]
        break;
      case 'Totonicapán':
        this.listaMunicipio = [
          "Totonicapán",
          "San Cristóbal Totonicapán",
          "San Francisco el Alto",
          "San Andrés Xecul",
          "Momostenango",
          "Santa María Chiquimula",
          "Santa Lucía la Reforma",
          "San Bartolo"
        ]
        break;
      case 'Quetzaltenango':
        this.listaMunicipio = [
          "Quetzaltenango",
          "Salcajá",
          "Olintepeque",
          "San Carlos Sija",
          "Sibilia",
          "Cabricán",
          "Cajolá",
          "San Miguel Siguilá",
          "Ostuncalco",
          "San Mateo",
          "Concepción Chiquirichapa",
          "San Martín Sacatepéquez",
          "Almolonga",
          "Cantel",
          "Huitán",
          "Zunil",
          "Colomba",
          "San Francisco la Unión",
          "El Palmar",
          "Coatepeque",
          "Génova",
          "Flores Costa Cuca",
          "La Esperanza",
          "Palestina de los Altos"
        ]
        break;
      case 'Suchitepéquez':
        this.listaMunicipio = [
          "Mazatenango",
          "Cuyotenango",
          "San Francisco Zapotitlán",
          "San Bernardino",
          "San José el Idolo",
          "Santo Domingo Suchitepéquez",
          "San Lorenzo",
          "Samayac",
          "San Pablo Jocopilas",
          "San Antonio Suchitepéquez",
          "San Miguel Panán",
          "San Gabriel",
          "Chicacao",
          "Patulul",
          "Santa Bárbara",
          "San Juan Bautista",
          "Santo Tomás la Unión",
          "Zunilito",
          "Pueblo Nuevo",
          "Río Bravo",
          "San José La Máquina"
        ]
        break;
      case 'Retalhuleu':
        this.listaMunicipio = [
          "Retalhuleu",
          "San Sebastián",
          "Santa Cruz Muluá",
          "San Martín Zapotitlán",
          "San Felipe",
          "San Andrés Villa Seca",
          "Champerico",
          "Nuevo San Carlos",
          "El Asintal",
        ]
        break;
      case 'San Marcos':
        this.listaMunicipio = [
          "San Marcos",
          "San Pedro Sacatepéquez",
          "San Antonio Sacatepéquez",
          "Comitancillo",
          "San Miguel Ixtahuacán",
          "Concepción Tutuapa",
          "Tacaná",
          "Sibinal",
          "Tajumulco",
          "Tejutla",
          "San Rafael Pié de la Cuesta",
          "Nuevo Progreso",
          "El Tumbador",
          "El Rodeo",
          "Malacatán",
          "Catarina",
          "Ayutla",
          "Ocós",
          "San Pablo",
          "El Quetzal",
          "La Reforma",
          "Pajapita",
          "Ixchiguán",
          "San José Ojetenán",
          "San Cristóbal Cucho",
          "Sipacapa",
          "Esquipulas Palo Gordo",
          "Río Blanco",
          "San Lorenzo",
          "La Blanca",
        ]
        break;
      case 'Huehuetenango':
        this.listaMunicipio = [
          "Huehuetenango",
          "Chiantla",
          "Malacatancito",
          "Cuilco",
          "Nentón",
          "San Pedro Necta",
          "Jacaltenango",
          "Soloma",
          "Ixtahuacán",
          "Santa Bárbara",
          "La Libertad",
          "La Democracia",
          "San Miguel Acatán",
          "San Rafael la Independencia",
          "Todos Santos Cuchumatán",
          "San Juan Atitán",
          "Santa Eulalia",
          "San Mateo Ixtatán",
          "Colotenango",
          "San Sebastián Huehuetenango",
          "Tectitán",
          "Concepción Huista",
          "San Juan Ixcoy",
          "San Antonio Huista",
          "San Sebastián Coatán",
          "Barillas",
          "Aguacatán",
          "San Rafael Petzal",
          "San Gaspar Ixchil",
          "Santiago Chimaltenango",
          "Santa Ana Huista",
          "Unión Cantinil"
        ]
        break;
      case 'Quiché':
        this.listaMunicipio = [
          "Santa Cruz del Quiché",
          "Chiché",
          "Chinique",
          "Zacualpa",
          "Chajul",
          "Chichicastenango",
          "Patzité",
          "San Antonio Ilotenango",
          "San Pedro Jocopilas",
          "Cunén",
          "San Juan Cotzal",
          "Joyabaj",
          "Nebaj",
          "San Andrés Sajcabajá",
          "Uspantán",
          "Sacapulas",
          "San Bartolomé Jocotenango",
          "Canillá",
          "Chicamán",
          "Ixcán",
          "Pachalum"
        ]
        break;
      case 'Baja Verapaz':
        this.listaMunicipio = [
          "Salamá",
          "San Miguel Chicaj",
          "Rabinal",
          "Cubulco",
          "Granados",
          "El Chol",
          "San Jerónimo",
          "Purulhá"
        ]
        break;
      case 'Alta Verapaz':
        this.listaMunicipio = [
          "Cobán",
          "Santa Cruz Verapaz",
          "San Cristóbal Verapaz",
          "Tactic",
          "Tamahú",
          "Tucurú",
          "Panzós",
          "Senahú",
          "San Pedro Carchá",
          "San Juan Chamelco",
          "Lanquín",
          "Cahabón",
          "Chisec",
          "Chahal",
          "Fray Bartolomé de las Casas",
          "Santa Catalina la Tinta",
          "Raxruhá"
        ]
        break;
      case 'Petén':
        this.listaMunicipio = [
          "Flores",
          "San José",
          "San Benito",
          "San Andrés",
          "La Libertad",
          "San Francisco",
          "Santa Ana",
          "Dolores",
          "San Luis",
          "Sayaxché",
          "Melchor de Mencos",
          "Poptún",
          "Las Cruces",
          "El Chal"
        ]
        break;
      case 'Izabal':
        this.listaMunicipio = [
          "Puerto Barrios",
          "Livingston",
          "El Estor",
          "Morales",
          "Los Amates"
        ]
        break;
      case 'Zacapa':
        this.listaMunicipio = [
          "Zacapa",
          "Estanzuela",
          "Río Hondo",
          "Gualán",
          "Teculután",
          "Usumatlán",
          "Cabañas",
          "San Diego",
          "La Unión",
          "Huité",
          "San Jorge"
        ]
        break;
      case 'Chiquimula':
        this.listaMunicipio = [
          "Chiquimula",
          "San José La Arada",
          "San Juan Ermita",
          "Jocotán",
          "Camotán",
          "Olopa",
          "Esquipulas",
          "Concepción Las Minas",
          "Quetzaltepeque",
          "San Jacinto",
          "Ipala"
        ]
        break;
      case 'Jalapa':
        this.listaMunicipio = [
          "Jalapa",
          "San Pedro Pinula",
          "San Luis Jilotepeque",
          "San Manuel Chaparrón",
          "San Carlos Alzatate",
          "Monjas",
          "Mataquescuintla",
        ]
        break;
      case 'Jutiapa':
        this.listaMunicipio = [
          "Jutiapa",
          "El Progreso",
          "Santa Catarina Mita",
          "Agua Blanca",
          "Asunción Mita",
          "Yupiltepeque",
          "Atescatempa",
          "Jerez",
          "El Adelanto",
          "Zapotitlán",
          "Comapa",
          "Jalpatagua",
          "Conguaco",
          "Moyuta",
          "Pasaco",
          "San José Acatempa",
          "Quesada"
        ]
        break;
    }

  }

  onChangeState(newValue) {
    this.statusEditar = newValue;
    this.cargarMunicipio(this.statusEditar);
  }

  onChangeMuni(newValue) {
    this.muniElegido = newValue;
    const tipoDeEnvio = this.infoTicket.tipoEnvio;
    if(tipoDeEnvio === "Envío a domicilio"){
      this.calcularEnvioDepa();
      this.calcularEnvio();
      this.realizarSuma(false);
    }
  }

  calcularEnvioDepa(){
    const nombreMunicipio  = this.productForm.get('municipio').value;
    // Termina la funcion ya que no se encuentra un municipio
    if(nombreMunicipio === '') return;
    if (nombreMunicipio === "Guatemala") {
      this.costoEnvio = 25;
      this.infoTicket.envioPrice = 25;
    } else {
      this.costoEnvio = 35;
    }

    if (this.statusEditar === "Guatemala") {
      this.suma = 0;
      this.total = 0;
      this.sumaCustom = 0;
      this.realizarSuma();
    }
  }


  calcularEnvio(): string {
    let costo = "calculando...";
    if (this.statusEditar === "Guatemala") {
      if (this.muniElegido === "Guatemala") {
        costo = "Q.25.00";
        this.infoTicket.envioPrice = 25;
      } else {
        costo = "Q.35.00";
        this.infoTicket.envioPrice = 35;
      }
    }else {
      costo = "Q.35.00";
      this.infoTicket.envioPrice = 35;
    }
    return costo;
  }

  mostrarProveedor(): boolean {
    let mostrar = false;

    if (this.muniElegido === "Guatemala" || this.muniElegido === "Santa Catarina Pinula" || this.muniElegido === "Mixco") {

      mostrar = false;
    } else {
      if (this.muniElegido != undefined) {
        mostrar = true;
      } else {
        mostrar = false;
      }
    }

    return mostrar;
  }

  traducirColor(ingles: string): string {
    let traduccion: string;

    switch (ingles) {
      case 'black':
        traduccion = 'Negro';
        break;
      case 'green':
        traduccion = 'Verde';
        break;
      case 'purple':
        traduccion = 'Morado';
        break;
      case 'orange':
        traduccion = 'Anaranjado';
        break;
      case 'red':
        traduccion = 'rojo';
        break;
      case 'blue':
        traduccion = 'azul';
        break;
      case 'chocolate':
        traduccion = 'Cafe';
        break;
      case 'turquoise':
        traduccion = 'Turquesa';
        break;
    }

    return traduccion;
  }


  aplicarTrim(introData: any = ''): string{
    if (introData ?? false) {
      if(introData !== ''){
        return introData.trim();
      }
    }
    return '';
  }

}


